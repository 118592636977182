/**
 * DroppableContainer Component
 * 
 * This component provides a droppable area for drag-and-drop interactions. It uses the `useDroppable`
 * hook from the `@dnd-kit/core` library to manage the droppable area.
 * 
 * Props:
 * - id: The unique identifier for the droppable area.
 * - children: The content to be rendered inside the droppable area.
 * 
 * Example usage:
 * <DroppableContainer id="droppable-1">
 *   <p>Drop items here</p>
 * </DroppableContainer>
 */

import React from 'react';
import { useDroppable } from '@dnd-kit/core';

const DroppableContainer = ({ id, children }) => {
  const { setNodeRef } = useDroppable({ id });

  return <div ref={setNodeRef}>{children}</div>;
};

export default DroppableContainer;
