import React, { useState } from "react";
import { Form } from "react-bootstrap";
import "./LoginPage.scss";
import "./LoginPageDesktop.scss";
import GPLogo from "../../assets/images/logo/gp-logo-trans-w100.png";
import LoginButton from "./reusable/LoginButton";
import LoginForgotPassword from "./reusable/LoginForgotPassword";
import MandatoryText from "./reusable/MandatoryText";
import { ToastContainer } from "react-toastify";
import useTranslation from "../../hooks/useTranslation";

const LoginPageDesktop = ({
  handleSubmit,
  PasswordField,
  isChecked,
  setIsChecked,
  handleEmailChange,
  handlePasswordChange,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { t } = useTranslation("loginRelatedPages", true);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit(email, password);
  };

  return (
    <div className="base-div">
      <div className="container login-page__container__desktop">
        <ToastContainer />
        <div className="login-page__form__desktop">
          <div className="login-page__form__desktop__text-wrapper">
            <img src={GPLogo} alt="logo" className="login__desktop__logo" />
            <h1 className="login-page__form-title">{t("login")}</h1>
            <p className="login-page__form-subtitle">
            {t("loginSubtitle")}.
            </p>
            <Form onSubmit={handleFormSubmit}>
              <Form.Group className="form-group__desktop">
                <Form.Label htmlFor="email">E-mail</Form.Label>
                <Form.Control
                  type="email"
                  className="form-control"
                  id="email"
                  aria-describedby="emailHelp"
                  placeholder="nev@minta.com"
                  autoComplete="on"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    handleEmailChange({ target: { value: e.target.value } });
                  }}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label htmlFor="password">{t("password")}</Form.Label>
                <PasswordField
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    handlePasswordChange({ target: { value: e.target.value } });
                  }}
                />
              </Form.Group>
              <MandatoryText
                isChecked={isChecked}
                setIsChecked={setIsChecked}
              />
              <LoginButton handleSubmit={handleSubmit} />
            </Form>
            <LoginForgotPassword />
          </div>
          <div className="login-page__form__desktop__image"></div>
        </div>
      </div>
    </div>
  );
};

export default LoginPageDesktop;
