/**
 * NotificationSettingsPage is a React component that renders a page for managing notification settings.
 *
 * This component provides the following features:
 * - Display and edit notification settings such as receiving notifications, notification method, email and phone number.
 * - Display a list of previous notifications with sortable sent time.
 * - Pagination for the list of notifications.
 * - A modal to show more details about a notification.
 * - Sorting of notifications based on selected field in ascending or descending order.
 * - Display of notifications for the current page based on the selected items per page.
 *
 */
import { jwtDecode } from "jwt-decode";
import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../../context/UserContext";
import "./NotificationSettingsPage.scss";
import { getAllThresholdViolationMeasurements } from "../../../api/sensorApi";
import NotificationSettings from "./NotificationSettings/NotificationSettings";
import NotificationTable from "./NotificationTable/NotificationTable";
import NotificationModal from "./NotificationModal/NotificationModal";
import moment from "moment";

const NotificationSettingsPage = () => {
  const { user } = useContext(UserContext);
  const [userRole, setUserRole] = useState("");
  // Modal state
  const [showNotificationInfoModal, setShowNotificationInfoModal] =
    useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  // Table content
  const [notificationTableHeaders, setNotificationTableHeaders] = useState({});
  const [notificationTableData, setNotificationTableData] = useState([]);
  // Sort state
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserRole(decodedToken.role);
    }
  }, []);

  // Table headers
  useEffect(() => {
    const headers = {
      sens_timestamp: "Időpont",
      probeid: "Szonda",
      sens_type: "Típus",
      message: "Üzenet",
      user: "Értesítést kapta",
      acknowledged_at: "Jóváhagyás ideje",
      acknowledged_by: "Jóváhagyta",
      notificationMoreInfo: "Info",
    };

    const filteredHeaders = filterHeadersByRole(headers, userRole);
    setNotificationTableHeaders(filteredHeaders);
  }, [userRole]);

  const filterHeadersByRole = (headers, role) => {
    const filteredHeaders = { ...headers };
    if (role === "1") {
      delete filteredHeaders.companyname;
    } else if (role === "4") {
      delete filteredHeaders.sitename;
      delete filteredHeaders.companyname;
      delete filteredHeaders.user;
    }
    return filteredHeaders;
  };

  // Number of items per page
  const itemsPerPage = 5;

  const fetchNotificationData = async () => {
    try {
      // Fetch notification data from the API
      const data = await getAllThresholdViolationMeasurements();
      const processedData = data.map((item) => {
        let message = "";
        if (item.sens_type === 2) {
          item.sens_type = "Hőmérséklet";
          if (item.sens_value > item.max_value) {
            message = "Túl magas hőmérséklet";
          } else if (item.sens_value < item.min_value) {
            message = "Túl alacsony hőmérséklet";
          }
        }
        return { ...item, message };
      });
      setNotificationTableData(processedData);
    } catch (error) {
      console.error("Error fetching notification data:", error);
    }
  };

  useEffect(() => {
    fetchNotificationData();
  }, []);

  const handleNotificationInfoModalShow = (notification) => {
    setSelectedNotification(notification);
    setShowNotificationInfoModal(true);
  };

  const handleNotificationInfoModalClose = () => {
    setShowNotificationInfoModal(false);
  };

  const handleSort = (field) => {
    let direction = "asc";
    if (sortField === field && sortDirection === "asc") {
      direction = "desc";
    }
    setSortField(field);
    setSortDirection(direction);
  };

  const sortedData = [...notificationTableData].sort((a, b) => {
    if (sortField) {
      if (sortField === "sens_timestamp") {
        const dateA = new Date(a[sortField]);
        const dateB = new Date(b[sortField]);
        if (sortDirection === "asc") {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      } else {
        if (sortDirection === "asc") {
          return a[sortField].localeCompare(b[sortField]);
        } else {
          return b[sortField].localeCompare(a[sortField]);
        }
      }
    }
    return 0;
  });

  // Get the items for the current page
  const currentPageData = sortedData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(sortedData.length / itemsPerPage);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="container notification-settings-page__container navbar-margin">
      <h1 className="page-title">Értesítések</h1>
      <div className="notification-settings-page__content">
        <NotificationSettings />
        <NotificationTable
          currentPageData={currentPageData}
          notificationTableHeaders={notificationTableHeaders}
          handleSort={handleSort}
          sortField={sortField}
          sortDirection={sortDirection}
          handleNotificationInfoModalShow={handleNotificationInfoModalShow}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
          totalPages={totalPages}
        />
        <NotificationModal
          showNotificationInfoModal={showNotificationInfoModal}
          handleNotificationInfoModalClose={handleNotificationInfoModalClose}
          notificationTableHeaders={notificationTableHeaders}
          notificationDetails={selectedNotification}
        />
      </div>
    </div>
  );
};

export default NotificationSettingsPage;
