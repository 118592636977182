import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const EditContainerModal = ({ show, onHide, container, refreshTableData }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [containerName, setContainerName] = useState(container.containername);
    
    return ( 
        <Modal show={modalIsOpen} size="xl" onHide={() => setModalIsOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Tároló szerkesztése</Modal.Title>
        </Modal.Header>

        </Modal>
     );
}
 
export default EditContainerModal;