import axios from "axios";
const backendUrl = process.env.REACT_APP_BACKEND_URL_FOR_FRONTEND;
const token = localStorage.getItem("token");

export const getMeasurementReportsForEveryDeviceForTheLast24hByCompanyId =
  async (companyId, probesensorsObj) => {
    try {
      // Correctly access the Set within the probesensors object
      const probesensors = probesensorsObj.probesensors;
      // Check if probesensors is not empty
      if (probesensors.size === 0) {
        throw new Error("No probe sensors provided");
      }

      // Convert Set to Array and join with commas
      const probesensorsQuery = Array.from(probesensors).join(",");

      if (probesensorsQuery === undefined) {
        console.warn("probesensorsQuery is undefined");
      }
      if (!probesensorsQuery) {
        console.warn("probesensorsQuery is empty or undefined");
      }
      const response = await axios({
        url: `${backendUrl}/reports/last24h/${companyId}?probesensors=${probesensorsQuery}`,
        method: "GET",
        responseType: "blob", // Important for handling binary data like CSV
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "gp-riport-utolso-24-ora.zip");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up
    } catch (error) {
      console.error(error);
      throw new Error(error.message);
    }
  };

export const getMeasurementReportsForEveryDeviceForTheLastWeekByCompanyId =
  async (companyId, probesensorsObj) => {
    try {
      // Correctly access the Set within the probesensors object
      const probesensors = probesensorsObj.probesensors;
      // Check if probesensors is not empty
      if (probesensors.size === 0) {
        throw new Error("No probe sensors provided");
      }

      // Convert Set to Array and join with commas
      const probesensorsQuery = Array.from(probesensors).join(",");

      if (probesensorsQuery === undefined) {
        console.warn("probesensorsQuery is undefined");
      }
      if (!probesensorsQuery) {
        console.warn("probesensorsQuery is empty or undefined");
      }
      const response = await axios({
        url: `${backendUrl}/reports/lastweek/${companyId}?probesensors=${probesensorsQuery}`,
        method: "GET",
        responseType: "blob", // Important for handling binary data like CSV
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "gp-riport-utolso-1-het.zip"); // or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up
    } catch (error) {
      console.error(error);
      throw new Error(error.message);
    }
  };

export const getMeasurementReportsForEveryDeviceForTheLastMonthByCompanyId =
  async (companyId, probesensorsObj) => {
    try {
      // Correctly access the Set within the probesensors object
      const probesensors = probesensorsObj.probesensors;
      // Check if probesensors is not empty
      if (probesensors.size === 0) {
        throw new Error("No probe sensors provided");
      }

      // Convert Set to Array and join with commas
      const probesensorsQuery = Array.from(probesensors).join(",");

      if (probesensorsQuery === undefined) {
        console.warn("probesensorsQuery is undefined");
      }
      if (!probesensorsQuery) {
        console.warn("probesensorsQuery is empty or undefined");
      }
      const response = await axios({
        url: `${backendUrl}/reports/lastmonth/${companyId}?probesensors=${probesensorsQuery}`,
        method: "GET",
        responseType: "blob", // Important for handling binary data like CSV
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "gp-riport-utolso-1-honap.zip"); // or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up
    } catch (error) {
      console.error(error);
      throw new Error(error.message);
    }
  };

export const getMeasurementReportsForEveryDeviceForTheLastQuarterByCompanyId =
  async (companyId, probesensorsObj) => {
    try {
      // Correctly access the Set within the probesensors object
      const probesensors = probesensorsObj.probesensors;
      // Check if probesensors is not empty
      if (probesensors.size === 0) {
        throw new Error("No probe sensors provided");
      }

      // Convert Set to Array and join with commas
      const probesensorsQuery = Array.from(probesensors).join(",");

      if (probesensorsQuery === undefined) {
        console.warn("probesensorsQuery is undefined");
      }
      if (!probesensorsQuery) {
        console.warn("probesensorsQuery is empty or undefined");
      }
      const response = await axios({
        url: `${backendUrl}/reports/lastquarter/${companyId}?probesensors=${probesensorsQuery}`,
        method: "GET",
        responseType: "blob", // Important for handling binary data like CSV
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "gp-riport-utolso-3-honap.zip"); // or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up
    } catch (error) {
      console.error(error);
      throw new Error(error.message);
    }
  };

export const getMeasurementReportsForEveryDeviceForTheLastHalfYearByCompanyId =
  async (companyId, probesensorsObj) => {
    try {
      // Correctly access the Set within the probesensors object
      const probesensors = probesensorsObj.probesensors;
      // Check if probesensors is not empty
      if (probesensors.size === 0) {
        throw new Error("No probe sensors provided");
      }

      // Convert Set to Array and join with commas
      const probesensorsQuery = Array.from(probesensors).join(",");

      if (probesensorsQuery === undefined) {
        console.warn("probesensorsQuery is undefined");
      }
      if (!probesensorsQuery) {
        console.warn("probesensorsQuery is empty or undefined");
      }
      const response = await axios({
        url: `${backendUrl}/reports/lasthalfyear/${companyId}?probesensors=${probesensorsQuery}`,
        method: "GET",
        responseType: "blob", // Important for handling binary data like CSV
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "gp-riport-utolso-6-honap.zip"); // or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up
    } catch (error) {
      console.error(error);
      throw new Error(error.message);
    }
  };

export const getMeasurementReportsForEveryDeviceForTheLastYearByCompanyId =
  async (companyId, probesensorsObj) => {
    try {
      // Correctly access the Set within the probesensors object
      const probesensors = probesensorsObj.probesensors;
      // Check if probesensors is not empty
      if (probesensors.size === 0) {
        throw new Error("No probe sensors provided");
      }

      // Convert Set to Array and join with commas
      const probesensorsQuery = Array.from(probesensors).join(",");

      if (probesensorsQuery === undefined) {
        console.warn("probesensorsQuery is undefined");
      }
      if (!probesensorsQuery) {
        console.warn("probesensorsQuery is empty or undefined");
      }
      const response = await axios({
        url: `${backendUrl}/reports/lastyear/${companyId}?probesensors=${probesensorsQuery}`,
        method: "GET",
        responseType: "blob", // Important for handling binary data like CSV
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "gp-riport-utolso-1-ev.zip"); // or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up
    } catch (error) {
      console.error(error);
      throw new Error(error.message);
    }
  };

export const getMeasurementReportsForCustomDateRangeByCompanyId = async (
  companyId,
  probesensorsObj,
  startDate,
  endDate
) => {
  try {
    // Correctly access the Set within the probesensors object
    const probesensors = probesensorsObj.probesensors;
    // Check if probesensors is not empty
    if (probesensors.size === 0) {
      throw new Error("No probe sensors provided");
    }

    // Convert Set to Array and join with commas
    const probesensorsQuery = Array.from(probesensors).join(",");

    if (probesensorsQuery === undefined) {
      console.warn("probesensorsQuery is undefined");
    }
    if (!probesensorsQuery) {
      console.warn("probesensorsQuery is empty or undefined");
    }

    const response = await axios({
      url: `${backendUrl}/reports/customtimerange/${companyId}?startdate=${startDate}&enddate=${endDate}&probesensors=${probesensorsQuery}`,
      method: "GET",
      responseType: "blob", // Important for handling binary data like CSV
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // Check if the response is empty
    if (response.status === 204 || response.data.size === 0) {
      console.error("Nincs a megadott időszakra vonatkozó adat.");
      return; // Exit the function if no data is available
    }

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "gp-riport-egyedi-idotartam.zip"); // or any other extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return "Nincs a megadott időszakra vonatkozó adat.";
    } else {
      return `Error fetching reports: ${error.message}`;
    }
  }
};
