import React from "react";
import "./MandatoryText.scss";
import useTranslation from "../../../hooks/useTranslation";

const MandatoryText = ({ isChecked, setIsChecked }) => {
  const { t } = useTranslation("loginRelatedPages", true);
  return (
    <div className="contact-form-checkbox-wrapper">
      <input
        type="checkbox"
        name="checkbox"
        className="contact-form-checkbox"
        checked={isChecked}
        onChange={() => setIsChecked(!isChecked)}
        required
      ></input>
      <span className="contact-form-checkbox-text">
        {t("readUnderstandAccept")}{" "}
        <a href="/" target="_blank" rel="noreferrer">
          {t("privacyPolicy")}
        </a>
        .
      </span>
    </div>
  );
};

export default MandatoryText;
