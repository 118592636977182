/**
 * SortableDevice Component
 * 
 * This component provides a draggable item for drag-and-drop interactions. It uses the `useDraggable`
 * hook from the `@dnd-kit/core` library to manage the draggable item. The component also applies
 * styles to the item based on its drag state.
 * 
 * Props:
 * - id: The unique identifier for the draggable item.
 * - device: The device object containing information about the device.
 * - disabled: Boolean to disable the draggable functionality.
 * 
 * Example usage:
 * <SortableDevice id="device-1" device={device} disabled={false} />
 */

import React from "react";
import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { DragOutlined } from "../../../helpers/icons/antDesignIcons";
import "./SortableDevice.scss";

const SortableDevice = ({ id, device, disabled }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useDraggable({ id, disabled });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="sortable-device-item"
    >
      <span>
      <DragOutlined />
      </span>
      <p style={{ pointerEvents: "none" }}> Szonda: {device.probeid}</p>
    </div>
  );
};

export default SortableDevice;
