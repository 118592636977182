/**
 * DashboardPage Component
 *
 * This component represents the dashboard page of the application. It fetches and displays
 * active threshold violation measurements based on the user's role and company. The component
 * also provides functionality to acknowledge alerts and dynamically adjusts table headers
 * based on the user's role. Additionally, it includes a helper modal to guide users on what
 * they can do on this page.
 *
 * Key functionalities:
 * - Fetches user role from JWT token stored in localStorage.
 * - Fetches active threshold violation measurements from the API.
 * - Dynamically adjusts table headers based on user role.
 * - Provides a loading spinner while data is being fetched.
 * - Allows users to acknowledge alerts.
 * - Includes a helper modal to guide users on the page functionalities.
 */

import { jwtDecode } from "jwt-decode";
import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../context/UserContext";
import DashboardSection from "./reusable/DashboardSection";
import "./DashboardPage.scss";
import {
  getAllActiveThresholdViolationMeasurements,
  acknowledgeThresholdViolationByRecordId,
} from "../../api/sensorApi";
import Spinner from "react-bootstrap/Spinner";
import { UserRoleProvider } from "../../context/userRoleContext";
import { toast } from "react-toastify";
import PageHeader from "../Shared/PageHeader/PageHeader";
import useTranslation from "../../hooks/useTranslation";

export function GrowExample() {
  return <Spinner animation="grow" />;
}

const DashboardPage = () => {
  const { user } = useContext(UserContext);
  const companyId = user.company_id;
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [dashboardAlertItems, setDashboardAlertItems] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [tableHeaders, setTableHeaders] = useState({});
const { t } = useTranslation("dashboardPage");
  console.log(user.token);

  // Fetch user role from JWT token stored in localStorage
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserRole(decodedToken.role);
    }
  }, []);

  // Set table headers based on user role
  useEffect(() => {
    const headers = {
      probeid: "Eszköz azonosító",
      sens_num: "Szenzor mélysége",
      containername: "Tároló neve",
      sitename: "Telephely neve",
      sens_timestamp: "Mérés ideje",
      sens_value: "Mért érték",
      min_value: "Min. érték",
      max_value: "Max. érték",
      user: "Felhasználó",
      companyname: "Cég neve",
      moreInfoModalButton: "",
      acknowledgedButton: "",
    };

    const filteredHeaders = filterHeadersByRole(headers, userRole);
    setTableHeaders(filteredHeaders);
  }, [userRole]);

  // Filter table headers based on user role
  const filterHeadersByRole = (headers, role) => {
    const filteredHeaders = { ...headers };
    if (role === "1") {
      delete filteredHeaders.companyname;
    } else if (role === "4") {
      delete filteredHeaders.sitename;
      delete filteredHeaders.companyname;
      delete filteredHeaders.user;
    }
    return filteredHeaders;
  };

  /*   const locationTableHeaders = {
    containername: "Raktár neve",
    sitename: "Telephely neve",
    status: "Státusz",
    numOfSensors: "Szondák száma",
    crop: "Termény",
    moreInfoModalButton: "",
  }; */

  // Fetch active threshold violation measurements
  const fetchAlertItems = async () => {
    // Fetch alerts if logged in user data is available
    if (user && user.token) {
      try {
        const alerts = await getAllActiveThresholdViolationMeasurements(
          companyId,
          user.token
        );
        setDashboardAlertItems(alerts);
      } catch (error) {
        console.error(error);
      }
    }
  };

  // Fetch alert items when user data is available
  useEffect(() => {
    if (user && user.token) {
      // check if user data is available
      fetchAlertItems();
      setIsPageLoaded(true);
    }
  }, [user]);

  // Handle acknowledgment of threshold violation
  const handleAcknowledge = async (thresholdViolationRecord) => {
    console.log("Record:", thresholdViolationRecord);
    const recordId = thresholdViolationRecord._id;
    console.log("Record ID:", recordId);

    try {
      await acknowledgeThresholdViolationByRecordId(recordId);
      fetchAlertItems();
      toast.success("Riasztás elismerve!");
    } catch (error) {
      console.error(error);
      toast.error("Hiba történt a riasztás elismerése során!");
    }
  };

  return (
    <UserRoleProvider>
      <>
        {isPageLoaded === false && (
          <div className="loader">
            <GrowExample />
          </div>
        )}
        {isPageLoaded && (
          <div className="container dashboard-page__container navbar-margin">
            <PageHeader
              title={t("dashboard_title")}
              helperTitle={t("dashboard_title")}
              helperBody={t("dashboard_helper")}
            />
            <div className="dashboard__grid">
              <DashboardSection
                tableHeaders={tableHeaders}
                title={t("dashboard_table_title")}
                items={dashboardAlertItems}
                linkTo="/alerts"
                handleAcknowledge={handleAcknowledge}
              />
              {/*           <DashboardSection
            tableHeaders={tableHeaders}
            title="Jelzések"
            items={dashboardWarningItems}
            linkTo="/warnings"
          /> */}
              {/*             <DashboardSection
              tableHeaders={locationTableHeaders}
              title="Helyszínek"
              items={dashboardLocationItems}
              linkTo="/locations"
            /> */}
            </div>
          </div>
        )}
      </>
    </UserRoleProvider>
  );
};

export default DashboardPage;
