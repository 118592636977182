/**
 * ConfirmationModal Component
 *
 * This component renders a confirmation modal dialog using React Bootstrap. It displays a message
 * and provides "Cancel" and "Yes" buttons for user interaction. The component uses a custom hook
 * for translation.
 *
 * Props:
 * - isVisible: Boolean to control the visibility of the modal.
 * - onConfirm: Function to handle the confirmation action.
 * - onCancel: Function to handle the cancellation action.
 * - message: String message to be displayed in the modal body.
 *
 * Example usage:
 * <ConfirmationModal
 *   isVisible={isVisible}
 *   onConfirm={handleConfirm}
 *   onCancel={handleCancel}
 *   message="Are you sure you want to delete this item?"
 * />
 */

import React from "react";
import { Button, Modal } from "react-bootstrap";
import useTranslation from "../../../hooks/useTranslation";

const ConfirmationModal = ({ isVisible, onConfirm, onCancel, message }) => {
  const { t } = useTranslation("common"); // Custom hook for translation
  return (
    <Modal
      show={isVisible} // Control the visibility of the modal
      onHide={onCancel} // Function to handle the modal close event
      centered
      style={{ "--bs-modal-border-color": "transparent" }} // Custom CSS variable to override modal border color
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("confirm")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {" "}
        {message.split("\n").map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          {t("cancel")}
        </Button>
        <Button className="btn-delete" onClick={onConfirm}>
          {t("yes")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
