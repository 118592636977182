/**
 * SettingsListingTable Component
 * 
 * This component renders a table for displaying settings data, such as user or location information.
 * It supports sorting, displaying modals for detailed information, and editing entries. The component
 * uses React Bootstrap for styling and modals, and Ant Design icons for visual elements.
 * 
 * Props:
 * - tableHeaders: An object representing the headers of the table.
 * - tableData: An array of data objects to be displayed in the table.
 * - tableType: A string indicating the type of table ("location" or "user").
 * - fetchUsersAndLocations: A function to fetch users and locations data.
 * - isUserSuperAdmin: A boolean indicating if the current user is a super admin.
 * 
 * Example usage:
 * <SettingsListingTable 
 *   tableHeaders={tableHeaders} 
 *   tableData={tableData} 
 *   tableType="user" 
 *   fetchUsersAndLocations={fetchUsersAndLocations} 
 *   isUserSuperAdmin={true} 
 * />
 */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { Modal } from "react-bootstrap";
import { notificationsDummyDataHun } from "../../NotificationSettingsPage/notificationsDummyData";
import {
  EditOutlined,
  InfoCircleOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "../../../../helpers/icons/antDesignIcons";
import "./SettingsListingTable.scss";

const SettingsListingTable = ({
  tableHeaders,
  tableData,
  tableType,
  fetchUsersAndLocations,
  isUserSuperAdmin,
}) => {
  const navigate = useNavigate();
  const [showUserInfoModal, setShowUserInfoModal] = useState(false);
  const [modalUserInfo, setModalUserInfo] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showLocationInfoModal, setShowLocationInfoModal] = useState(false);
  const [modalLocationInfo, setModalLocationInfo] = useState("");
  const [showNotificationInfoModal, setShowNotificationInfoModal] =
    useState(false);
  // Sort state
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);

  const roleMapping = {
    0: "Szuperadmin",
    1: "Cégadmin",
    2: "Cégvezető",
    3: "Telephelyvezető",
    4: "Felhasználó",
  };

  useEffect(() => {
    console.log(typeof fetchUsersAndLocations);
  }, [fetchUsersAndLocations]);

  const handleUserInfoModalClose = () => setShowUserInfoModal(false);
  const handleUserInfoModalShow = (userInfo) => {
    setModalUserInfo(userInfo);
    setShowUserInfoModal(true);
  };

  const handleLocationInfoModalClose = () => setShowLocationInfoModal(false);
  const handleLocationInfoModalShow = (locationInfo) => {
    console.log(locationInfo);
    setModalLocationInfo(locationInfo);
    setShowLocationInfoModal(true);
  };

  const handleNotificationInfoModalClose = () =>
    setShowNotificationInfoModal(false);
  const handleNotificationInfoModalShow = () =>
    setShowNotificationInfoModal(true);

  const handleEditUser = (userInfo) => {
    navigate(`/settings/company/edit-user/${userInfo.id}`, {
      state: { modalUserInfo: userInfo },
    });
  };

  const handleEditLocation = (location) => {
    setSelectedLocation(location);
    console.log("SELECTED LOCATION", location);
    navigate(`/settings/company/sites/edit-location/${location._id}`, {
      state: { modalLocationInfo: location },
  });
  };

  const handleSort = (field) => {
    let direction = "asc";
    if (sortField === field && sortDirection === "asc") {
      direction = "desc";
    }
    setSortField(field);
    setSortDirection(direction);
  };

  // Sort the data
  const sortedData = Array.isArray(tableData)
    ? [...tableData].sort((a, b) => {
        // Existing sorting logic for other table types
        if (sortField) {
          if (sortDirection === "asc") {
            if (a[sortField] === b[sortField]) {
              return a.firstname.localeCompare(b.firstname);
            }
            return a[sortField].localeCompare(b[sortField]);
          } else {
            if (a[sortField] === b[sortField]) {
              return b.firstname.localeCompare(a.firstname);
            }
            return b[sortField].localeCompare(a[sortField]);
          }
        }
        return 0;
      })
    : [];


  return (
    <>
      {/* if the tabletype is location the display this */}
      {tableType === "location" ? (
        <div className="settings-listing-table table-wrapper">
          <Table>
            <thead>
              <tr className="table-header-tr">
                {Object.keys(tableHeaders).map((key, index) => (
                  <th key={index} className={`table-header__item ${key}`}>
                    <p className="table-header__title">{tableHeaders[key]}</p>
                    {/* If the key is "name" or "location", display the sort button */}
                    {key === "sitename" || key === "location" ? (
                      <button className="sort-button">Sort</button>
                    ) : null}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {
                /* Display no data if the sortedData length is 0*/
                tableData.length === 0 && (
                  <tr>
                    <td
                      colSpan={Object.keys(tableHeaders).length}
                      className="table-data"
                    >
                      Nincs megjeleníthető adat.
                    </td>
                  </tr>
                )
              }
              {tableData.map((data, index) => (
                <tr key={index}>
                  {Object.keys(tableHeaders).map((key, index) => (
                    <td key={index} className={`table-data ${key}`}>
                      <div className="ellipsis">
                        {/* If the key is "moreInfo", display the edit button */}
                        {key === "moreInfo" ? (
                          <Button
                            variant="link"
                            onClick={() => {
                              console.log("DATA", data);
                              handleLocationInfoModalShow(data)}}
                          >
                            <InfoCircleOutlined />
                          </Button>
                        ) : key === "address" ? (
                          <>
                          {console.log("I AM HERE")}
                            {console.log("ADDRESS :", data)}
                            {console.log("ZIP:", data.zip)}
                            {console.log("CITY:", data.sitecity)}
                            {console.log("STREET:", data.sitestreet)}
                            {console.log("HOUSENR:", data.sitehousenr)}
                            {`${data.zip || ""} ${data.sitecity || ""}, ${
                              data.sitestreet || ""
                            } ${data.sitehousenr || ""}`}
                          </>
                        ) : (
                          data[key]
                        )}
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        // If the tabletype is user, display this
        // Display the table
        <div className="settings-listing-table table-wrapper">
          <Table>
            <thead>
              <tr className="table-header-tr">
                {Object.keys(tableHeaders).map((key, index) => (
                  <th key={index} className={`table-header__item ${key}`}>
                    <p className="table-header__title">{tableHeaders[key]}</p>
                    {/* If the key is "name" or "location", display the sort button */}
                    {key === "lastname" ||
                    key === "firstname" ||
                    key === "location" ||
                    key === "sentTime" ? (
                      <Button
                        variant="link"
                        className="table-header-sort__btn"
                        onClick={() => handleSort(key)}
                      >
                        {sortField === key && sortDirection === "asc" ? (
                          <SortDescendingOutlined />
                        ) : (
                          <SortAscendingOutlined />
                        )}
                      </Button>
                    ) : null}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {
                /* Display no data if the sortedData length is 0*/
                sortedData.length === 0 && (
                  <tr>
                    <td
                      colSpan={Object.keys(tableHeaders).length}
                      className="table-data"
                    >
                      Nincs megjeleníthető adat.
                    </td>
                  </tr>
                )
              }
              {sortedData.map((data, index) => (
                <tr key={index}>
                  {Object.keys(tableHeaders).map((key, index) => (
                    <td key={index} className={`table-data ${key}`}>
                      <div className="ellipsis">
                        {/* If the key is "moreInfo", display the edit button */}
                        {key === "userMoreInfo" ? (
                          <Button
                            variant="link"
                            onClick={() => handleUserInfoModalShow(data)}
                          >
                            <InfoCircleOutlined />
                          </Button>
                        ) : key === "locationMoreInfo" ? (
                          <Button
                            variant="link"
                            onClick={() => handleLocationInfoModalShow(data)}
                          >
                            <InfoCircleOutlined />
                          </Button>
                        ) : key === "notificationMoreInfo" ? (
                          <Button
                            variant="link"
                            onClick={handleNotificationInfoModalShow}
                          >
                            <InfoCircleOutlined />
                          </Button>
                        ) : key === "notifications" ? (
                          data[key] ? (
                            "Igen"
                          ) : (
                            "Nem"
                          )
                        ) : key === "roles" ? (
                          roleMapping[data[key]]
                        ) : (
                          data[key]
                        )}
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
          <Modal show={showUserInfoModal} onHide={handleUserInfoModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>{`${modalUserInfo.lastname} ${modalUserInfo.firstname}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Email: {modalUserInfo.email}</p>
              <p>Szerepkör: {roleMapping[modalUserInfo.roles]}</p>
              <p>Telephely: {modalUserInfo.location}</p>
              <p>Értesítések: {modalUserInfo.notifications ? "Igen" : "Nem"}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => modalUserInfo && handleEditUser(modalUserInfo)}
              >
                <EditOutlined /> Módosítás
              </Button>
              <Button variant="secondary" onClick={handleUserInfoModalClose}>
                Bezárás
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={showLocationInfoModal}
            onHide={handleLocationInfoModalClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>{modalLocationInfo.sitename}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Cím: {modalLocationInfo.zip} {modalLocationInfo.sitecity}, {modalLocationInfo.sitestreet} {modalLocationInfo.sitehousenr}</p>
              <p>Raktárak száma: {modalLocationInfo.container_count}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => handleEditLocation(modalLocationInfo)}
              >
                <EditOutlined /> Módosítás
              </Button>
              <Button
                variant="secondary"
                onClick={handleLocationInfoModalClose}
              >
                Bezárás
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={showNotificationInfoModal}
            onHide={handleNotificationInfoModalClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Értesítés részletei</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <strong>Cím:</strong> {notificationsDummyDataHun[0].title}
              </p>
              <p>
                <strong>Üzenet:</strong> {notificationsDummyDataHun[0].message}
              </p>
              <p>
                <strong>Felhasználó:</strong>{" "}
                {notificationsDummyDataHun[0].receivedUser}
              </p>
              <p>
                <strong>Időpont:</strong>{" "}
                {notificationsDummyDataHun[0].sentTime}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={handleNotificationInfoModalClose}
              >
                Bezárás
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
};

export default SettingsListingTable;
