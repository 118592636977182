/**
 * DeviceChart.jsx
 *
 * This component is responsible for rendering a line chart for a specific device.
 *
 * It uses the Chart.js library to create a line chart with zoom functionality.
 * The chart displays data from multiple datasets, with each dataset representing a different sensor.
 *
 * The data for the chart (labels and datasets) is passed in through props.
 * While the data is loading, a spinner is displayed.
 *
 * The chart configuration options are defined in the `options` object.
 * These include options for the x and y axes, tooltips, and zoom functionality.
 *
 * The `zoom` property in the `options` object enables zooming on the chart.
 * The `onZoomComplete` function is used to update the chart after a zoom operation is completed.
 *
 * The component returns a `div` containing the line chart.
 */

import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-moment";
import zoomPlugin from "chartjs-plugin-zoom";
import moment from "moment";
import "moment/locale/hu";

moment.locale("hu");

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  zoomPlugin
);

const DeviceChartForContainerView = ({ deviceId, chartData, timeFormat }) => {
  const [chartDataForChartJs, setChartDataForChartJs] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    console.log("Original chartData:", chartData);

    // Sort chart data by timestamp
    const sortedChartData = [...chartData].sort(
      (a, b) => new Date(a.sens_timestamp) - new Date(b.sens_timestamp)
    );
    console.log("Sorted chartData:", sortedChartData);

    // Get unique probe and sensor combinations
    const uniqueProbesAndSensors = [
      ...new Set(
        sortedChartData.map((item) => `${item.probeid}-${item.sens_num}`)
      ),
    ];
    console.log("Unique Probes and Sensors:", uniqueProbesAndSensors);

    // Get unique timestamps
    const uniqueTimestamps = [
      ...new Set(sortedChartData.map((item) => item.sens_timestamp)),
    ].sort((a, b) => new Date(a) - new Date(b));
    console.log("Unique Timestamps:", uniqueTimestamps);

    // Create datasets
    const datasets = uniqueProbesAndSensors.map((probeAndSensor) => {
      const [probeid, sens_num] = probeAndSensor
        .split("-")
        .map((item, index) =>
          index === 0 ? item.toString() : parseInt(item, 10)
        ); // Convert probeid to string, sens_num to number
      const sensorData = sortedChartData.filter((item) => {
        return (
          item.probeid.toString() === probeid && item.sens_num === sens_num
        ); // Ensure probeid is compared as a string
      });

      // Create a map of timestamp to value
      const dataMap = new Map(
        sensorData.map((item) => [item.sens_timestamp, item.sens_value])
      );

      console.log(`Data Map for Probe ${probeid} Sensor ${sens_num}:`, dataMap);

      // Fill the data array with values corresponding to the unique timestamps
      const data = uniqueTimestamps.map((timestamp) =>
        dataMap.get(timestamp) !== undefined ? dataMap.get(timestamp) : null
      );

      const bgColor = "#" + Math.floor(Math.random() * 16777215).toString(16);

      console.log(`Data for Probe ${probeid} Sensor ${sens_num}:`, data);

      return {
        label: `Szonda ${probeid} - szenzor ${sens_num}`,
        data,
        fill: false,
        backgroundColor: bgColor,
        borderColor: bgColor,
        spanGaps: true, // This option connects the points even if there are gaps
      };
    });

    // Log the final datasets before setting state
    console.log("Final datasets:", datasets);

    // Update chart data state
    setChartDataForChartJs({ labels: uniqueTimestamps, datasets });
  }, [chartData]);

  const options = {
    animation: false,
    pointRadius: 0.5,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "time",
        time: {
          unit: timeFormat === "last24h" ? "hour" : "day",
          tooltipFormat: timeFormat === "last24h" ? "HH:mm" : "MM/DD",
          displayFormats: {
            hour: "HH:mm",
            day: "MM/DD",
          },
        },
        title: {
          display: true,
          text: "Idő",
        },
      },
      y: {
        beginAtZero: false,
        title: {
          display: true,
          text: "Mért érték",
        },
      },
    },
    plugins: {
      tooltip: {
        intersect: false,
        mode: "index",
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || "";
            const value = context.raw;
            return `${label}: ${Math.round(value * 100) / 100}`;
          },
        },
      },
    },
  };

  console.log("chartDataForChartJs", chartDataForChartJs);

  return (
    // Render the line chart if there is chartdata
    <>
      {chartDataForChartJs.datasets.length === 0 ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "200px" }}
        >
          <div>
            <p>
              Nincs elérhető adat a megadott időszakban.
            </p>
          </div>
        </div>
      ) : (
        <div style={{ width: "100%", height: "400px" }}>
          <Line options={options} data={chartDataForChartJs} />
        </div>
      )}
    </>
  );
};

export default DeviceChartForContainerView;
