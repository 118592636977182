import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { PlusCircleOutlined } from "../../../helpers/icons/antDesignIcons";
import "../LocationsPage.scss";


const AddSite = ({ handleAddNewSiteClick }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [siteName, setSiteName] = useState("");
  const [siteCity, setSiteCity] = useState("");
  const [zip, setZip] = useState("");
  const [siteStreet, setSiteStreet] = useState("");
  const [siteHouseNr, setSiteHouseNr] = useState("");

  useEffect(() => {
    console.log("siteName", siteName);
    console.log("siteCity", siteCity);
    console.log("zip", zip);
    console.log("siteStreet", siteStreet);
    console.log("houseNumber", siteHouseNr);
  }, [siteName, siteCity, zip, siteStreet, siteHouseNr]);

  return (
    <div className="add-site__wrapper">
    {/* <ToastContainer /> */}
      <Button
        variant="contained"
        color="primary"
        className="locations-page__header__add__btn"
        onClick={() => setModalIsOpen(true)}
      >
        <PlusCircleOutlined /> Új telephely hozzáadása
      </Button>
      <Modal centered show={modalIsOpen} onHide={() => setModalIsOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title> Új telephely hozzáadása</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="add-site__form">
            <Form.Group className="mb-3">
              <Form.Label>Telephely neve</Form.Label>
              <Form.Control
                type="text"
                placeholder="Telephely neve"
                required
                onChange={(e) => setSiteName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Város</Form.Label>
              <Form.Control
                type="text"
                placeholder="Város"
                required
                onChange={(e) => setSiteCity(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Irányítószám</Form.Label>
              <Form.Control
                type="text"
                placeholder="Irányítószám"
                required
                onChange={(e) => setZip(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Utca</Form.Label>
              <Form.Control
                type="text"
                placeholder="Utca/Helyrajzi szám"
                required
                onChange={(e) => setSiteStreet(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Házszám</Form.Label>
              <Form.Control
                type="text"
                placeholder="Házszám"
                onChange={(e) => setSiteHouseNr(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalIsOpen(false)}>
            Mégse
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={() =>
              handleAddNewSiteClick({
                siteName,
                siteCity,
                zip,
                siteStreet,
                siteHouseNr,
              })
            }
          >
            Hozzáadás
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddSite;
