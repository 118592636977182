/**
 * ContainerGraphView is a component that displays a graphical view of sensor data over time.
 * It allows users to select a time range and specific sensors to filter the data displayed in the graph.
 * Users can also download a screenshot of the graph.
 *
 * Props:
 * - devices: Array of device objects.
 * - sensorsForSelectionInGraphView: Array of sensor objects available for selection.
 * - graphData: Array of data points to be displayed in the graph.
 */

import React, { useEffect, useState, useRef } from "react";
import { Accordion, Button, FormSelect } from "react-bootstrap";
import html2canvas from "html2canvas";
import { toast } from "react-toastify";
import {
  BulbOutlined,
  CameraOutlined,
  CheckOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useScreenWidth } from "../../../../hooks/useScreenWidth";
import DeviceChartForContainerView from "./DeviceChartForContainerView";
import CustomTimeRangePicker from "../../../Shared/CustomTimeRangePicker/CustomTimeRangePicker";
import TimeFrameNotification from "../../../Shared/TimeFrameNotification/TimeFrameNotification";
import useTranslation from "../../../../hooks/useTranslation";

const ContainerGraphView = ({
  devices,
  sensorsForSelectionInGraphView,
  graphData,
}) => {
  const { t } = useTranslation("locationsPage");
  const screenWidth = useScreenWidth();
  const [selectedSensors, setSelectedSensors] = useState(new Set());
  const [filteredGraphData, setFilteredGraphData] = useState([]);
  const [timeFormat, setTimeFormat] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  // date picker related variables
  const [showDatePicker, setShowDatePicker] = useState(false);
  // Define state variables for start and end dates
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const chartContainerRef = useRef(null);

  useEffect(() => {
    if (timeFormat === "custom") {
      setShowDatePicker(true);
    } else {
      setShowDatePicker(false);
    }
  }, [timeFormat]);

  // Functions to calculate start dates for different time ranges
  const getLast24hDate = () => {
    const now = new Date();
    return new Date(now - 24 * 60 * 60 * 1000); // 24 hours ago from the adjusted time
  };
  const getLastWeekStartDate = () => {
    const now = new Date();
    return new Date(now - 7 * 24 * 60 * 60 * 1000); // 7 days ago
  };

  const getLastMonthStartDate = () => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth() - 1, now.getDate()); // 30 days ago
  };

  // Callback functions to update date state in parent for custom date range picker
  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  // Function to handle sensor selection/deselection
  const handleSensorSelection = (deviceId, sensorId, isChecked) => {
    setSelectedSensors((prevSelectedSensors) => {
      const newSelectedSensors = new Set(prevSelectedSensors);
      if (isChecked) {
        newSelectedSensors.add(sensorId);
      } else {
        newSelectedSensors.delete(sensorId);
      }
      return newSelectedSensors;
    });
  };

  // Function to select all sensors
  const handleSelectAllSensors = () => {
    const allSensorIds = sensorsForSelectionInGraphView.map(
      (sensor) => sensor.sensor_id
    );
    setSelectedSensors(new Set(allSensorIds));
  };

  // Function to deselect all sensors
  const handleDeselectAllSensors = () => {
    setSelectedSensors(new Set());
  };

  // Function to submit selected sensors and time format
  const handleSubmitButtonClicked = () => {
    // Validate that a time format and at least one sensor is selected
    if (timeFormat === "") {
      toast.error(t("select_timeframe"));
      return;
    }

    // Validate that at least one sensor is selected
    if (selectedSensors.size === 0) {
      toast.error(t("sensors_not_selected"));
      return;
    }

    // Filter graphData based on selected sensors and time format
    let startDateForFilter;
    switch (timeFormat) {
      case "last24h":
        startDateForFilter = getLast24hDate();
        break;
      case "lastweek":
        startDateForFilter = getLastWeekStartDate();
        break;
      case "lastmonth":
        startDateForFilter = getLastMonthStartDate();
        break;
      case "custom":
        startDateForFilter = startDate;
        break;
      default:
        startDateForFilter = null; // No specific timeframe selected, consider handling accordingly
    }

    // Get current date
    const now = new Date();

    // Filter graphData based on the selected timeframe
    const filtered = graphData.filter((data) => {
      if (!startDateForFilter) return true; // If no start date, include all data
      const itemDate = new Date(data.sens_timestamp);
      // Check if the time format is custom
      if (timeFormat === "custom") {
        return itemDate >= startDateForFilter && itemDate <= endDate; // Use endDate for custom time format
      } else {
        return itemDate >= startDateForFilter && itemDate <= now; // Use now for other time formats
      }
    });

    // Assuming data includes probeid and sens_num, adjust as needed
    const newFilteredGraphData = filtered
      .filter((data) => selectedSensors.has(data.sensor_id))
      .map((data) => ({
        ...data,
        label: `${t("probe")} ${data.probeid} - ${t("sensor")} ${data.sens_num}`, // Update label here; adjust according to how DeviceChart2 uses labels
      }));

    setFilteredGraphData(newFilteredGraphData);
    setIsSubmitted(true); // Indicate that the chart should be displayed
  };

  const groupedSensors = sensorsForSelectionInGraphView.reduce(
    (acc, sensor) => {
      (acc[sensor.probeid] = acc[sensor.probeid] || []).push(sensor);
      return acc;
    },
    {}
  );

  // Group sensors by probeid and sort them
  Object.keys(groupedSensors).forEach((probeid) => {
    groupedSensors[probeid].sort((a, b) => a.sens_num - b.sens_num);
  });

  // Take a screenshot of the chart and download it as an image
  const takeScreenshotAndDownload = () => {
    if (chartContainerRef.current) {
      html2canvas(chartContainerRef.current).then((canvas) => {
        const image = canvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");
        const link = document.createElement("a");
        link.download = "chart-screenshot.png";
        link.href = image;
        link.click();
      });
    } else {
      console.error("Chart container not found");
      toast.error(t("creating_graph_error"));
    }
  };

  return (
    <div className="container-graph-view">
      {/* Time range selection */}
      <div className="cgw__filter__container">
        <FormSelect
          id="timeRangeSelect"
          className="cgw__filter__select"
          aria-label="timeRangeSelect"
          onChange={(e) => setTimeFormat(e.target.value)}
        >
          <option value="">{t("select_timeframe")}...</option>
          <option value="last24h">{t("last24h")}</option>
          <option value="lastweek">{t("lastweek")}</option>
          <option value="lastmonth">{t("lastmonth")}</option>
          <option value="custom">{t("customTimeFrame")}</option>
        </FormSelect>
        {showDatePicker && (
          <CustomTimeRangePicker
            show={showDatePicker}
            setShow={setShowDatePicker}
            onDateChange={handleDateChange}
          />
        )}
        {/* Sensor selection accordion */}
        <Accordion className="cgw-accordion">
          <Accordion.Item eventKey="0">
            <Accordion.Header>{t("select_sensors")}</Accordion.Header>
            <Accordion.Body>
              {/* Sensor selection and submit buttons */}
              <div className="cgw__filter__buttons_wrapper">
                <div className="cgw__filter__select-buttons__wrapper">
                  {/* Select/Deselect all sensors buttons, content is based on screen width */}
                  {screenWidth > 768 ? (
                    <Button
                      className="cgw-select-button"
                      onClick={handleSelectAllSensors}
                    >
                      {t("selectAll")}
                    </Button>
                  ) : (
                    <Button
                      className="cgw-select-button"
                      onClick={handleSelectAllSensors}
                    >
                      <CheckOutlined />
                    </Button>
                  )}
                  {screenWidth > 768 ? (
                    <Button
                      className="cgw-select-button"
                      onClick={handleDeselectAllSensors}
                    >
                      {t("unselectAll")}
                    </Button>
                  ) : (
                    <Button
                      className="cgw-select-button"
                      onClick={handleDeselectAllSensors}
                    >
                      <DeleteOutlined />
                    </Button>
                  )}
                </div>
                <div className="cwg-submit-button__wrapper">
                  <Button
                    onClick={takeScreenshotAndDownload}
                    className="camera-btn"
                  >
                    <CameraOutlined />
                  </Button>
                  {/* Screenshot and submit buttons */}
                  <Button
                    variant="primary"
                    className=" btn cgw-submit-btn"
                    onClick={handleSubmitButtonClicked}
                  >
                    {t("make_graph")}
                  </Button>
                </div>
              </div>
              {/* List of devices and sensors */}
              <div className="cgw-devices-list">
                {Object.entries(groupedSensors).map(
                  ([probeid, sensors], index) => (
                    <div key={index} className="cgw-device-card">
                      <h3>{probeid}</h3>
                      <ul>
                        {sensors.map((sensor) => (
                          <li key={sensor.sensor_id}>
                            <label>
                              <input
                                type="checkbox"
                                name={`sensor-${probeid}`}
                                value={sensor.sensor_id}
                                checked={selectedSensors.has(sensor.sensor_id)} // Ensure checkbox reflects selection state
                                onChange={(e) =>
                                  handleSensorSelection(
                                    sensor.deviceId,
                                    sensor.sensor_id,
                                    e.target.checked
                                  )
                                }
                              />
                              {sensor.sens_num}
                            </label>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )
                )}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      {/* Chart rendering based on submission status */}
      {isSubmitted ? (
        <>
          <div ref={chartContainerRef} className="cgw-chart__container">
            <DeviceChartForContainerView
              chartData={filteredGraphData}
              timeFormat={timeFormat}
            />
          </div>
        </>
      ) : (
        <div className="cgw-chart-loader-div">
          <BulbOutlined />
          {t("graphInfo")}
        </div>
      )}

      {/* Show timeframenotification based on timeformat*/}
      {(timeFormat === "lastmonth" || timeFormat === "custom") && (
        <TimeFrameNotification />
      )}
    </div>
  );
};

export default ContainerGraphView;
