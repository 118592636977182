/**
 * Custom React hook for handling translations in a React application.
 * This hook fetches translation data based on the user's selected language,
 * stores it in the component's state, and provides a function `t` to retrieve
 * translation strings. It also allows changing the language dynamically.
 *
 * Usage:
 * const { t, setLanguage } = useTranslation();
 *
 * - `t(key)`: Function to get the translated value for a given key.
 * - `setLanguage(lang)`: Function to change the current language and trigger a re-fetch of translations.
 */

import { useState, useEffect } from "react";
import { getTranslations } from "../api/translationApi"; // Adjust the import path as needed
import getBrowserLanguage from "../helpers/getBrowserLanguage";

const useTranslation = (module, useBrowserLanguage = false) => {
  // Retrieve the user's preferred language from localStorage or default to Hungarian ('hu')
  const userLanguage = localStorage.getItem("language") || "hu";

  // Determine the language to use
  const initialLanguage = useBrowserLanguage
    ? getBrowserLanguage()
    : userLanguage;

  // State to store the translations fetched from the server
  const [translations, setTranslations] = useState({});
  // State to store the current language
  const [language, setLanguage] = useState(initialLanguage);

  useEffect(() => {
    // Function to fetch translations from the server based on the current language
    const fetchTranslations = async () => {
      try {
        console.log(
          "useTranslation - Fetching translations for language:",
          language,
          "module:",
          module
        ); // Debugging log
        const response = await getTranslations(language, module);

        // Ensure the response data is an object and not null
        if (typeof response === "object" && response !== null) {
          setTranslations(response);
        } else {
          console.error("useTranslation - Invalid translation data:", response);
          // Handle or log invalid translation data
        }
      } catch (error) {
        console.error("useTranslation - Error fetching translations:", error);
      }
    };
    if (language && module) {
      fetchTranslations();
    } else {
      console.error("useTranslation - Language or module is undefined:", {
        language,
        module,
      });
    }
  }, [language, module]); // Re-run the effect if the language or module state changes

  // Function `t` to retrieve a translation value by key
  // Returns the key itself if the translation is not found
  const t = (key) =>
    typeof translations === "object" &&
    translations !== null &&
    key in translations
      ? translations[key]
      : key;

  return { t, setLanguage };
};

export default useTranslation;
