import React, { useContext } from "react";
import UserContext from "../../../../context/UserContext";
import "../ContainerMoreInfoPage.scss";
import { DeleteOutlined } from "../../../../helpers/icons/antDesignIcons";
import ConnectCropToContainerModal from "./ConnectCropToContainerModal";
import USER_ROLES from "../../../../constants/userRoles";

const ContainerCropView = ({
  containerId,
  cropData,
  listOfCropsInCompany,
  showConnectCropToContainerModal,
  handleSubmitConnectCropToContainerButtonClick,
  handleShowConnectCropToContainerModal,
  handleCloseConnectCropToContainerModal,
  selectedCropToConnectToContainer,
  setSelectedCropToConnectToContainer,
  handleShowCropDeleteConfirmationModal,

  setSelectedCropToDeleteFromContainer,
}) => {
  const { user } = useContext(UserContext);
  console.log("containerId", containerId);
  console.log("cropData", cropData);
  console.log("listOfCropsInCompany", listOfCropsInCompany);
  console.log("CROP DATA IN CONTAINER CROP VIEW", cropData);

  // Filter the list of crops in the company to exclude those already in the container
  const filteredCrops = listOfCropsInCompany.filter(
    (crop) =>
      !cropData.some((containerCrop) => containerCrop.crops_id === crop._id)
  );

  return (
    <div>
      <h4 className="container-view-subtitle">Termény nézet</h4>
      <div className="container-crop-more-view-list__wrapper">
        {cropData.length === 0 && (
          <div className="container-crop-more-view-list__content__item">
            <div className="container-crop-more-view-list__content__item__cropname">
              <p>Nincs termény ebben a tárolóban</p>
            </div>
          </div>
        )}
        {cropData.length > 0 && (
          <>
            <div className="container-crop-more-view-list__header">
              <div className="container-crop-more-view-list__header__item">
                <p>Magtárban található termény(ek)</p>
              </div>
            </div>
            <div className="container-crop-more-view-list__content">
              {Array.isArray(cropData) ? (
                cropData.map((crop, index) => (
                  <div
                    key={index}
                    className="container-crop-more-view-list__content__item"
                  >
                    <>
                      <p>{crop.cropname}</p>
                      {/* if user is a superadmin or companyadmin or location leader for the given container, should be able to see the delete button */}
                      {user.roles === USER_ROLES.SUPER_ADMIN ||
                        user.roles === USER_ROLES.COMPANY_ADMIN ||
                        (user.roles === USER_ROLES.LOCATION_LEADER && (
                          <DeleteOutlined
                            onClick={() => {
                              handleShowCropDeleteConfirmationModal(
                                crop.crops_id
                              );
                            }}
                          />
                        ))}
                    </>
                  </div>
                ))
              ) : (
                <div className="container-crop-more-view-list__content__item">
                  <div className="container-crop-more-view-list__content__item__cropname">
                    <p>{cropData.cropname}</p>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {/* if user is a superadmin or company admin or location leader of the given site and container, then be able to connect crops to the container */}
      {(user.roles === USER_ROLES.SUPER_ADMIN ||
        user.roles === USER_ROLES.COMPANY_ADMIN ||
        user.roles === USER_ROLES.LOCATION_LEADER) &&
        filteredCrops.length > 0 && (
          <ConnectCropToContainerModal
            containerId={containerId}
            cropData={cropData}
            listOfCropsInCompany={listOfCropsInCompany}
            showConnectCropToContainerModal={showConnectCropToContainerModal}
            handleSubmitConnectCropToContainerButtonClick={
              handleSubmitConnectCropToContainerButtonClick
            }
            handleShowConnectCropToContainerModal={
              handleShowConnectCropToContainerModal
            }
            handleCloseConnectCropToContainerModal={
              handleCloseConnectCropToContainerModal
            }
            selectedCropToConnectToContainer={selectedCropToConnectToContainer}
            setSelectedCropToConnectToContainer={
              setSelectedCropToConnectToContainer
            }
          />
        )}
    </div>
  );
};

export default ContainerCropView;
