/**
 * CompanyEditLocationPage Component
 * 
 * This component provides a form for editing location details within a company. It allows the user to
 * update various fields such as location name, zip code, city, street, and house number. The component
 * also provides functionality to delete the location. It uses React hooks for state management and form
 * handling, and React Bootstrap for styling and modals.
 * 
 * Props:
 * - fetchUsersAndLocations: Function to fetch users and locations data.
 * 
 * Example usage:
 * <CompanyEditLocationPage fetchUsersAndLocations={fetchUsersAndLocations} />
 */

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getSiteBySiteId,
  updateSiteBySiteId,
} from "../../../../api/locationApi";
import { Form, Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { DeleteOutlined } from "../../../../helpers/icons/antDesignIcons";
import "./CompanyEditLocationPage.scss";

const CompanyEditLocationPage = ({ fetchUsersAndLocations }) => {
  const location = useLocation();
  const { modalLocationInfo } = location.state || {};
  const navigate = useNavigate();
  const [locationData, setLocationData] = useState(location.state || {});
  const [showDeleteLocationModal, setShowDeleteLocationModal] = useState(false);
  const [errors, setErrors] = useState({});

  const fetchLocationInfo = async () => {
    try {
      const response = await getSiteBySiteId(locationData.id);
      setLocationData(response.data);
    } catch (error) {
      console.error("Error fetching location info: ", error);
    }
  };

  useEffect(() => {
    if (!locationData || locationData === undefined || !modalLocationInfo) {
      fetchLocationInfo();
    }
  }, [locationData, modalLocationInfo]);

  useEffect(() => {
    if (modalLocationInfo) {
      console.log("Modal location info: ", modalLocationInfo);
      setLocationData(modalLocationInfo);
    }
  }, [modalLocationInfo]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLocationData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDeleteLocationModalClose = () =>
    setShowDeleteLocationModal(false);

  const handleDeleteLocationModalShow = () => setShowDeleteLocationModal(true);
  const handleDeleteLocation = (event, id) => {
    event.preventDefault();
    // Here you can handle the location deletion, e.g. send a request to a backend
    console.log(`Location with ID ${id} has been deleted`);
    navigate("/settings/company");
  };

  console.log("Modal location info: ", modalLocationInfo);

  const validateCompanySiteData = () => {
    const errors = {};

    if (
      locationData.locationName === "" ||
      locationData.zip === "" ||
      locationData.sitecity === "" ||
      locationData.sitestreet === "" ||
      locationData.sitehousenr === ""
    ) {
      errors.emptyFields = "Minden mező kitöltése kötelező!";
    }
    if (locationData.zip.length > 10) {
      errors.zipLength = "Az irányítószám maximum 10 számjegyből állhat!";
    }
    if (locationData.sitecity.length > 100) {
      errors.cityLength = "A város maximum 100 karakterből állhat!";
    }
    if (locationData.sitestreet.length > 100) {
      errors.streetLength = "Az út/utca maximum 100 karakterből állhat!";
    }
    if (locationData.sitehousenr.length > 10) {
      errors.houseNrLength = "A házszám maximum 10 karakterből állhat!";
    }
    return errors;
  };

  const handleLocationEditSubmit = (event) => {
    event.preventDefault();

    const validationErrors = validateCompanySiteData();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      Object.values(validationErrors).forEach((error) => {
        toast.error(error);
      });
      return;
    }

    console.log("Location data to be updated: ", locationData);

    try {
      const { _id, ...updatedFields } = locationData;
      updateSiteBySiteId(locationData._id, updatedFields);
      toast.success("Helyszín adatok sikeresen módosítva!");
    } catch (error) {
      console.error("Error updating location: ", error);
    }

    // Here you can handle the location edit, e.g. send a request to a backend
    console.log("Location has been edited");
    navigate("/settings/company");
  };

  const handleEditBackButton = () => {
    navigate("/settings/company");
  };

  if (!locationData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container company-edit-location-page__container navbar-margin">
      <div className="page-title-wrapper">
        <h1 className="page-title">Telephelyadatok módosítása</h1>
        <div className="page-title-delete__wrapper">
          <p className="page-title-delete_p">Telephely törlése</p>
          <button
            className="page-title-delete__button"
            onClick={handleDeleteLocationModalShow}
          >
            <DeleteOutlined />
          </button>
        </div>
      </div>
      <Modal
        show={showDeleteLocationModal}
        onHide={handleDeleteLocationModalClose}
        className="company-edit-user-page__delete-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Telephely törlése</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Biztosan törölni szeretné ezt a telephelyet?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleDeleteLocationModalClose}
            className="btn btn-secondary"
          >
            Mégsem
          </Button>
          <Button
            variant="danger"
            onClick={handleDeleteLocation}
            className="btn btn-danger"
          >
            Törlés
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="company-edit-location-page__content">
        <Form onSubmit={handleLocationEditSubmit}>
          <Form.Group className="mb-3" controlId="formBasicLocationName">
            <Form.Label>Telephely neve</Form.Label>
            <Form.Control
              type="text"
              placeholder="Telephely neve"
              name="sitename"
              value={locationData?.sitename || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicLocationZip">
            <Form.Label>Irányítószám</Form.Label>
            <Form.Control
              type="text"
              placeholder="Irányítószám"
              name="zip"
              value={locationData?.zip || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicLocationCity">
            <Form.Label>Város</Form.Label>
            <Form.Control
              type="text"
              placeholder="Város"
              name="sitecity"
              value={locationData?.sitecity || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicLocationStreet">
            <Form.Label>Út/utca</Form.Label>
            <Form.Control
              type="text"
              placeholder="Út/utca"
              name="sitestreet"
              value={locationData?.sitestreet || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicLocationHouseNr">
            <Form.Label>Házszám</Form.Label>
            <Form.Control
              type="text"
              placeholder="Házszám"
              name="sitehousenr"
              value={locationData?.sitehousenr || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
          {/*           <Form.Group className="mb-3" controlId="formBasicLocationLeader">
            <Form.Label>Telephelyvezető</Form.Label>
            <Form.Control
              as="select"
              name="locationLeader"
              value={locationData}
                onChange={handleInputChange}
            >
                {locationLeaders.map((leader, index) => (
                    <option key={index} value={leader}>
                        {leader}
                    </option>
                    ))}
            </Form.Control>
          </Form.Group> */}
          <div className="company-edit-user-page__button__wrapper">
            <Button
              variant="primary"
              type="submit"
              className="btn btn-save company-edit-user-page__save-button"
            >
              Módosítás
            </Button>
            <Button
              variant="secondary"
              onClick={handleEditBackButton}
              className="company-edit-user-back-button"
            >
              Vissza
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CompanyEditLocationPage;
