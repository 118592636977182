const sensorNumber = 1;
const temperature = 35;
const maxTemperature = 30;
const minTemperature = 10;
const sensorLocation = "Warehouse 1";

export const notificationsDummyData = [
  {
    id: 1,
    title: "High temperature alert",
    message: `Sensor ${sensorNumber} is registering a high temperature (${temperature}°C) in ${sensorLocation}. Maximum allowed temperature is ${maxTemperature}°C. Please check the system immediately.`,
    status: "unread",
    receivedUser: "Jóska",
    sentTime: "2021-09-01T12:00:00",
  },
  {
    id: 2,
    title: "Low temperature alert",
    message: `Sensor ${sensorNumber} is registering a low temperature (${temperature}°C) in ${sensorLocation}. Minimum allowed temperature is ${minTemperature}°C. Please check the system immediately.`,
    status: "read",
    receivedUser: "Ferenc",
    sentTime: "2021-09-01T12:00:00",
  },
  {
    id: 3,
    title: "Device offline alert",
    message: `Sensor ${sensorNumber} is offline in ${sensorLocation}. Please check the system immediately.`,
    status: "read",
    receivedUser: "Viktor",
    sentTime: "2021-09-01T12:00:00",
  },
  {
    id: 4,
    title: "High temperature alert",
    message: `Sensor ${sensorNumber} is registering a high temperature (${temperature}°C) in ${sensorLocation}. Maximum allowed temperature is ${maxTemperature}°C. Please check the system immediately.`,
    status: "read",
    receivedUser: "Péter",
    sentTime: "2021-09-01T12:00:00",
  },
];

export const notificationsDummyDataHun = [
    {
      id: 1,
      title: "Magas hőmérséklet riasztás",
      message: `A(z) ${sensorNumber}. érzékelő magas hőmérsékletet (${temperature}°C) regisztrál a(z) ${sensorLocation}-ban. A maximálisan engedélyezett hőmérséklet ${maxTemperature}°C. Kérjük, azonnal ellenőrizze a rendszert.`,
      status: "olvasatlan",
      receivedUser: "Jóska",
      sentTime: "2021-09-01T12:00:00",
    },
    {
      id: 2,
      title: "Alacsony hőmérséklet riasztás",
      message: `A(z) ${sensorNumber}. érzékelő alacsony hőmérsékletet (${temperature}°C) regisztrál a(z) ${sensorLocation}-ban. A minimálisan engedélyezett hőmérséklet ${minTemperature}°C. Kérjük, azonnal ellenőrizze a rendszert.`,
      status: "olvasott",
      receivedUser: "Ferenc",
      sentTime: "2021-09-01T12:00:00",
    },
    {
      id: 3,
      title: "Eszköz offline riasztás",
      message: `A(z) ${sensorNumber}. érzékelő offline állapotban van a(z) ${sensorLocation}-ban. Kérjük, azonnal ellenőrizze a rendszert.`,
      status: "olvasott",
      receivedUser: "Viktor",
      sentTime: "2021-09-01T12:00:00",
    },
    {
      id: 4,
      title: "Magas hőmérséklet riasztás",
      message: `A(z) ${sensorNumber}. érzékelő magas hőmérsékletet (${temperature}°C) regisztrál a(z) ${sensorLocation}-ban. A maximálisan engedélyezett hőmérséklet ${maxTemperature}°C. Kérjük, azonnal ellenőrizze a rendszert.`,
      status: "olvasott",
      receivedUser: "Péter",
      sentTime: "2021-09-01T12:00:00",
    },
    {
        id: 5,
        title: "Magas hőmérséklet riasztás",
        message: `A(z) ${sensorNumber}. érzékelő magas hőmérsékletet (${temperature}°C) regisztrál a(z) ${sensorLocation}-ban. A maximálisan engedélyezett hőmérséklet ${maxTemperature}°C. Kérjük, azonnal ellenőrizze a rendszert.`,
        status: "olvasott",
        receivedUser: "Péter",
        sentTime: "2021-09-01T12:00:00",
      },
      {
        id: 6,
        title: "Magas hőmérséklet riasztás",
        message: `A(z) ${sensorNumber}. érzékelő magas hőmérsékletet (${temperature}°C) regisztrál a(z) ${sensorLocation}-ban. A maximálisan engedélyezett hőmérséklet ${maxTemperature}°C. Kérjük, azonnal ellenőrizze a rendszert.`,
        status: "olvasott",
        receivedUser: "Péter",
        sentTime: "2021-09-01T12:00:00",
      },
      {
        id: 7,
        title: "Magas hőmérséklet riasztás",
        message: `A(z) ${sensorNumber}. érzékelő magas hőmérsékletet (${temperature}°C) regisztrál a(z) ${sensorLocation}-ban. A maximálisan engedélyezett hőmérséklet ${maxTemperature}°C. Kérjük, azonnal ellenőrizze a rendszert.`,
        status: "olvasott",
        receivedUser: "Péter",
        sentTime: "2021-09-01T12:00:00",
      },
      {
        id: 8,
        title: "Magas hőmérséklet riasztás",
        message: `A(z) ${sensorNumber}. érzékelő magas hőmérsékletet (${temperature}°C) regisztrál a(z) ${sensorLocation}-ban. A maximálisan engedélyezett hőmérséklet ${maxTemperature}°C. Kérjük, azonnal ellenőrizze a rendszert.`,
        status: "olvasott",
        receivedUser: "Péter",
        sentTime: "2021-09-01T12:00:00",
      },
  ];
