/**
 * CustomTimeRangePicker Component
 * 
 * This component allows users to select a custom time range using a date picker.
 * It provides visual feedback for the selected start and end dates, and ensures
 * that the selected dates are not in the future. The component uses React hooks
 * for state management and side effects, and interacts with an external function
 * to handle date changes.
 * 
 * Props:
 * - show: Boolean to control the visibility of the date picker.
 * - setShow: Function to set the visibility of the date picker.
 * - onDateChange: Function to handle the date change event, receiving the start and end dates.
 * 
 * Key Features:
 * - Select a start and end date using a date picker.
 * - Ensure the selected dates are not in the future.
 * - Display visual feedback for the selected dates.
 * - Provide a footer with instructions or the selected dates.
 * - Use React hooks for state management and side effects.
 * 
 * Example usage:
 * <CustomTimeRangePicker 
 *   show={show} 
 *   setShow={setShow} 
 *   onDateChange={(start, end) => console.log(start, end)} 
 * />
 */


import React, { useEffect, useState } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { hu } from "date-fns/locale";
import { toast } from "react-toastify";
import "./CustomTimeRangePicker.scss";

const CustomTimeRangePicker = ({ show, setShow, onDateChange }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const today = new Date().setHours(0, 0, 0, 0);
/*   const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth(); */

  const handleDayClick = (day) => {
    const dayTimestamp = new Date(day).setHours(0, 0, 0, 0);

    // Check if the selected day is in the future
    if (dayTimestamp > today) {
      toast.error("A kezdő és/vagy a befejező dátum nem lehet a jövőben!");
      return;
    }

    // Setting start date if no date is selected or if both dates are already selected
    if (!startDate || (startDate && endDate)) {
      setStartDate(day);
      // Reset endDate if it was previously set
      if (endDate) setEndDate(null);
      return;
    }

    // Setting end date if start date is selected and it's before the selected day
    if (startDate && !endDate) {
      if (dayTimestamp > new Date(startDate).setHours(0, 0, 0, 0)) {
        setEndDate(day);
      } else {
        // If the selected day is before the start date, reset start date
        setStartDate(day);
      }
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      onDateChange(startDate, endDate);
    }
  }, [startDate, endDate, onDateChange]);

  return (
    <div>
      <DayPicker
        selectedDays={[startDate, { from: startDate, to: endDate }]}
        onDayClick={handleDayClick}
        locale={hu}
        showOutsideDays
        numberOfMonths={1}
        mode="range"
        captionLayout="dropdown"
        startMonth={new Date(2022, 0)}
        endMonth={new Date(2026, 11)}
        modifiers={{
          selectedStart: [startDate], // special style for the start date
          selectedEnd: [endDate], // special style for the end date
        }}
        modifiersStyles={{
          selectedStart: {
            backgroundColor: "#F3CA52",
            color: "#333",
            fontWeight: "800",
          },
          selectedEnd: {
            backgroundColor: "#F3CA52",
            color: "#333",
            fontWeight: "800",
          },
        }}
        footer={
          startDate === null && endDate === null ? (
            "Válasszon kezdő és befejező dátumot"
          ) : (
            <>
              <div>
                Kezdő dátum: <strong>{startDate.toLocaleDateString()}</strong>
              </div>
              {endDate && (
                <div>
                  Befejező dátum:{" "}
                  <strong>{endDate.toLocaleDateString()}</strong>
                </div>
              )}
            </>
          )
        }
      />
    </div>
  );
};

export default CustomTimeRangePicker;
