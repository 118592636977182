import React, { useContext, useEffect, useState } from "react";
import {
  getAllCropsByCompanyId,
  addNewCrop,
  updateCrop,
  deleteCropById,
} from "../../api/cropApi";
import UserContext from "../../context/UserContext";
import { Button, Table } from "react-bootstrap";
import {
  DeleteOutlined,
  EditOutlined,
} from "../../helpers/icons/antDesignIcons";
import "./CropsPage.scss";
import AddNewCropModal from "./CropModals/AddNewCropModal";
import EditCropModal from "./CropModals/EditCropModal";
import { toast } from "react-toastify";
import ConfirmationModal from "../Shared/ConfirmationModal/ConfirmationModal";
import USER_ROLES from "../../constants/userRoles";
import TooltipButton from "../Shared/TooltipButton/TooltipButton";
import PageHeader from "../Shared/PageHeader/PageHeader";
import useTranslation from "../../hooks/useTranslation";

const CropsPage = () => {
  const { user } = useContext(UserContext);
  const companyId = user.company_id;
  const { t } = useTranslation("cropsPage");
  const [crops, setCrops] = useState([]);
  const [showEditCropModal, setShowEditCropModal] = useState(false);
  const [selectedCropForEdit, setSelectedCropForEdit] = useState(null);
  const [showDeleteCropModal, setShowDeleteCropModal] = useState(false);
  const [selectedCropForDelete, setSelectedCropForDelete] = useState(null);
  const cropsTableHeaders = {
    cropname: t("cropname"),
    /*     quantity: "Mennyiség", */
    status: t("status"),
    description: t("description"),
    edit: t("edit"),
    delete: t("delete"),
  };

  const fetchCrops = async () => {
    try {
      const cropsData = await getAllCropsByCompanyId(companyId);
      if (Array.isArray(cropsData)) {
        setCrops(cropsData);
      } else {
        console.error("Invalid data:", cropsData);
      }
    } catch (error) {
      console.error("Error fetching crops: ", error);
    }
  };

  useEffect(() => {
    fetchCrops();
  }, [companyId]);

  useEffect(() => {
    if (selectedCropForEdit) {
      const filteredCropForEdit = crops.find(
        (crop) => crop.cropId === selectedCropForEdit._id
      );
      setSelectedCropForEdit(filteredCropForEdit);
    }
  }, [selectedCropForEdit, crops]);

  const handleAddNewModalSubmit = async (newCrop) => {
    try {
      await addNewCrop(companyId, newCrop);
      await fetchCrops();
      toast.success(t("addNewCropSuccess"));
    } catch (error) {
      console.error("Error adding new crop:", error);
      toast.error(t("addNewCropError"));
    }
  };

  const handleEditModalSubmit = async (updatedCrop) => {
    const bodyContent = {
      cropname: updatedCrop.cropname,
      description: updatedCrop.description,
      status: updatedCrop.status,
    };
    const cropId = updatedCrop._id;
    try {
      await updateCrop(cropId, bodyContent);
      setShowEditCropModal(false);
      toast.success(t("editCropSuccess"));
      fetchCrops();
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.error.trim();
        console.error("Error response data:", errorMessage);
        if (errorMessage === "Crop already exists with the given name") {
          console.error("Error updating crop:", errorMessage);
          toast.error(t("cropExistsError"));
        } else {
          console.error("Error updating crop:", errorMessage);
          toast.error(t("editCropError"));
        }
      } else {
        console.error("Error updating crop:", error);
        toast.error(t("editCropError"));
      }
    }
  };

  const handleDeleteCropClick = (crop) => {
    console.log("Selected crop for delete:", crop);
    setShowDeleteCropModal(true);
    setSelectedCropForDelete(crop);
  };

  const handleDeleteCropSubmit = async (crop) => {
    const cropId = crop._id;
    try {
      await deleteCropById(cropId);
      setShowDeleteCropModal(false);
      toast.success(t("deleteCropSuccess"));
      fetchCrops();
    } catch (error) {
      console.error("Error deleting crop:", error);
      toast.error(t("deleteCropError"));
    }
  };

  return (
    <div className="container crops-page__container navbar-margin">
      <PageHeader
        title={t("crops_title")}
        helperTitle={t("crops_title")}
        helperBody={t("crops_helper")}
      />
      <div className="crops-page__content">
        {/* if the user is superadmin or companyadmin, they could add new crops/ see this button */}
        {user.roles === USER_ROLES.SUPER_ADMIN ||
        user.roles === USER_ROLES.COMPANY_ADMIN ? (
          <div className="crops-page__header">
            <AddNewCropModal onSubmit={handleAddNewModalSubmit} />
          </div>
        ) : null}
        <div className="table__wrapper">
          <Table
            headers={cropsTableHeaders}
            data={crops}
            className="crops-page__table"
          >
            <thead>
              <tr className="table-header-tr">
                {Object.values(cropsTableHeaders).map((header) => (
                  <th
                    key={header}
                    className="table-header__item crops-page__table-header-item"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {crops.length === 0 && (
                <tr>
                  <td
                    colSpan={Object.keys(cropsTableHeaders).length}
                    className="table-data crops-page__table-no-data"
                  >
                    {t("noCropData")}
                  </td>
                </tr>
              )}
              {crops.map((crop, index) => (
                <tr key={index} className="crops-page__table-row">
                  <td className="table-data crops-page__table-data">
                    {crop.cropname}
                  </td>
                  {/*         {crop.quantity === null || crop.quantity === undefined ? (
                    <td className="table-data crops-page__table-data">
                      Nincs megadva
                    </td>
                  ) : (
                    <td className="table-data crops-page__table-data">
                      {crop.quantity}
                    </td>
                  )} */}
                  {/*             <td className="table-data crops-page__table-data">
                  {crop.unit}
                </td> */}
                  {crop.status === null || crop.status === undefined ? (
                    <td className="table-data crops-page__table-data">
                      {t("status_unknown")}
                    </td>
                  ) : (
                    <td className="table-data crops-page__table-data">
                      {crop.status}
                    </td>
                  )}
                  {crop.description === null ||
                  crop.description === undefined ? (
                    <td className="table-data crops-page__table-data crops-page__table-data__description">
                      {t("status_unknown")}
                    </td>
                  ) : (
                    <td className="table-data crops-page__table-data crops-page__table-data__description">
                      {crop.description}
                    </td>
                  )}
                  <td>
                    {user.roles === USER_ROLES.SUPER_ADMIN ||
                    user.roles === USER_ROLES.COMPANY_ADMIN ? (
                      <EditCropModal
                        show={showEditCropModal}
                        onClose={() => setShowEditCropModal(false)}
                        setSelectedCropForEdit={setSelectedCropForEdit}
                        selectedCropForEdit={selectedCropForEdit}
                        onSubmit={handleEditModalSubmit}
                        crop={crop}
                      />
                    ) : (
                      <TooltipButton
                        variant="contained"
                        className="crops-page-list__edit-button"
                        tooltipKey="tooltipNoPermission"
                      >
                        <EditOutlined />
                      </TooltipButton>
                    )}
                  </td>
                  <td>
                    {user.roles === USER_ROLES.SUPER_ADMIN ||
                    user.roles === USER_ROLES.COMPANY_ADMIN ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          handleDeleteCropClick(crop);
                        }}
                        className="crops-page-list__delete-button"
                      >
                        <DeleteOutlined />
                      </Button>
                    ) : (
                      <TooltipButton
                        variant="contained"
                        className="crops-page-list__delete-button"
                        tooltipKey="tooltipNoPermission"
                      >
                        <DeleteOutlined />
                      </TooltipButton>
                    )}
                    {selectedCropForDelete && (
                      <ConfirmationModal
                        isVisible={showDeleteCropModal}
                        onConfirm={() =>
                          handleDeleteCropSubmit(selectedCropForDelete)
                        }
                        onCancel={() => setShowDeleteCropModal(false)}
                        message={t("deleteConfirmationMessage")}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default CropsPage;
