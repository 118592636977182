/**
 * UserSettingsPage.jsx
 *
 * This component is a page for managing user settings. It includes editable fields for the user's name, email, password, language, and timezone.
 * Each field is represented by an EditableSettingItem component, which allows the user to edit the value of the field.
 * The component uses local state to store the current values of the fields and a honeypot field for spam protection.
 * The handleSave function is used to validate and save the new values entered by the user.
 * In the future, this component will be integrated with a backend to persist the changes made by the user.
 */

import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../../context/UserContext";
import { useNavigate } from "react-router-dom";
import "./UserSettingsPage.scss";
import {
  getUserSettingsById,
  getLanguageOptions,
  updateUserNameInSettingsById,
  updateUserEmailInSettingsById,
  updateUserPasswordInSettingsById,
  updateUserLanguageInSettingsById,
  updateUserTimezoneInSettingsById,
} from "../../../api/settingsUserApi";
import { getUserByEmail } from "../../../api/userApi";
import EditableSettingItem from "../Shared/EditableSettingItem/EditableSettingItem";
import RedButton from "../../Shared/RedButton/RedButton";
import { LogoutOutlined } from "../../../helpers/icons/antDesignIcons";
import { toast } from "react-toastify";

const UserSettingsPage = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [language, setLanguage] = useState("");
  const [timezone, setTimezone] = useState("");
  const [honeypot, setHoneypot] = useState("");
  const [languageOptions, setLanguageOptions] = useState([]);
  const [timezoneOptions, setTimezoneOptions] = useState([1, 2, 3, 4, 5]);
  const [isPassword, setIsPassword] = useState(false);


  const fetchUserSettings = async () => {
    // Fetch user settings from the backend
    const response = await getUserSettingsById(user.userId);
    console.log("User settings: ", response);

    // Update the state with the fetched values
    setName(response.lastname + " " + response.firstname);
    setEmail(response.email);
    setLanguage(response.language);
    setTimezone(response.timezone_offset);
  };

  // Fetch user settings when the component mounts
  useEffect(() => {
    fetchUserSettings();
  }, []);

  const fetchLanguageOptions = async () => {
    // Fetch language options from the backend
    const response = await getLanguageOptions();
    setLanguageOptions(response);
  };

  // Fetch language options when the component mounts
  useEffect(() => {
    fetchLanguageOptions();
  }, []);

  const updateUserName = async (name) => {
    const firstname = name.split(" ")[1];
    const lastname = name.split(" ")[0];

    try {
      await updateUserNameInSettingsById(user.userId, firstname, lastname);
      localStorage.setItem("firstname", firstname);
      localStorage.setItem("lastname", lastname);
      toast.success("Név sikeresen frissítve!");
    } catch (error) {
      toast.error("Hiba történt a név frissítése közben.");
    }
  };

  const updateEmail = async (email) => {
    try {
      const newEmail = await getUserByEmail(email);
      if (newEmail) {
        toast.error(
          "Hiba történt az e-mail frissítése közben. Előfordulhat, hogy nem jó a formátum vagy ez az e-mail cím már használatban van, kérjük próbálja meg egy másikkal."
        );
        return;
      } else {
        await updateUserEmailInSettingsById(user.userId, email);
        localStorage.setItem("email", email);
        toast.success("E-mail sikeresen frissítve!");
      }
    } catch (error) {
      console.log("Error: ", error);
      toast.error(
        "Hiba történt az e-mail frissítése közben. Előfordulhat, hogy nem jó a formátum vagy ez az e-mail cím már használatban van, kérjük próbálja meg egy másikkal."
      );
      throw new Error(error);
    }
  };

  const updatePassword = async (password) => {
    try {
      await updateUserPasswordInSettingsById(user.userId, password);
      toast.success("Jelszó sikeresen frissítve!");
    } catch (error) {
      toast.error("Hiba történt a jelszó frissítése közben.");
    }
  };

  const updateLanguage = async (language) => {
    console.log("Language: ", language);
    try {
      await updateUserLanguageInSettingsById(user.userId, language);
      localStorage.setItem("language", language);
      toast.success("Nyelv sikeresen frissítve!");
    } catch (error) {
      toast.error("Hiba történt a nyelv frissítése közben.");
    }
  };

  const updateTimezone = async (timezone) => {
    const formattedTimezone = timezone.replace("UTC+", "");
    try {
      await updateUserTimezoneInSettingsById(user.userId, formattedTimezone);
      localStorage.setItem("timezone_offset", formattedTimezone);
      toast.success("Időzóna sikeresen frissítve!");
    } catch (error) {
      toast.error("Hiba történt az időzóna frissítése közben.");
    }
  };

  const handleSave = async (newValue, id) => {
    let isValid = true;
    let errorMessage = "";

    if (honeypot) return;

    if (id === "name" && (newValue.trim() === "" || newValue.length > 50)) {
      isValid = false;
      errorMessage = "A név mező nem lehet üres és maximum 50 karakter lehet.";
    } else if (id === "email" && !/^\S+@\S+\.\S+$/.test(newValue)) {
      isValid = false;
      errorMessage = "Kérjük hogy érvényes e-mail címet adjon meg.";
    } else if (id === "password" && newValue.length < 14) {
      isValid = false;
      errorMessage =
        "A jelszónak legalább 14 karakter hosszúnak kell lennie és tartalmaznia kell legalább egy kis- és nagybetűt valamint egy különleges karaktert.";
    } else if (id === "timezone" && newValue.length > 500) {
      isValid = false;
      errorMessage = "Description should be less than 500 characters.";
    } else if (
      id === "language" &&
      !languageOptions
        .map((option) => option.language_code.toUpperCase())
        .includes(newValue.toUpperCase())
    ) {
      isValid = false;
      errorMessage = "Kérjük válasszon a listából.";
    } else if (
      id === "timezone" &&
      !timezoneOptions.map((option) => `UTC+${option}`).includes(newValue)
    ) {
      isValid = false;
      errorMessage = "Kérjük válasszon a listából.";
    }

    if (!isValid) {
      toast.error(errorMessage); // show the error message
      return;
    }

    try {
      switch (id) {
        case "name":
          await updateUserName(newValue);
          setName(newValue);
          break;
        case "email":
          await updateEmail(newValue);
          setEmail(newValue);
          break;
        case "password":
          await updatePassword(newValue);
          break;
        case "language":
          await updateLanguage(newValue);
          setLanguage(newValue);
          break;
        case "timezone":
          await updateTimezone(newValue);
          console.log("New value: ", newValue);
          setTimezone(localStorage.getItem("timezone_offset"));
          break;
        default:
          break;
      }
    } catch (error) {
      console.error("Hiba történt a módosítás közben.");
    }
  };

  return (
    <div className="container user-settings-page__container navbar-margin">
      <h1 className="page-title">Felhasználói beállítások</h1>
      <div className="user-settings-page__content">
        <EditableSettingItem
          id="name"
          title="Név"
          value={name}
          onSave={handleSave}
          type="text"
        />
        <EditableSettingItem
          id="email"
          title="E-mail"
          value={email}
          onSave={handleSave}
          type="email"
        />
        <EditableSettingItem
          id="password"
          title="Jelszó"
          value={password}
          onSave={handleSave}
          type="password"
          isPassword={true}
        />
        <EditableSettingItem
          id="language"
          title="Nyelv"
          value={language.toUpperCase()}
          onSave={handleSave}
          type="dropdown"
          options={languageOptions.map((option) =>
            option.language_code.toUpperCase()
          )}
        />
        <EditableSettingItem
          id="timezone"
          title="Időzóna"
          value={`UTC+${timezone}`}
          onSave={handleSave}
          type="dropdown"
          options={timezoneOptions.map((option) => `UTC+${option}`)}
        />
        <input
          type="text"
          name="honeypot"
          value={honeypot}
          onChange={(e) => setHoneypot(e.target.value)}
          style={{ display: "none" }}
        />
      </div>
      <RedButton
        icon={<LogoutOutlined />}
        content="Kijelentkezés"
        onClick={() => {
          localStorage.clear();
          navigate("/");
        }}
      />
    </div>
  );
};

export default UserSettingsPage;
