/**
 * DashboardPage Component
 *
 * This component represents the dashboard page of the application. It fetches and displays
 * active threshold violation measurements based on the user's role and company. The component
 * also provides functionality to acknowledge alerts and dynamically adjusts table headers
 * based on the user's role. Additionally, it includes a helper modal to guide users on what
 * they can do on this page.
 *
 * Key functionalities:
 * - Fetches user role from JWT token stored in localStorage.
 * - Fetches active threshold violation measurements from the API.
 * - Dynamically adjusts table headers based on user role.
 * - Provides a loading spinner while data is being fetched.
 * - Allows users to acknowledge alerts.
 * - Includes a helper modal to guide users on the page functionalities.
 */

import { jwtDecode } from "jwt-decode";
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import UserContext from "../../context/UserContext";
import DashboardSection from "./reusable/DashboardSection";
import "./DashboardPage.scss";
import {
  getAllActiveThresholdViolationMeasurements,
  acknowledgeThresholdViolationByRecordId,
} from "../../api/sensorApi";
import Spinner from "react-bootstrap/Spinner";
import { UserRoleProvider } from "../../context/userRoleContext";
import { toast } from "react-toastify";
import PageHeader from "../Shared/PageHeader/PageHeader";
import useTranslation from "../../hooks/useTranslation";
import moment from "moment-timezone";
import USER_ROLES from "../../constants/userRoles";

export function GrowExample() {
  return <Spinner animation="grow" />;
}

const DashboardPage = () => {
  const { user } = useContext(UserContext);
  const companyId = user.company_id;
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [dashboardAlertItems, setDashboardAlertItems] = useState([]);
  const [tableHeaders, setTableHeaders] = useState({});
  const { t } = useTranslation("dashboardPage");

  // Extract user role from token outside of useEffect to prevent unnecessary re-renders
  const token = localStorage.getItem("token");
  const decodedToken = token ? jwtDecode(token) : {};
  const userRole = decodedToken.role || "";

  // Define headers once and filter based on role
  const getTableHeaders = useCallback(
    (t, role) => {
      const baseHeaders = {
        probeid: t("probeId"),
        sens_num: t("sensNum"),
        containername: t("containerName"),
        sitename: t("siteName"),
        sens_timestamp: t("sensTimestamp"),
        sens_value: t("measuredValue"),
        min_value: t("minValue"),
        max_value: t("maxValue"),
        user: t("user"),
        companyname: t("company"),
        moreInfoModalButton: "",
        acknowledgedButton: "",
      };

      // Filter headers based on user role
      if (role === USER_ROLES.COMPANY_ADMIN) {
        delete baseHeaders.companyname;
      } else if (role === USER_ROLES.COMPANYUSER) {
        delete baseHeaders.sitename;
        delete baseHeaders.companyname;
        delete baseHeaders.user;
      }
      return baseHeaders;
    },
    []
  );

  // Fetch translations and set headers when role or translations change
  useEffect(() => {
    if (userRole) {
      const headers = getTableHeaders(t, userRole);
      setTableHeaders(headers);
    }
  }, [userRole, t, getTableHeaders]);

  // Fetch alert items
  const fetchAlertItems = useCallback(async () => {
    if (user && user.token) {
      try {
        const alerts = await getAllActiveThresholdViolationMeasurements(companyId, user.token);

        // Adjust alerts based on user's browser timezone
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const adjustedAlerts = alerts.map((alert) => {
          const adjustedTimestamp = moment.utc(alert.sens_timestamp).tz(userTimezone).format();
          return { ...alert, sens_timestamp: adjustedTimestamp };
        });

        setDashboardAlertItems(adjustedAlerts);
      } catch (error) {
        console.error(error);
      }
    }
  }, [user, companyId]);

  useEffect(() => {
    if (user && user.token) {
      fetchAlertItems();
      setIsPageLoaded(true);
    }
  }, [user, companyId, fetchAlertItems]);

  // Handle acknowledgment of threshold violation
  const handleAcknowledge = async (thresholdViolationRecord) => {
    console.log("Record:", thresholdViolationRecord);
    const recordId = thresholdViolationRecord._id;
    console.log("Record ID:", recordId);

    try {
      await acknowledgeThresholdViolationByRecordId(recordId);
      fetchAlertItems();
      toast.success(t("dashboardItemApproveSuccess"));
    } catch (error) {
      console.error(error);
      toast.error(t("dashboardItemApproveError"));
    }
  };

  return (
    <UserRoleProvider>
      <>
        {isPageLoaded === false && (
          <div className="loader">
            <GrowExample />
          </div>
        )}
        {isPageLoaded && (
          <div className="container dashboard-page__container navbar-margin">
            <PageHeader
              title={t("dashboard_title")}
              helperTitle={t("dashboard_title")}
              helperBody={t("dashboard_helper")}
            />
            <div className="dashboard__grid">
              <DashboardSection
                tableHeaders={tableHeaders}
                title={t("dashboard_table_title")}
                items={dashboardAlertItems}
                linkTo="/alerts"
                handleAcknowledge={handleAcknowledge}
              />
              {/*           <DashboardSection
            tableHeaders={tableHeaders}
            title="Jelzések"
            items={dashboardWarningItems}
            linkTo="/warnings"
          /> */}
              {/*             <DashboardSection
              tableHeaders={locationTableHeaders}
              title="Helyszínek"
              items={dashboardLocationItems}
              linkTo="/locations"
            /> */}
            </div>
          </div>
        )}
      </>
    </UserRoleProvider>
  );
};

export default DashboardPage;
