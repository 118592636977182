import axios from "axios";
const backendUrl = process.env.REACT_APP_BACKEND_URL_FOR_FRONTEND;
const validToken = localStorage.getItem("token");

export const getAllUsers = async () => {
  try {
    const users = await axios.get("/users");
    return users.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getUserById = async (id) => {
  try {
    const user = await axios.get(`/users/${id}`);
    return user.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getUserByEmail = async (email) => {
  try {
    const user = await axios.get(`${backendUrl}/users/${email}`, {
      headers: {
        Authorization: `Bearer ${validToken}`,
      },
    });
    return user.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const registerUser = async (user) => {
  try {
    const newUser = await axios.post("/users", user);
    return newUser.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const sendResetPasswordEmail = async (email) => {
  try {
    const user = await axios.post(
      `${backendUrl}/users/reset-password`,
      { email },
      {
        headers: {
          Authorization: `Bearer ${validToken}`,
        },
      }
    );
    return user.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const resetPassword = async (token, password) => {
  try {
    const response = await axios.put(
      `${backendUrl}/users/reset-password/${token}`,
      { password },
      {
        headers: {
          Authorization: `Bearer ${validToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};
