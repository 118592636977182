/**
 * LocationsPage Component
 *
 * Summary:
 * This component represents the Locations page of the application. It allows users to view, add, and manage sites and containers within a company. Users can filter containers by site, connect devices and bases to containers, and dynamically update the table data based on user interactions.
 *
 * Key functionalities:
 * - Fetches and displays sites and containers based on the user's company ID.
 * - Allows users to filter containers by site.
 * - Provides buttons to add new sites and containers.
 * - Allows users to connect devices and bases to containers.
 * - Dynamically updates the table data based on user interactions.
 * - Displays notifications for successful or failed operations.
 * - Utilizes the toast notification library to display notifications.
 * - Includes a helper modal to guide users on the page functionalities.
 */

import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import {
  getAllContainersWithNoBaseByCompanyId,
  getAllSitesByCompanyId,
  getAllContainersByCompanyId,
  addNewSiteByCompanyId,
  addNewContainerBySiteId,
} from "../../api/locationApi";
import { getAllDevicesWithNoContainerByCompanyId } from "../../api/deviceApi";
import {
  getBasesWithNoContainerAndProbeIdByCompanyId,
  getAllBasesInContainersByCompanyId,
} from "../../api/baseApi";
import ConnectDeviceToContainerButton from "../Shared/ConnectDeviceToContainerButton/ConnectDeviceToContainerButton";
import { FormSelect } from "react-bootstrap";
import "./LocationsPage.scss";
import LocationTable from "./LocationTable/LocationTable";
import ConnectBaseToContainerButton from "../Shared/ConnectBaseToContainerButton/ConnectBaseToContainerButton";
import AddSite from "./AddButtons/AddSite";
import AddContainer from "./AddButtons/AddContainer";
import USER_ROLES from "../../constants/userRoles";
import { toast } from "react-toastify";
import PageHeader from "../Shared/PageHeader/PageHeader";
import useTranslation from "../../hooks/useTranslation";

const LocationsPage = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const companyId = user.company_id;
  const { t } = useTranslation("locationsPage");
  // SITES AND CONTAINERS STATES
  const [sites, setSites] = useState([]);
  const [containers, setContainers] = useState([]);
  const [selectedSite, setSelectedSite] = useState(null);
  const [selectedSiteForAddContainer, setSelectedSiteForAddContainer] =
    useState(null);
  const [filteredContainers, setFilteredContainers] = useState([]);
  const [containerListForBases, setContainerListForBases] = useState([]);

  // DEVICES STATE
  const [devices, setDevices] = useState([]);

  // BASES STATE
  const [bases, setBases] = useState([]);
  const [baseListForDelete, setBaseListForDelete] = useState([]);

  // TABLE HEADERS AND DATA
  const locationsTableHeaders = {
    containerName: t("containerName"),
    siteName: t("siteName"),
    containertype: t("containerType"),
    edit: t("edit"),
    delete: t("delete"),
  };
  const [locationsTableData, setLocationsTableData] = useState([]);

  // Fetch and refresh table data
  const refreshTableData = async () => {
    try {
      const containersData = await getAllContainersByCompanyId(companyId);
      setContainers(containersData.data);
      setLocationsTableData(containersData.data);
      return containersData.data; // Ensure it returns the updated list
    } catch (error) {
      console.error("Error fetching containers: ", error);
      return []; // Return empty array in case of error
    }
  };

  // Fetch bases with no container and probe ID
  const fetchBasesWithNoContainerAndProbeIdByCompanyId = async () => {
    try {
      const basesData = await getBasesWithNoContainerAndProbeIdByCompanyId(
        companyId
      );
      setBases(basesData);
    } catch (error) {
      console.error("Error fetching bases: ", error);
    }
  };

  // Fetch containers with no base
  const fetchContainersWithNoBase = async () => {
    try {
      const containersData = await getAllContainersWithNoBaseByCompanyId(
        companyId
      );
      setContainerListForBases(containersData.data);
      console.log("containersData", containersData.data);
    } catch (error) {
      console.error("Error fetching containers: ", error);
    }
  };

  // Fetch bases in containers
  const fetchBasesInContainers = async () => {
    try {
      const basesData = await getAllBasesInContainersByCompanyId(companyId);
      setBaseListForDelete(basesData);
      console.log("BASES DATA: ", basesData);
    } catch (error) {
      console.error("Error fetching bases: ", error);
    }
  };

  // Fetch sites and containers
  const fetchSitesAndContainers = async () => {
    try {
      const sitesData = await getAllSitesByCompanyId(companyId);
      setSites(sitesData.data);
      const containersData = await getAllContainersByCompanyId(companyId);
      setContainers(containersData.data);
      setLocationsTableData(containersData.data);
      setFilteredContainers(containersData.data);
    } catch (error) {
      console.error("Error fetching sites: ", error);
    }
  };

  // Fetch sites and containers on component mount
  useEffect(() => {
    fetchSitesAndContainers();
  }, []);

  // Fetch devices by company ID
  useEffect(() => {
    const fetchDevicesByCompanyId = async () => {
      try {
        const devicesData = await getAllDevicesWithNoContainerByCompanyId(
          companyId
        );
        setDevices(devicesData);
      } catch (error) {
        console.error("Error fetching devices: ", error);
      }
    };
    fetchDevicesByCompanyId();
  }, [companyId]);

  useEffect(() => {
    fetchBasesWithNoContainerAndProbeIdByCompanyId();
  }, [companyId]);

  // Fetch bases with no container and probe ID on company ID change
  useEffect(() => {
    fetchContainersWithNoBase();
  }, []);

  // Fetch containers with no base on component mount
  useEffect(() => {
    fetchBasesInContainers();
  }, []);

  // Handle site change
  const handleSiteChange = async (e) => {
    const siteName = e.target.value;
    setSelectedSite(siteName);
    try {
      const containersData = await getAllContainersByCompanyId(companyId);
      if (siteName === "all") {
        setContainers(containersData.data);
      } else {
        setContainers(
          containersData.data.filter(
            (container) => container.sitename === siteName
          )
        );
      }
    } catch (error) {
      console.error("Error fetching containers: ", error);
    }
  };

  // Handle add new site click
  const handleAddNewSiteClick = async (siteData) => {
    try {
      await addNewSiteByCompanyId(companyId, siteData);
      toast.success("Telephely sikeresen hozzáadva!");
      fetchSitesAndContainers();
    } catch (error) {
      console.error("Error adding new site: ", error);
      toast.error("Hiba történt a telephely hozzáadásakor!");
    }
  };

  // Handle add new container click
  const handleAddNewContainerClick = async (containerData) => {
    console.log("containerData", containerData);
    try {
      await addNewContainerBySiteId(containerData);
      toast.success("Tároló sikeresen hozzáadva!");
      fetchSitesAndContainers();
    } catch (error) {
      console.error("Error adding new container: ", error);
      toast.error("Hiba történt a tároló hozzáadásakor!");
    }
  };

  return (
    <div className="container locations-page__container navbar-margin">
      <PageHeader
        title={t("location_title")}
        helperTitle={t("location_title")}
        helperBody={t("location_helper")}
      />

      <div className="location-page-content">
        <div className="locations-page__header">
          <div className="locations-page__header__filter__wrapper">
            <div className="locations-page__header__filter">
              <span className="locations-page__header__filter__label">
                {t("sites")}:
              </span>
              <FormSelect
                id="locations-locationstype"
                className="locations-page__header__filter__select"
                value={selectedSite || "all"}
                onChange={handleSiteChange}
              >
                <option value="all">{t("all")}</option>
                {sites.map((site) => (
                  <option key={site._id} value={site.siteid}>
                    {site.sitename}
                  </option>
                ))}
              </FormSelect>
            </div>
            {/* if user is superadmin or company admin should see these buttons */}
            {user.roles === USER_ROLES.SUPER_ADMIN ||
            user.roles === USER_ROLES.COMPANY_ADMIN ? (
              <div className="location-page__header__btn__wrapper">
                <div className="location-page__header__connect-btn__wrapper">
                  <ConnectBaseToContainerButton
                    baselist={bases}
                    containerList={containerListForBases}
                    baseListForDelete={baseListForDelete}
                    companyId={companyId}
                    fetchBasesWithNoContainerAndProbeIdByCompanyId={
                      fetchBasesWithNoContainerAndProbeIdByCompanyId
                    }
                    fetchContainersWithNoBase={fetchContainersWithNoBase}
                    fetchBasesInContainers={fetchBasesInContainers}
                  />
                  <ConnectDeviceToContainerButton
                    deviceList={devices}
                    containerList={containers}
                    siteList={sites}
                  />
                </div>
                <div className="locations-page__header__add__btn__wrapper">
                  <AddSite handleAddNewSiteClick={handleAddNewSiteClick} />
                  <AddContainer
                    handleAddNewContainerClick={handleAddNewContainerClick}
                    siteList={sites}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>{" "}
        <LocationTable
          tableHeader={locationsTableHeaders}
          tabledata={containers}
          selectedSite={selectedSite}
          refreshTableData={refreshTableData}
        />
      </div>
    </div>
  );
};

export default LocationsPage;
