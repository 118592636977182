import axios from "axios";
import { getAuthHeaders } from "./auth";
const backendUrl = process.env.REACT_APP_BACKEND_URL_FOR_FRONTEND;
const token = localStorage.getItem("token");
const userId = localStorage.getItem("userId");

export const getAllSensors = async () => {
  try {
    const sensors = await axios.get(`${backendUrl}/sensors`, {
      headers: getAuthHeaders(),
    });
    return sensors.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getAllSensorsByCompanyId = async (companyId) => {
  try {
    const sensors = await axios.get(
      `${backendUrl}/sensors/company/${companyId}`,
      {
        headers: getAuthHeaders(),
      }
    );
    return sensors.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getAllSensorsWithNoMesDataByContainerId = async (containerId) => {
  try {
    const sensors = await axios.get(
      `${backendUrl}/sensors/containerwithnodata/${containerId}`,
      {
        headers: getAuthHeaders(),
      }
    );
    return sensors.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getAllSensorsByContainerId = async (containerId) => {
  try {
    const sensors = await axios.get(
      `${backendUrl}/sensors/container/${containerId}/${userId}`,
      {
        headers: getAuthHeaders(),
      }
    );
    console.log("Sensors:", sensors.data);
    return sensors.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getAllSensorsWithEveryDataByContainerId = async (containerId) => {
  try {
    const sensors = await axios.get(
      `${backendUrl}/sensors/containerwitheverydata/${containerId}`,
      {
        headers: getAuthHeaders(),
      }
    );
    return sensors.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getAllSensorsByDeviceId = async (deviceId) => {
  try {
    const sensors = await axios.get(
      `${backendUrl}/sensors/device/${deviceId}`,
      {
        headers: getAuthHeaders(),
      }
    );
    return sensors.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getAllSensorsWithLatestDataByDeviceId = async (
  deviceId,
  userId
) => {
  try {
    const sensors = await axios.get(
      `${backendUrl}/sensors/sensorswithlatestdata/${deviceId}/${userId}`,
      {
        headers: getAuthHeaders(),
      }
    );
    return sensors.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getAllSensorsByContainerIdAndDeviceId = async (
  containerId,
  deviceId
) => {
  try {
    const sensors = await axios.get(
      `${backendUrl}/sensors/container/${containerId}/device/${deviceId}`,
      {
        headers: getAuthHeaders(),
      }
    );
    return sensors.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getSensorById = async (sensorId) => {
  try {
    const sensor = await axios.get(`${backendUrl}/sensors/${sensorId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return sensor.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getAllActiveThresholdViolationMeasurements = async () => {
  try {
    const measurements = await axios.get(
      `${backendUrl}/sensors/thresholdviolationmeasurements`,
      {
        headers: getAuthHeaders(),
      }
    );
    return measurements.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getAllThresholdViolationMeasurements = async () => {
  try {
    const measurements = await axios.get(
      `${backendUrl}/sensors/thresholdviolationmeasurements/all`,
      {
        headers: getAuthHeaders(),
      }
    );
    return measurements.data;
  } catch (error) {
    throw new Error(error.message);
  }
};


// UPDATE

export const updateSensorThresholdsBySensorId = async (
  sensorId,
  thresholds,
  userId
) => {
  try {
    const updatedSensor = await axios.patch(
      `${backendUrl}/sensors/thresholds/${sensorId}`,
      { ...thresholds, userId },
      {
        headers: getAuthHeaders(),
      }
    );
    return updatedSensor.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const updateEverySensorThresholdsByContainerId = async (
  containerId,
  thresholds
) => {
  try {
    const updatedSensors = await axios.patch(
      `${backendUrl}/sensors/thresholdsincontainer/${containerId}`,
      { ...thresholds, userId },
      {
        headers: getAuthHeaders(),
      }
    );
    return updatedSensors.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const updateEverySensorThresholdsAdditiveByContainerId = async (
  containerId,
  thresholds
) => {
  console.log("Starting updateEverySensorThresholdsAdditiveByContainerId");
  console.log("containerId:", containerId);
  console.log("thresholds:", thresholds);
  console.log("userId:", userId);

  try {
    const updatedSensors = await axios.patch(
      `${backendUrl}/sensors/thresholdsadditiveincontainer/${containerId}`,
      { ...thresholds, userId },
      {
        headers: getAuthHeaders(),
      }
    );

    console.log("Updated sensors:", updatedSensors.data);
    return updatedSensors.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const acknowledgeThresholdViolationByRecordId = async (recordId) => {
  console.log("Starting acknowledgeThresholdViolationBySensorId IN API CALL");
  console.log("RecordID:", recordId);
  try {
    const updatedThresholdViolationRecord = await axios.patch(
      `${backendUrl}/sensors/thresholdviolationmeasurements/${recordId}`,
      {},
      {
        headers: getAuthHeaders(),
      }
    );
    return updatedThresholdViolationRecord.data;
  } catch (error) {
    throw new Error(error.message);
  }
};