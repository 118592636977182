import React, { useState } from "react";
import { resetPassword } from "../../../api/userApi";
import { useParams, Link } from "react-router-dom";
import { Button, Form, FormLabel } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import GPLogo from "../../../assets/images/logo/gp-logo-trans-w100.webp";
import "./ResetPasswordPage.scss";
import useTranslation from "../../../hooks/useTranslation";

const ResetPasswordPage = () => {
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const { t } = useTranslation("loginRelatedPages", true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== passwordConfirm) {
      toast.error(t("notMatchPassword"));
      return;
    }
    try {
      const response = await resetPassword(token, password);
      if (response.status === 200) {
        toast.success(t("successfulPasswordReplacement"));
      } else if (response.status === 404) {
        toast.error(t("unsuccessfulPasswordReplacement"));
      } else {
        toast.error(t("unsuccessfulPasswordReplacement"));
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="bg-div-resetpassword-page">
      <ToastContainer />
      <div className="container reset-password-page__container">
        <div className="reset-password-page__content__container">
          {" "}
          <img
            src={GPLogo}
            alt="GP Logo"
            className="reset-password-page__logo"
          />
          <h1 className="reset-password-page__form-title">
            {t("resetPasswordTitle")}
          </h1>
          <p className="reset-password-page__form-subtitle">
            {t("resetPasswordSubtitle")}
          </p>
          <Form onSubmit={handleSubmit} className="reset-password-page__form">
            <div className="reset-password-page__password-input-container">
              <FormLabel htmlFor="passwordconfirm">{t("password")}:</FormLabel>
              <Form.Control
                id="passwordconfirm"
                className="reset-password-page__password-input"
                type="password"
                placeholder={t("minPassLengthPlaceholder")}
                required
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="reset-password-page__password-input-container">
              <FormLabel htmlFor="password">{t("passwordRepeat")}:</FormLabel>
              <Form.Control
                id="password"
                className="reset-password-page__password-input"
                type="password"
                placeholder={t("password")}
                required
                onChange={(e) => setPasswordConfirm(e.target.value)}
              />
            </div>
            <Button
              type="submit"
              className="reset-password-page__submit-button"
            >
              {t("resetPasswordButton")}
            </Button>
          </Form>
          <div className="forgot-password-page__login-link">
            <Link to="/" className="btn btn-secondary">
              {t("backToLogin")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
