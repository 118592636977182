/**
 * NotificationSettings is a component that displays a list of editable settings related to notifications.
 *
 * @component
 * @returns {JSX.Element} A React component that represents a list of editable settings related to notifications.
 */

import React from "react";
import EditableSettingItem from "../../Shared/EditableSettingItem/EditableSettingItem";

const NotificationSettings = () => {
  return (
    <>
      <EditableSettingItem
        id="getNotifications"
        title="Értesítések fogadása"
        value="Be"
        onSave={() => {}}
        type="dropdown"
        options={["Be", "Ki"]}
      />
      <EditableSettingItem
        id="wayOfNotification"
        title="Értesítés módja"
        value="Email"
        onSave={() => {}}
        type="dropdown"
        options={["Email", "SMS", "Push értesítés"]}
      />
      <EditableSettingItem
        id="notificationEmail"
        title="Értesítési email cím"
        value=""
        onSave={() => {}}
        type="email"
      />
      <EditableSettingItem
        id="notificationPhone"
        title="Értesítési telefonszám"
        value=""
        onSave={() => {}}
        type="phone"
      />
    </>
  );
};

export default NotificationSettings;
