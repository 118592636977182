/**
 * DeviceMoreInfoPage.jsx
 *
 * This component is responsible for displaying more detailed information about a specific device.
 *
 * It includes a dropdown for selecting a duration (last 24 hours, last week, last month, or custom),
 * and a button for initiating a search based on the selected duration.
 *
 * The `handleSelectedOption` function updates the selected option when the dropdown value changes.
 *
 * The `handleDurationSearch` function fetches sensor data for the selected duration when the search button is clicked.
 * It sets the loading state to true, clears any previous sensor data, and then fetches new sensor data based on the selected duration.
 * If the selected duration is 'custom', it ensures that start and end dates are selected.
 * After the sensor data is fetched, it updates the sensor data state variable and sets the loading state to false.
 *
 * The component returns a `div` containing the dropdown, search button, and a chart displaying the sensor data.
 */

import React, { useState, useEffect, useRef } from "react";
import { SensorDataContext } from "../../../context/SensorContext";
import { useLocation } from "react-router-dom";
import { Button, Form, FormSelect, Spinner } from "react-bootstrap";
import DeviceChartForContainerView from "../../LocationsPage/ContainerMoreInfoPage/ContainerGraphView/DeviceChartForContainerView";
import {
  /*   getAllSensorsByDeviceId,
  getAllDeviceDataByDeviceId, */
  getLast24hDataByDeviceId,
  getLastWeekDataByDeviceId,
  getLastMonthDataByDeviceId,
  getCustomDataByDeviceIdAndDuration,
} from "../../../api/deviceApi";
import { getAllSensorsWithLatestDataByDeviceId } from "../../../api/sensorApi";
import {
  BulbOutlined,
  SearchOutlined,
} from "../../../helpers/icons/antDesignIcons";
import "./DeviceMoreInfoPage.scss";
import "react-day-picker/dist/style.css";
import SensorsList from "./Sensors/SensorsList/SensorsList";
import CustomTimeRangePicker from "../../Shared/CustomTimeRangePicker/CustomTimeRangePicker";
import TimeFrameNotification from "../../Shared/TimeFrameNotification/TimeFrameNotification";
import useTranslation from "../../../hooks/useTranslation";

const DeviceMoreInfoPage = (props) => {
  const location = useLocation();
  const { t } = useTranslation("devicesPage");
  const device = location.state ? location.state.device : {};
  const { probeid = "default-probeid" } = device;
  const userId = localStorage.getItem("userId");
  const [sensorsData, setSensorData] = useState([]);
  const [sensorDataForList, setSensorDataForList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchClicked, setSearchClicked] = useState(false);
  // Time filter form states
  const [selectedOption, setSelectedOption] = useState("last24h");
  const [timeUnit, setTimeUnit] = useState("24h");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const chartContainerRef = useRef(null);

  useEffect(() => {
    if (!Array.isArray(sensorsData)) {
      const fetchSensorsData = async () => {
        setIsLoading(true);
        try {
          const sensorData = await getLast24hDataByDeviceId(probeid);
          setSensorData(sensorData);
          setIsLoading(false);
        } catch (error) {
          console.error(error);
          setIsLoading(false);
        }
      };
      fetchSensorsData();
    }
  }, [sensorsData, probeid]);
  // Fetch sensordata for graph
  useEffect(() => {
    const fetchSensorsData = async () => {
      setIsLoading(true);
      try {
        const sensorData = await getLast24hDataByDeviceId(device.probeid);
        setSensorData(sensorData);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchSensorsData();
  }, [device]);

  //Fetch sensordata for lists

  useEffect(() => {
    const fetchSensorsDataForList = async () => {
      try {
        const sensorData = await getAllSensorsWithLatestDataByDeviceId(
          device.probeid,
          userId
        );

        if (Array.isArray(sensorData)) {
          const latestSensorData = Object.values(
            sensorData.reduce((acc, sensor) => {
              if (
                !acc[sensor._id] ||
                new Date(sensor.sens_timestamp) >
                  new Date(acc[sensor._id].sens_timestamp)
              ) {
                acc[sensor._id] = sensor;
              }
              return acc;
            }, {})
          );
          setSensorDataForList(latestSensorData);
        } else {
          console.error(
            "Expected sensorData to be an array, but got:",
            sensorData
          );
        }
        console.log(sensorData);
      } catch (error) {
        console.error(error);
      }
    };
    fetchSensorsDataForList();
  }, [device, userId]);

  // Function to refresh sensor data
  const refreshSensorDataForList = async () => {
    try {
      const sensorData = await getAllSensorsWithLatestDataByDeviceId(
        device.probeid,
        userId
      );

      if (Array.isArray(sensorData)) {
        const latestSensorData = Object.values(
          sensorData.reduce((acc, sensor) => {
            if (
              !acc[sensor._id] ||
              new Date(sensor.sens_timestamp) >
                new Date(acc[sensor._id].sens_timestamp)
            ) {
              acc[sensor._id] = sensor;
            }
            return acc;
          }, {})
        );
        setSensorDataForList(latestSensorData);
      } else {
        console.error(
          "Expected sensorData to be an array, but got:",
          sensorData
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Check if the window size changes, to rerender the graph with the new size
  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //
  useEffect(() => {
    if (selectedOption === "custom") {
      setShowDatePicker(true);
    } else {
      setShowDatePicker(false);
    }
  }, [selectedOption]);

  const handleSelectedOption = (event) => {
    setSelectedOption(event.target.value);
  };

  // Callback functions to update date state in parent for custom date range picker
  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  /**
   * This function handles the search operation based on the selected duration.
   * It fetches sensor data for the selected duration and updates the state variables accordingly.
   */

  // Function to handle the search operation based on the selected duration
  const handleDurationSearch = async () => {
    setIsLoading(true);
    setSearchClicked(true);
    setSensorData([]);

    let sensorData;

    setTimeUnit(selectedOption);

    try {
      switch (selectedOption) {
        case "last24h":
          sensorData = await getLast24hDataByDeviceId(device.probeid);
          break;
        case "lastweek":
          sensorData = await getLastWeekDataByDeviceId(device.probeid);
          break;
        case "lastmonth":
          sensorData = await getLastMonthDataByDeviceId(device.probeid);
          break;
        case "custom":
          // For custom duration, ensure start and end dates are selected
          if (startDate && endDate) {
            // Adjust the dates if they are a day earlier
            /*             const timezoneOffset = startDate.getTimezoneOffset() * 60000;
            const start = new Date(startDate - timezoneOffset)
              .toISOString()
              .split("T")[0];

            endDate.setDate(endDate.getDate() + 1);
            const end = new Date(endDate - timezoneOffset)
              .toISOString()
              .split("T")[0];

            console.log("start", start);
            console.log("end", end); */

            sensorData = await getCustomDataByDeviceIdAndDuration(
              device.probeid,
              startDate,
              endDate
            );
          } else {
            console.error(
              "Start date and end date must be selected for custom duration"
            );
          }
          break;
        default:
          console.error("Invalid selected option");
      }

      if (sensorData) {
        setSensorData(sensorData);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Spinner animation="border" />;
  }

  return (
    <div className="container devices-page__container navbar-margin">
      <h1>{device.probeid}</h1>
      <div className="devices-page-filter-time-form-wrapper">
        <Form className="devices-page-filter-time-form">
          <Form.Group
            controlId="devices-page-filter-time-form-group"
            className="devices-page-filter-time-form-group"
          >
            <Form.Label>Mért időszak:</Form.Label>
            <FormSelect
              aria-label="Időtartam"
              className="devices-page-filter-time-select"
              onChange={handleSelectedOption}
              value={selectedOption}
            >
              <option value="last24h">Elmúlt 24 óra</option>
              <option value="lastweek">Elmúlt hét</option>
              <option value="lastmonth">Elmúlt hónap</option>
              <option value="custom">Egyedi időszak</option>
            </FormSelect>
          </Form.Group>
          {showDatePicker && (
            <CustomTimeRangePicker
              show={showDatePicker}
              setShow={setShowDatePicker}
              onDateChange={handleDateChange}
            />
          )}

          <Button onClick={handleDurationSearch}>
            <SearchOutlined /> Keresés
          </Button>
        </Form>
      </div>
      {/* Show timeframenotification based on timeformat*/}
      {(selectedOption === "lastmonth" || selectedOption === "custom") && (
        <TimeFrameNotification />
      )}

      {/* Chart rendering based on submission status */}
      {searchClicked ? (
        <>
          <div ref={chartContainerRef} className="cgw-chart__container">
            {Array.isArray(sensorsData) && (
              <DeviceChartForContainerView
                chartData={sensorsData}
                timeFormat={timeUnit}
              />
            )}
          </div>
        </>
      ) : (
        <div className="cgw-chart-loader-div">
          <BulbOutlined />A grafikon megjelenítéséhez válasszon időintervallumot
          és szenzort
        </div>
      )}

      {/*       {Array.isArray(sensorsData) && (
        <DeviceChartForContainerView
          chartData={sensorsData}
          timeFormat={timeUnit}
        />
      )} */}

      <SensorDataContext.Provider
        value={{
          refreshSensorData: refreshSensorDataForList,
          sensorDataForList,
        }}
      >
        <SensorsList sensorsData={sensorDataForList} />
      </SensorDataContext.Provider>
    </div>
  );
};

export default DeviceMoreInfoPage;
