/**
 * ForgotPasswordPage component.
 * This component is used to render the forgot password page.
 *
 * @module components/ForgotPasswordPage
 */

import React, { useState } from "react";
import { sendResetPasswordEmail } from "../../../api/userApi";
import { Link } from "react-router-dom";
import { Form, FormLabel } from "react-bootstrap";
import "./ForgotPasswordPage.scss";
import GPLogo from "../../../assets/images/logo/gp-logo-trans-w100.webp";
import Lock from "../../../assets/images/forgotpassword/send.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import useTranslation from "../../../hooks/useTranslation";

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("");
  const { t } = useTranslation("loginRelatedPages", true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await sendResetPasswordEmail(email);
      if (
        response.status === 200 ||
        response.status === 404 ||
        response.status === 400
      ) {
        toast.info(
          "Amennyiben létezik ez a fiók, elküldtük a jelszó visszaállító linket a megadott email címére."
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="bg-div-forgotpassword-page">
      <ToastContainer />
      <div className="container forgot-password-page__container">
        <div className="forgot-password-page__content__container">
          <img
            src={GPLogo}
            alt="GP Logo"
            className="forgot-password-page__logo"
          />
          <img src={Lock} alt="Lock" className="forgot-password-page__lock" />
          <h1 className="login-page__form-title">{t("forgotYourPassword")}</h1>
          <p className="login-page__form-subtitle ">
            {t("forgotYourPasswordSubtitle")}
          </p>
          <Form onSubmit={handleSubmit}>
            <FormLabel htmlFor="email">{t("emailaddress")}:</FormLabel>
            <Form.Control
              id="email"
              type="email"
              placeholder={t("emailPlaceholder")}
              required
              autoComplete="on"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button type="submit" className="btn btn-primary">
            {t("send")}
            </button>
          </Form>
          <div className="forgot-password-page__login-link">
            <Link to="/" className="btn btn-secondary">
              {t("backToLogin")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
