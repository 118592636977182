/**
 * TooltipButton Component
 * 
 * This component renders a button with a tooltip. The tooltip text is fetched
 * using the `useTranslation` hook from the `react-i18next` library, allowing
 * for internationalization support. The tooltip text is determined by the 
 * `tooltipKey` prop, which is used to look up the appropriate translation.
 * 
 * Props:
 * - children: The content to be displayed inside the button.
 * - tooltipKey: The key used to fetch the tooltip text from the translation files.
 * - ...props: Any additional props to be passed to the Button component.
 * 
 * Example usage:
 * <TooltipButton tooltipKey="example.tooltip" variant="primary">
 *   Click Me
 * </TooltipButton>
 */

import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import useTranslation from "../../../hooks/useTranslation"; // Adjust the import path as needed

const TooltipButton = ({ children, tooltipKey, ...props }) => {
  const { t } = useTranslation('common'); // Specify the namespace

  return (
    <OverlayTrigger
      overlay={<Tooltip id="tooltip-disabled">{t(tooltipKey)}</Tooltip>}
    >
      <span className="d-inline-block">
        <Button {...props}>
          {children}
        </Button>
      </span>
    </OverlayTrigger>
  );
};

export default TooltipButton;