/**
 * NotificationModal is a component that displays a modal with details about a notification.
 *
 * @component
 * @param {object} props - The properties that define the NotificationModal component.
 * @param {boolean} props.showNotificationInfoModal - A boolean that controls whether the modal is shown or not.
 * @param {function} props.handleNotificationInfoModalClose - A function that is called when the modal needs to be closed.
 * @param {object} props.notificationDetails - An object that contains the details about the notification.
 * @param {string} props.notificationDetails.title - The title of the notification.
 * @param {string} props.notificationDetails.message - The message of the notification.
 * @param {string} props.notificationDetails.receivedUser - The user who received the notification.
 * @param {string} props.notificationDetails.sentTime - The time when the notification was sent.
 * @returns {JSX.Element} A React component that represents a modal with notification details.
 */

import React from "react";
import { Button, Modal } from "react-bootstrap";
import moment from "moment";

const NotificationModal = ({
  showNotificationInfoModal,
  handleNotificationInfoModalClose,
  notificationTableHeaders,
  notificationDetails,
}) => {
  return (
    <>
      <Modal
        show={showNotificationInfoModal}
        onHide={handleNotificationInfoModalClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Értesítés részletei</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {notificationDetails ? (
            <>
              {Object.keys(notificationTableHeaders).map(
                (key, index) =>
                  key !== "notificationMoreInfo" && (
                    <p key={index} className={`notification-detail ${key}`}>
                      <strong>{notificationTableHeaders[key]}:</strong>{" "}
                      {key === "sens_timestamp" || key === "acknowledged_at"
                        ? moment(notificationDetails[key]).format(
                            "YYYY.MM.DD HH:mm:ss"
                          )
                        : notificationDetails[key]}
                    </p>
                  )
              )}
              {/* Additional notification details */}
              <p>
                <strong>Értesítést kapta:</strong> {notificationDetails.user}
              </p>
            </>
          ) : (
            <p>Nincs elérhető értesítési adat.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleNotificationInfoModalClose}
          >
            Bezárás
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NotificationModal;
