import "./styles/main.scss";
import React, { useState } from "react";
import { useLocation, Route, Routes } from "react-router-dom";
import UserContext from "./context/UserContext";
import NavigationBar from "./components/NavigationBar/NavigationBar";
// LOGIN RELATED IMPORTS
import LoginPage from "./components/LoginPage/LoginPage";
import NotImplementedPage from "./components/NotImplementedPage/NotImplementedPage";
import ForgotPasswordPage from "./components/LoginPage/ForgotPasswordPage/ForgotPasswordPage";
import ResetPasswordPage from "./components/LoginPage/ResetPasswordPage/ResetPasswordPage";
// DASHBOARD RELATED IMPORTS
import DashboardPage from "./components/DashboardPage/DashboardPage";
// REPORTS RELATED IMPORTS
import ReportsPage from "./components/ReportsPage/ReportsPage";
// LOCATION RELATED IMPORTS
import LocationsPage from "./components/LocationsPage/LocationsPage";
import ContainerMoreInfoPage from "./components/LocationsPage/ContainerMoreInfoPage/ContainerMoreInfoPage";
// DEVICE RELATED IMPORTS
import DevicesPage from "./components/DevicesPage/DevicesPage";
import DeviceMoreInfoPage from "./components/DevicesPage/DeviceMoreInfoPage/DeviceMoreInfoPage";
// CROP RELATED IMPORTS
import CropsPage from "./components/CropsPage/CropsPage";
// SETTINGS RELATED IMPORTS
import SettingsPage from "./components/SettingsPage/SettingsPage";
import UserSettingsPage from "./components/SettingsPage/UserSettingsPage/UserSettingsPage";
import CompanySettingsPage from "./components/SettingsPage/CompanySettingsPage/CompanySettingsPage";
import CompanyEditUserPage from "./components/SettingsPage/CompanySettingsPage/CompanyEditUserPage/CompanyEditUserPage";
import CompanyEditLocationPage from "./components/SettingsPage/CompanySettingsPage/CompanyEditLocationPage/CompanyEditLocationPage";
// ALERT RELATED IMPORTS
import NotificationSettingsPage from "./components/SettingsPage/NotificationSettingsPage/NotificationSettingsPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [user, setUser] = useState({
    token: localStorage.getItem("token"),
    userId: localStorage.getItem("userId"),
    roles: localStorage.getItem("roles"),
    lastname: localStorage.getItem("lastname"),
    firstname: localStorage.getItem("firstname"),
    company_id: localStorage.getItem("company_id"),
    companyname: localStorage.getItem("companyname"),
  });

  const location = useLocation();
  const isLoginPage = location.pathname === "/";
  const isForgotPasswordPage = location.pathname === "/forgot-password";
  const isResetPasswordPage = location.pathname.startsWith("/reset-password/");

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {!isLoginPage && !isForgotPasswordPage && !isResetPasswordPage ? (
        <NavigationBar />
      ) : null}
      <Routes>
        {/* ----------- LOGIN RELATED ROUTES ----------- */}
        <Route path="/" element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/reset-password/:token" element={<ResetPasswordPage />} />

        {/* ----------- DASHBOARD RELATED ROUTES ----------- */}
        <Route path="/dashboard" element={<DashboardPage />} />

        {/* ----------- REPORTS RELATED ROUTES ----------- */}
        <Route path="/reports" element={<ReportsPage />} />

        {/* ----------- LOCATION RELATED ROUTES ----------- */}
        <Route path="/locations" element={<LocationsPage />} />
        <Route
          path="/settings/company/sites/edit-location/:locationId"
          element={<CompanyEditLocationPage />}
        />
        <Route
          path="/locations/:containerId"
          element={<ContainerMoreInfoPage />}
        />
        <Route
          path="/locations/:containerId"
          element={<ContainerMoreInfoPage />}
        />

        {/* ----------- DEVICE RELATED ROUTES ----------- */}
        <Route path="/devices" element={<DevicesPage />} />
        <Route path="/devices/:deviceId" element={<DeviceMoreInfoPage />} />

        {/* ----------- CROP RELATED ROUTES ----------- */}
        <Route path="/crops" element={<CropsPage />} />

        {/* ----------- SETTINGS RELATED ROUTES ----------- */}
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/settings/user" element={<UserSettingsPage />} />
        <Route path="/settings/company" element={<CompanySettingsPage />} />
        <Route
          path="/settings/company/edit-user/:userId"
          element={<CompanyEditUserPage />}
        />
        <Route
          path="/settings/company/edit-location"
          element={<CompanyEditLocationPage />}
        />
        <Route
          path="/settings/notifications"
          element={<NotificationSettingsPage />}
        />
        {/* ----------- ALERT RELATED ROUTES ----------- */}

        {/* ----------- NOT IMPLEMENTED ROUTE ----------- */}
        <Route path="*" element={<NotImplementedPage />} />
      </Routes>
      <ToastContainer />
    </UserContext.Provider>
  );
}

export default App;
