/**
 * CompanyEditUserPage Component
 * 
 * This component provides a form for editing user details within a company. It allows the user to
 * update various fields such as name, email, and role. The component uses React hooks for state
 * management and form handling.
 * 
 * Props:
 * - user: The user object containing details to be edited.
 * - onSave: Function to handle saving the updated user details.
 * - onCancel: Function to handle canceling the edit operation.
 * 
 * Example usage:
 * <CompanyEditUserPage 
 *   user={user} 
 *   onSave={handleSave} 
 *   onCancel={handleCancel} 
 * />
 */

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getUserById } from "../../../../api/userApi";
import {
  deleteCompanyUser,
  updateCompanyUser,
} from "../../../../api/companySettingsApi";
import { Form, Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import USER_ROLES from "../../../../constants/userRoles";
import { UserDeleteOutlined } from "../../../../helpers/icons/antDesignIcons";
import "./CompanyEditUserPage.scss";

const CompanyEditUserPage = () => {
  const location = useLocation();
  const { modalUserInfo } = location.state || {};
  const navigate = useNavigate();
  const [userData, setUserData] = useState(modalUserInfo || {});
  const [errors, setErrors] = useState({});
  const userRoles = [
    { display: "Szuperadmin", value: 0 },
    { display: "Kereskedő", value: 1 },
    { display: "Cégvezető", value: 2 },
    { display: "Telephelyvezető", value: 3 },
    { display: "Felhasználó", value: 4 },
  ];


  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const handleDeleteUserModalClose = () => setShowDeleteUserModal(false);
  const handleDeleteUserModalShow = () => setShowDeleteUserModal(true);

  useEffect(() => {
    if (!modalUserInfo) {
      // Fetch user info if not passed via state
      const fetchUserInfo = async () => {
        try {
          const response = await getUserById(userData._id);
          console.log("User data fetched: ", response.data);
          setUserData(response.data);
        } catch (error) {
          console.error("Error fetching user info:", error);
        }
      };
      fetchUserInfo();
    }
  }, [modalUserInfo, userData._id]);

  useEffect(() => {
    console.log("Modal user info: ", modalUserInfo);
  }, [modalUserInfo]);

  useEffect(() => {
    setUserData(modalUserInfo);
  }, [modalUserInfo]);

  if (!userData) {
    return <div>Loading...</div>;
  }

  const handleInputChange = (event) => {
    const { name, type, checked, value } = event.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleDeleteUser = (event, id) => {
    event.preventDefault();
    // Here you can handle the user deletion, e.g. send a request to a backend
    deleteCompanyUser(id);
    navigate("/settings/company");
  };

  const validate = () => {
    const errors = {};
    if (!userData.lastname)
      errors.lastname = "Családnév mező kitöltése kötelező";
    if (userData.lastname && userData.lastname.length < 3)
      errors.lastname = "Családnév minimum 3 karakter hosszú lehet";
    if (userData.lastname && userData.lastname.length > 50)
      errors.lastname = "Családnév maximum 50 karakter hosszú lehet";
    if (!userData.firstname)
      errors.firstname = "Keresztnév mező kitöltése kötelező";
    if (userData.firstname && userData.firstname.length < 3)
      errors.firstname = "Keresztnév minimum 3 karakter hosszú lehet";
    if (userData.firstname && userData.firstname.length > 50)
      errors.firstname = "Keresztnév maximum 50 karakter hosszú lehet";
    if (!userData.email) {
      errors.email = "Email mező kitöltése kötelező";
    } else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/.test(userData.email)
    ) {
      errors.email = "Email nem megfelelő formátumú";
    }
    if (!userData.phonenumber) {
      errors.phonenumber = "Telefonszám mező kitöltése kötelező";
    } else if (!/^\+?[0-9]{10,15}$/.test(userData.phonenumber)) {
      errors.phonenumber = "Telefonszám nem megfelelő formátumú";
    }
    if (!userData.roles) errors.roles = "Szerepkör mező kitöltése kötelező";
    return errors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("User data to be updated: ", userData);

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      Object.values(validationErrors).forEach((error) => {
        toast.error(error);
      });
      return;
    }

    try {
      // Here you can handle the form submission, e.g. send the data to a backend
      const { _id, ...updatedFields } = userData;
      updateCompanyUser(_id, updatedFields);
      toast.success("Felhasználói adatok sikeresen módosítva!");
      /*   navigate("/settings/company"); */
    } catch (error) {
      toast.error("An error occurred while updating user data.");
      console.error("Error updating user data:", error);
    }
  };

  const handleBackButton = () => {
    navigate("/settings/company");
  };

  return (
    <div className="container company-edit-user-page__container navbar-margin">
      <div className="page-title-wrapper">
        <h1 className="page-title">Felhasználói adatok módosítása</h1>
        <div className="page-title-delete__wrapper">
          <p className="page-title-delete_p">Felhasználó törlése</p>
          <button
            className="page-title-delete__button"
            onClick={handleDeleteUserModalShow}
          >
            <UserDeleteOutlined />
          </button>
        </div>
      </div>
      <Modal
        show={showDeleteUserModal}
        onHide={handleDeleteUserModalClose}
        className="company-edit-user-page__delete-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Felhasználó törlése</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Biztosan törölni szeretné a felhasználót?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleDeleteUserModalClose}
            className="btn btn-secondary"
          >
            Mégsem
          </Button>
          <Button
            variant="danger"
            onClick={(event) => handleDeleteUser(event, userData._id)}
            className="btn btn-danger"
          >
            Törlés
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="company-edit-user-page__content">
        <Form onSubmit={handleSubmit} className="company-edit-user-page-form">
          <Form.Group controlId="formLastName">
            <Form.Label>Családnév</Form.Label>
            <Form.Control
              type="text"
              name="lastname"
              value={userData.lastname || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group controlId="formFirstName">
            <Form.Label>Keresztnév</Form.Label>
            <Form.Control
              type="text"
              name="firstname"
              value={userData.firstname}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={userData.email || ""}
              onChange={handleInputChange}
              autoComplete="on"
            />
          </Form.Group>
          <Form.Group controlId="formPhone">
            <Form.Label>Telefonszám</Form.Label>
            <Form.Control
              type="tel"
              name="phonenumber"
              value={userData.phonenumber || ""}
              onChange={handleInputChange}
              autoComplete="on"
              /*               placeholder="+36301234567" */
            />
          </Form.Group>
          <Form.Group controlId="formRole">
            <Form.Label>Szerepkör</Form.Label>
            <Form.Control
              as="select"
              name="roles"
              value={userData.roles || ""}
              onChange={handleInputChange}
            >
              {userRoles.map((roles, index) => (
                <option key={index} value={roles.value}>
                  {roles.display}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formLocation">
            <Form.Label>Telephely</Form.Label>
            <Form.Control
              type="text"
              name="location"
              value={userData.location || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group controlId="formNotifications">
            <Form.Check
              type="checkbox"
              name="emailnotification"
              checked={userData.emailnotification || ""}
              onChange={handleInputChange}
              label="E-mail értesítések"
            />
          </Form.Group>
          <div className="company-edit-user-page__button__wrapper">
            <Button
              type="submit"
              form="company-edit-user-page-form"
              className="btn btn-save company-edit-user-page__save-button"
              onClick={handleSubmit}
            >
              Mentés
            </Button>
            <Button
              className="btn btn-secondary company-edit-user-back-button"
              onClick={handleBackButton}
            >
              Mégsem
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CompanyEditUserPage;
