/**
 * TimeFrameNotification Component
 * 
 * This component displays a notification with an icon and a translated message.
 * The message is fetched using the `useTranslation` hook from the `react-i18next`
 * library, allowing for internationalization support. The translation key used
 * is `timeFrameNotification`.
 * 
 * Example usage:
 * <TimeFrameNotification />
 */

import "./TimeFrameNotification.scss";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import useTranslation from "../../../hooks/useTranslation";

const TimeFrameNotification = () => {
  const { t } = useTranslation("common");

  useTranslation();

  return (
    <div className="tfn__content__info">
      <i>
        <ExclamationCircleOutlined />
      </i>
      <p>{t("timeFrameNotification")}</p>
    </div>
  );
};

export default TimeFrameNotification;
