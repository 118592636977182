/**
 * NotImplementedPage Component
 *
 * This component renders a "Not Implemented" page, typically used for routes that are under development
 * or do not exist. It includes an animation, a logo, and a link to navigate back to the dashboard.
 * The component uses Framer Motion for animations and Lottie for displaying an animation.
 *
 * Example usage:
 * <NotImplementedPage />
 */

import React from "react";
import "./NotImplementedPage.scss";
import GPLogo from "../../assets/images/logo/gp-logo-trans-w100.png";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import animationData from "../../assets/lottie/404animation.json";
import { motion } from "framer-motion";
import useTranslation from "../../hooks/useTranslation";

const NotImplementedPage = () => {
  const { t } = useTranslation("notImplementedPage", true);

  return (
    <motion.div
      className="container not-implemented-page__container"
      initial={{ opacity: 0 }} // Initial opacity for animation
      animate={{ opacity: 1 }} // Final opacity for animation
      transition={{ duration: 1 }} // Duration of the animation
    >
      <div className="not-implemented-page__content__container">
        <div className="not-implemented-page__content__animation__container">
          <Lottie
            animationData={animationData}
            className="not-implemented-page__animation"
          />
        </div>
        <div className="not-implemented-page__content__text__container">
          <img
            src={GPLogo}
            alt="GP Logo"
            className="not-implemented-page__logo"
          />

          <h1>{t("oops")}</h1>
          <p>{t("pageNotFound")}</p>
          <Link to="/" className="btn btn-primary">
          {t("backToLogin")}
          </Link>
        </div>
      </div>
    </motion.div>
  );
};

export default NotImplementedPage;
