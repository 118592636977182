/**
 * SensorsList Component
 *
 * This component renders a list of sensors with their details such as sensor ID, minimum and maximum threshold values,
 * last measured value, and the time of the last measurement. It allows users to view detailed information about a sensor
 * and edit the threshold values of a sensor. The component uses React Bootstrap for styling and components, and react-toastify
 * for displaying success or error messages.
 *
 * Props:
 * - sensorsData: Array of sensor objects to be displayed.
 *
 * State:
 * - showInfoModal: Boolean to control the visibility of the sensor information modal.
 * - selectedSensor: Object representing the currently selected sensor for viewing details.
 * - showEditModal: Boolean to control the visibility of the edit threshold modal.
 * - editingSensor: Object representing the currently selected sensor for editing.
 * - unit: String representing the unit of temperature (Celsius or Fahrenheit).
 *
 * Functions:
 * - convertToFahrenheit: Converts Celsius to Fahrenheit.
 * - handleRowClick: Handles the click event on a sensor row to toggle the information modal.
 * - handleEditModalOpen: Opens the edit threshold modal and sets the currently editing sensor.
 * - handleEditModalClose: Closes the edit threshold modal.
 * - handleEditModalSave: Saves the edited threshold values for a sensor and updates the sensor data.
 *
 * External Libraries:
 * - react-bootstrap: Used for UI components like Button, FormSelect, and Table.
 * - react-toastify: Used for displaying toast notifications.
 *
 * Context:
 * - useSensorData: Context hook to access and refresh sensor data.
 *
 * Hooks:
 * - useScreenWidth: Custom hook to get the current screen width for responsive design adjustments.
 *
 * API:
 * - updateSensorThresholdsBySensorId: Function to update the threshold values of a sensor by its ID.
 *
 * Components:
 * - SensorInfoModal: Modal component to display detailed information about a sensor.
 * - SensorEditThresholdModal: Modal component to edit the threshold values of a sensor.
 *
 * Icons:
 * - EditOutlined: Icon used for the edit button.
 *
 * Styling:
 * - SensorsList.scss: SCSS module for custom styling of the SensorsList component.
 */

import React, { useState } from "react";
import { useSensorData } from "../../../../../context/SensorContext";
import { Button, FormSelect, Table } from "react-bootstrap";
import { useScreenWidth } from "../../../../../hooks/useScreenWidth";
import SensorInfoModal from "./SensorInfoModal/SensorInfoModal";
import { EditOutlined } from "../../../../../helpers/icons/antDesignIcons";
import "./SensorsList.scss";
import SensorEditThresholdModal from "./SensorEditThresholdModal/SensorEditThresholdModal";
import { updateSensorThresholdsBySensorId } from "../../../../../api/sensorApi";
import { ToastContainer, toast } from "react-toastify";
import sensorConfig from "../../../../../config/sensorConfig";

const SensorsList = ({ sensorsData }) => {
  const { refreshSensorData } = useSensorData();
  const screenWidth = useScreenWidth();
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [selectedSensor, setSelectedSensor] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingSensor, setEditingSensor] = useState(null);
  const [unit, setUnit] = useState("Celsius");
  const sensorsTableHeader = [
    "Mélység",
    "Minimum érték",
    "Maximum érték",
    "Utolsó mért érték",
    "Utolsó mérés ideje",
    " ",
  ];

  const convertToFahrenheit = (celsius) => {
    return (celsius * 9) / 5 + 32;
  };

  const handleRowClick = (sensor) => {
    if (selectedSensor && selectedSensor._id === sensor._id) {
      setShowInfoModal(!showInfoModal);
    } else {
      setSelectedSensor(sensor);
      setShowInfoModal(true);
    }
  };

  const handleEditModalOpen = (e, sensor) => {
    e.stopPropagation();
    setEditingSensor(sensor);
    console.log("EDITING SENSOR", sensor);
    setShowEditModal(true);
  };

  const handleEditModalClose = (e) => {
    if (e) e.stopPropagation();
    setEditingSensor(null);
    setShowEditModal(false);
  };

  const handleEditModalSave = async (editingSensor) => {
    console.log("EDITING SENSOR IN COMPONENT", editingSensor);
    try {
      // Assuming `editingSensor` contains the sensor ID and the new thresholds
      const sensorId = editingSensor._id;
      const thresholds = {
        minThreshold: parseFloat(editingSensor.min_value),
        maxThreshold: parseFloat(editingSensor.max_value),
      };

      const userId = localStorage.getItem("userId");

      if (
        thresholds.minThreshold === null ||
        thresholds.maxThreshold === null ||
        isNaN(thresholds.minThreshold) ||
        isNaN(thresholds.maxThreshold)
      ) {
        throw new Error("emptyFields");
      }

      if (thresholds.minThreshold >= thresholds.maxThreshold) {
        toast.error(
          "A minimum küszöbérték nem lehet nagyobb a maximum küszöbértéknél"
        );
        return;
      }

      if (
        thresholds.minThreshold < sensorConfig.temperature.minThresholdConfig ||
        thresholds.minThreshold > sensorConfig.temperature.maxThresholdConfig ||
        thresholds.maxThreshold < sensorConfig.temperature.minThresholdConfig ||
        thresholds.maxThreshold > sensorConfig.temperature.maxThresholdConfig
      ) {
        throw new Error("outOfRange");
      }

      // Call the API function
      const updatedSensorData = await updateSensorThresholdsBySensorId(
        sensorId,
        thresholds,
        userId
      );

      console.log("Updated sensor data:", updatedSensorData);

      // Close the edit modal and refresh the sensor list here from Context
      setEditingSensor(null);
      setShowEditModal(false);
      refreshSensorData();

      toast.success("Szenzor küszöbértékei sikeresen frissítve");
    } catch (error) {
      if (error.message === "emptyFields") {
        toast.error("A küszöbértékek nem lehetnek üresek");
      } else if (error.message === "minGreaterThanMax") {
        toast.error(
          "A minimum küszöbérték nem lehet nagyobb a maximum küszöbértéknél"
        );
      } else if (error.message === "outOfRange") {
        toast.error(
          `A küszöbértékeknek ${sensorConfig.temperature.minThresholdConfig} és ${sensorConfig.temperature.maxThresholdConfig} között kell lenniük`
        );
      } else if (
        error.message === "Request failed with status code 403"
      ) {
        toast.error("Nincs jogosultságod a művelet végrehajtásához");
      } else {
        toast.error(
          "Hiba történt a szenzor küszöbértékeinek frissítése közben"
        );
      }
    }
  };

  return (
    <div className="sensors-list">
      <ToastContainer />
      <h2 className="sensors-list__title">Az eszközben található szenzorok</h2>
      <div className="sensors-list__content">
        <div className="sensors-list__header"> </div>
        <Table
          headers={sensorsTableHeader}
          data={sensorsData}
          className="sensors-list__table"
        >
          <thead>
            <tr className="table-header-tr">
              {Object.values(sensorsTableHeader).map((header, index) => (
                <th
                  key={header}
                  id={`sensors-list-column-${index + 1}-header`}
                  className="table-header__item sensors-list-header-item"
                >
                  {header}
                  {header === "Utolsó mért érték" && (
                    <FormSelect
                      value={unit}
                      onChange={(e) => setUnit(e.target.value)}
                      style={{ width: "75px" }}
                    >
                      <option value="Celsius">°C</option>
                      <option value="Fahrenheit">°F</option>
                    </FormSelect>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sensorsData.map((sensor) => (
              <tr
                key={sensor._id}
                className={screenWidth < 768 ? "table-row" : "table-row-tr"}
              >
                <td
                  className="sensors-list-table-data table-data"
                  id="sensors-list-column-1-data"
                  onClick={() => handleRowClick(sensor)}
                >
                  {sensor.sens_num}
                </td>
                {sensor.min_value ? (
                  <td
                    className="table-data sensors-list-table-data"
                    id="sensors-list-column-2-data"
                  >
                    {unit === "Celsius"
                      ? sensor.min_value
                      : convertToFahrenheit(sensor.min_value).toFixed(2)}
                  </td>
                ) : (
                  <td
                    className="table-data sensors-list-table-data"
                    id="sensors-list-column-2-data"
                  >
                    Nincs megadva
                  </td>
                )}
                {sensor.max_value ? (
                  <td
                    className="table-data sensors-list-table-data"
                    id="sensors-list-column-3-data"
                  >
                    {unit === "Celsius"
                      ? sensor.max_value
                      : convertToFahrenheit(sensor.max_value).toFixed(2)}
                  </td>
                ) : (
                  <td
                    className="table-data sensors-list-table-data"
                    id="sensors-list-column-3-data"
                  >
                    Nincs megadva
                  </td>
                )}
                <td
                  className="table-data sensors-list-table-data"
                  id="sensors-list-column-4-data"
                >
                  {unit === "Celsius"
                    ? sensor.sens_value.toFixed(2)
                    : convertToFahrenheit(sensor.sens_value).toFixed(2)}
                </td>
                <td
                  className="table-data sensors-list-table-data"
                  id="sensors-list-column-5-data"
                >
                  {new Date(sensor.sens_timestamp).toLocaleString()}
                </td>
                <td
                  className="table-data sensors-list-table-data"
                  id="sensors-list-column-6-data"
                >
                  <Button
                    variant="outline-primary"
                    className="table-row__button"
                    onClick={(e) => handleEditModalOpen(e, sensor)}
                  >
                    <EditOutlined />
                  </Button>
                  <SensorEditThresholdModal
                    show={showEditModal}
                    handleClose={(e) => handleEditModalClose(e)}
                    handleSave={handleEditModalSave}
                    editingSensorData={editingSensor}
                    setEditingSensorData={setEditingSensor}
                  />
                </td>
              </tr>
            ))}
          </tbody>
          <SensorInfoModal
            show={showInfoModal}
            handleClose={() => setShowInfoModal(false)}
            sensorData={selectedSensor}
          />
        </Table>
      </div>
    </div>
  );
};

export default SensorsList;
