import React, { useCallback, useContext, useState } from "react";
import UserContext from "../../context/UserContext";
import axios from "axios";
import { useScreenWidth } from "../../hooks/useScreenWidth";
import { Form, InputGroup, Button } from "react-bootstrap";
import "./LoginPage.scss";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import GPLogo from "../../assets/images/logo/gp-logo-trans-w100.webp";
import LoginPageDesktop from "./LoginPageDesktop";
import LoginButton from "./reusable/LoginButton";
import LoginForgotPassword from "./reusable/LoginForgotPassword";
import MandatoryText from "./reusable/MandatoryText";
import { ToastContainer, toast } from "react-toastify";
import useTranslation from "../../hooks/useTranslation";

function PasswordField({ value, onChange }) {
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation("loginRelatedPages", true);

  return (
    <InputGroup>
      <Form.Control
        type={showPassword ? "text" : "password"}
        className="form-control"
        id="password"
        placeholder={t("minPassLengthPlaceholder")}
        value={value}
        onChange={onChange}
      />
      <Button
        onClick={() => setShowPassword(!showPassword)}
        variant="outline-secondary"
      >
        {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
      </Button>
    </InputGroup>
  );
}

function LoginPage() {
  const { setUser } = useContext(UserContext);
  const [isChecked, setIsChecked] = useState(false);
  const screenWidth = useScreenWidth();
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL_FOR_FRONTEND;
  const [formData, setFormData] = useState({ email: "", password: "" });
  const { t } = useTranslation("loginRelatedPages", true);

  const handleEmailChange = useCallback((e) => {
    setFormData((prevFormData) => ({ ...prevFormData, email: e.target.value }));
  }, []);

  const handlePasswordChange = useCallback((e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      password: e.target.value,
    }));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isChecked) {
      toast.error(t("privacyPolicyCheckBoxError"));
      return;
    }

    if (formData.email === "" || formData.password === "") {
      toast.error(t("fillAllFields"));
      return;
    }

    const useremail = formData.email;
    const userpassword = formData.password;

    try {
      const response = await axios.post(`${backendUrl}/login`, {
        useremail,
        userpassword,
      });

      if (response.status === 200) {
        const {
          token,
          userId,
          roles,
          lastname,
          firstname,
          company_id,
          companyname,
          language,
          timezone_offset,
        } = response.data;

        setUser({
          token,
          userId,
          roles,
          lastname,
          firstname,
          company_id,
          companyname,
          language,
          timezone_offset,
        });

        localStorage.setItem("token", token);
        localStorage.setItem("userId", userId);
        localStorage.setItem("roles", roles);
        localStorage.setItem("lastname", lastname);
        localStorage.setItem("firstname", firstname);
        localStorage.setItem("company_id", company_id);
        localStorage.setItem("companyname", companyname);
        localStorage.setItem("language", language);
        localStorage.setItem("timezone_offset", timezone_offset);

        if (response.status === 200) {
          toast.success(t("loginSuccess"), {
            autoClose: 3000,
          });
          setTimeout(() => {
            navigate("/dashboard");
            // Wait for setUser and localStorage.setItem to complete before navigating
          }, 1500); // Wait for 1.5 seconds before navigating
        }
      } else if (response.status === 401) {
        console.error("Unauthorized login");
        toast.error(t("loginError"));
        navigate("/");
      } else if (response.status === 403) {
        console.log("403-as hiba");
        toast.error(t("loginFailedCloseAccount"));
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          toast.error(t("loginError"));
        }
        if (error.response.status === 403) {
          console.log("403-as hiba");
          const { message } = error.response.data;
          console.log("403 message: ", message);
          toast.error(t("loginFailedCloseAccount"));
        }

        if (error.response.status === 500) {
          console.log("500-as hiba");
          toast.error(t("loginFailedServer"));
        }
      } else {
        if (error.message === "Network Error") {
          toast.error(t("loginFailedNetwork"));
        }
        console.log("Login error: ", error);
        toast.error(t("loginFailedMessage"));
      }
    }
  };

  return (
    <>
      {screenWidth < 1024 ? (
        <div className="container login-page__container">
          <ToastContainer />
          <div className="login-page__form">
            <img src={GPLogo} alt="logo" />
            <p className="login-page__form-subtitle">
              Kérjük, adja meg a bejelentkezési adatait.
            </p>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="form-group">
                <Form.Label htmlFor="email">E-mail</Form.Label>
                <Form.Control
                  type="email"
                  className="form-control"
                  id="email"
                  aria-describedby="emailHelp"
                  placeholder="nev@minta.com"
                  value={formData.email}
                  onChange={handleEmailChange}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label htmlFor="password">{t("password")}</Form.Label>
                <PasswordField
                  value={formData.password}
                  onChange={handlePasswordChange}
                />
              </Form.Group>
              <MandatoryText
                isChecked={isChecked}
                setIsChecked={setIsChecked}
              />
              <LoginButton handleSubmit={handleSubmit} />
            </Form>
            <LoginForgotPassword />
          </div>
        </div>
      ) : (
        <LoginPageDesktop
          handleSubmit={handleSubmit}
          PasswordField={PasswordField}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
          handleEmailChange={handleEmailChange}
          handlePasswordChange={handlePasswordChange}
        />
      )}
    </>
  );
}

export default LoginPage;
