// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hamburger-icon .line {
  height: 3px;
  background-color: white;
  margin: 6px 0;
  transition: width 0.4s;
  border-radius: 1.5px;
}

.hamburger-icon .line1 {
  width: 35px;
}

.hamburger-icon .line2 {
  width: 25px;
}`, "",{"version":3,"sources":["webpack://./src/components/NavigationBar/BarIcon.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,uBAAA;EACA,aAAA;EACA,sBAAA;EACA,oBAAA;AACJ;;AAEE;EACE,WAAA;AACJ;;AAEE;EACE,WAAA;AACJ","sourcesContent":[".hamburger-icon .line {\n    height: 3px;\n    background-color: white;\n    margin: 6px 0;\n    transition: width 0.4s;\n    border-radius: 1.5px;\n}\n  \n  .hamburger-icon .line1 {\n    width: 35px;\n  }\n  \n  .hamburger-icon .line2 {\n    width: 25px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
