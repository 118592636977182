import React from "react";
import "./RedButton.scss";

const RedButton = ({icon, content, onClick}) => {
    return ( 
        <button className="btn btn-danger red-button"  onClick={onClick}>
            {icon}
            {content}
        </button>
     );
}
 
export default RedButton;