/**
 * This module defines a function to fetch translation data from a backend service.
 * It uses Axios for HTTP requests and relies on environment variables and localStorage
 * for configuration and authentication.
 *
 * The `getTranslations` function requests translations for a specified language
 * and a predefined set of keys. It constructs a query string with these keys and
 * includes an authorization token in the request headers.
 *
 * Usage:
 * import { getTranslations } from './translationApi';
 *
 * async function loadTranslations(language) {
 *   try {
 *     const translations = await getTranslations(language);
 *     console.log(translations);
 *   } catch (error) {
 *     console.error("Failed to load translations:", error);
 *   }
 * }
 */

import axios from "axios";
import { getAuthHeaders } from "./auth";

// Base URL for the backend service, retrieved from environment variables
const backendUrl = process.env.REACT_APP_BACKEND_URL_FOR_FRONTEND;

/**
 * Fetches translations for the specified language and module.
 *
 * @param {string} language - The language code for which to fetch translations.
 * @param {string} module - The module name for which to fetch translations.
 * @returns {Promise<Object>} A promise that resolves to an object containing the requested translations.
 * @throws {Error} Throws an error if the request fails.
 */
export const getTranslations = async (language, module) => {
  console.log(
    "getTranslations - Current language:",
    language,
    "module:",
    module
  ); // Debugging log
  try {
    // Fetch common translations
    const commonTranslations = await axios.get(
      `${backendUrl}/translations?language=${language}&module=common`,
      {
        headers: getAuthHeaders(),
      }
    );

    // Fetch module-specific translations
    const moduleTranslations = await axios.get(
      `${backendUrl}/translations?language=${language}&module=${module}`,
      {
        headers: getAuthHeaders(),
      }
    );

    // Merge common and module-specific translations
    return {
      ...commonTranslations.data,
      ...moduleTranslations.data,
    };
  } catch (error) {
    // Throw a new error with the message from the original error
    throw new Error(error.message);
  }
};
