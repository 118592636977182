import React, { useEffect, useState } from "react";
import { Button, Form, FormSelect, Modal } from "react-bootstrap";
import { PlusCircleOutlined } from "../../../helpers/icons/antDesignIcons";

const AddContainer = ({ handleAddNewContainerClick, siteList }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [siteId, setSiteId] = useState("");
  const [containerName, setContainerName] = useState("");
  const [containerType, setContainerType] = useState("");

  useEffect(() => {
    console.log("siteId", siteId);
    console.log("containerName", containerName);
    console.log("containerType", containerType);
  }, [siteId, containerName, containerType]);

  return (
    <div className="add-site__wrapper">
      <Button
        variant="contained"
        color="primary"
        className="locations-page__header__add__btn"
        onClick={() => setModalIsOpen(true)}
      >
        <PlusCircleOutlined />
        Új tároló hozzáadása
      </Button>
      <Modal centered show={modalIsOpen} onHide={() => setModalIsOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title> Új tároló hozzáadása</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="add-site__form">
            <FormSelect
              className="mb-3"
              onChange={(e) => setSiteId(e.target.value)}
              required
            >
              <option value="">Telephely kiválasztása</option>
              {siteList.map((site) => (
                <option key={site._id} value={site._id}>
                  {site.sitename}
                </option>
              ))}
            </FormSelect>
            <Form.Group className="mb-3">
              <Form.Label>Tároló neve</Form.Label>
              <Form.Control
                type="text"
                placeholder="Minta: Magtár 1"
                required
                onChange={(e) => setContainerName(e.target.value)}
              />
            </Form.Group>
            <FormSelect
              className="mb-3"
              onChange={(e) => setContainerType(e.target.value)}
            >
              <option value="">Típus kiválasztása</option>
              <option value="Magtár">Magtár</option>
              <option value="Siló">Siló</option>
            </FormSelect>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalIsOpen(false)}>
            Mégse
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={() =>
              handleAddNewContainerClick({
                siteId,
                containerName,
                containerType,
              })
            }
          >
            Hozzáadás
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddContainer;
