import axios from "axios";
const backendUrl = process.env.REACT_APP_BACKEND_URL_FOR_FRONTEND;
const token = localStorage.getItem("token");

export const getAllBasesByCompanyId = async (companyId) => {
  try {
    const bases = await axios.get(
      `${backendUrl}/bases/companybases/${companyId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return bases.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getBasesWithNoContainerAndProbeIdByCompanyId = async (
  companyId
) => {
  try {
    const bases = await axios.get(
      `${backendUrl}/bases/baseswithnocontainerandprobeid/${companyId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("bases FREE", bases);
    return bases.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getAllBasesInContainersByCompanyId = async (companyId) => {
  try {
    const bases = await axios.get(
      `${backendUrl}/bases/basesincontainers/${companyId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("bases", bases.data);
    return bases.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

// UPDATE

export const updateBaseContainerIdByBaseId = async (baseId, containerId) => {
  try {
    const updatedBaseContainerId = await axios.patch(
      `${backendUrl}/bases/updatebasecontainerid/${baseId}`,
      { containerid: containerId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return updatedBaseContainerId.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const removeBaseFromContainerByBaseIdAndCompanyId = async (
  baseId,
  companyId
) => {
  console.log("selectedBase IN API", baseId);
  console.log("companyId IN API", companyId);

  console.log("token", token);
  try {
    const removedBaseFromContainer = await axios.patch(
      `${backendUrl}/bases/removebasefromcontainer/${baseId}/${companyId}`, {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      
    );
    return removedBaseFromContainer.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const connectBaseToContainer = async (baseId, containerId, companyId) => {
  try {
    const connectedBaseToContainer = await axios.patch(
      `${backendUrl}/bases/connectbasetocontainer/${baseId}/${containerId}/${companyId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return connectedBaseToContainer.data;
  } catch (error) {
    throw new Error(error.message);
  }
}
