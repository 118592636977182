// This component renders the navigation bar of the application. It uses the react-bootstrap library for styling and layout.
// The navigation bar includes a toggleable off-canvas sidebar for smaller screens (xs breakpoint), and a dropdown menu for user-related actions.
import React, { useState, useEffect } from "react";
import { Navbar, Nav, NavDropdown, Container, Button } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./NavigationBar.scss";
import GPLogo from "../../assets/images/logo/gp-logo-trans-w100.webp";
import BarIcon from "./BarIcon";
import {
  CloseCircleOutlined,
  UserOutlined,
} from "../../helpers/icons/antDesignIcons";
import useLogout from "../../hooks/useLogout";
import useTranslation from "../../hooks/useTranslation";

const VERSION = "0.1.14";
const PROVIDER = "by Unicomp Kft.";

const NavigationBar = () => {
  // State to control the visibility of the off-canvas sidebar
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [username, setUsername] = useState("");
  const handleLogout = useLogout();
  const { t } = useTranslation("common");

  useEffect(() => {
    const firstname = localStorage.getItem("firstname");
    const lastname = localStorage.getItem("lastname");
    if (firstname && lastname) {
      setUsername(`${firstname}`);
    }
  }, []);

  return (
    <>
      <Navbar expand="xs" className="bg-body-tertiary mb-3 navigationbar-base">
        <Container fluid>
          {/* Toggle button for the off-canvas sidebar */}
          <Navbar.Toggle
            aria-controls="offcanvasNavbar"
            className="navigationbar-toggle"
            onClick={() => setShowOffcanvas(true)}
          >
            <BarIcon />
          </Navbar.Toggle>
          {/* Off-canvas sidebar */}
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="start"
            show={showOffcanvas}
          >
            <Offcanvas.Header>
              <Offcanvas.Title id="offcanvasNavbarLabel">
                GrainPatrol - Admin Panel
              </Offcanvas.Title>
              <Button
                variant="none"
                className="ms-auto"
                onClick={() => setShowOffcanvas(false)}
              >
                <CloseCircleOutlined
                  style={{ fontSize: "1.5rem", color: "white", padding: 0 }}
                />
              </Button>
            </Offcanvas.Header>
            <Offcanvas.Body className="navbar-offcanvas-body">
              <div className="offcanvas-body-top">
                <Nav className="justify-content-end flex-grow-1 pe-3 navigationbar-offcanvas-base">
                  <Nav.Link href="/dashboard">{t("overview")}</Nav.Link>
                  <Nav.Link href="/reports">{t("reports")}</Nav.Link>
                  <Nav.Link href="/locations">{t("sites")}</Nav.Link>
                  <Nav.Link href="/devices">{t("devices")}</Nav.Link>
                  <Nav.Link href="/crops">{t("crops")}</Nav.Link>
                  <NavDropdown
                    title={t("settings")}
                    id="offcanvasNavbarDropdown"
                  >
                    <NavDropdown.Item href="/settings/user">
                      {t("settingsUser")}
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/settings/company">
                      {t("settingsCompany")}
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/settings">
                      {t("settings")}
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </div>
              <div className="offcanvas-footer">
                <small>Verzió: {VERSION} </small>
                <small>{PROVIDER}</small>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
          {/* Logo of the application */}
          <Navbar.Brand href="/dashboard">
            <img src={GPLogo} alt="GrainPatrol logo" width="150px" />
          </Navbar.Brand>
          {/* User-related actions dropdown menu */}
          <Nav>
            <div className="dropdown-container">
              <NavDropdown title={<UserOutlined />} id="nav-dropdown">
                <NavDropdown.Item>
                  Bejelentkezve, mint: {username || "Keresztnév"}
                </NavDropdown.Item>
                <NavDropdown.Item href="/settings/notifications">
                  Értesítések kezelése
                </NavDropdown.Item>
                <NavDropdown.Item href="/settings">
                  Beállítások
                </NavDropdown.Item>
                <NavDropdown.Item href="/help">Súgó</NavDropdown.Item>
                <NavDropdown.Item
                  onClick={handleLogout}
                  href="/"
                  className="nav-dropdown-last-item"
                >
                  Kijelentkezés
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default NavigationBar;
