import axios from "axios";
import { getAuthHeaders } from "./auth";
const backendUrl = process.env.REACT_APP_BACKEND_URL_FOR_FRONTEND;
const token = localStorage.getItem("token");

export const getUserSettingsById = async (id) => {
  try {
    const userSettings = await axios.get(`${backendUrl}/settings/user/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return userSettings.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getLanguageOptions = async () => {
  try {
    const response = await axios.get(`${backendUrl}/settings/user/languages`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

// ---- NOTIFICATIONS SETTINGS ----

export const getEmailNotificationSettingsById = async () => {
  try {
    const response = await axios.get(
      `${backendUrl}/settings/user/emailnotification`,
      {
        headers: getAuthHeaders(),
      }
    );
    console.log("Email notification settings: ", response.data);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

// UPDATE USER SETTINGS

export const updateUserNameInSettingsById = async (id, firstname, lastname) => {
  console.log("Firstname: ", firstname);
  console.log("Lastname: ", lastname);
  try {
    await axios.patch(
      `${backendUrl}/settings/user/name/${id}`,
      { firstname, lastname },
      {
       headers: getAuthHeaders(),
      }
    );
  } catch (error) {
    throw new Error(error.message);
  }
};

export const updateUserEmailInSettingsById = async (id, email) => {
  try {
    await axios.patch(
      `${backendUrl}/settings/user/email/${id}`,
      { email },
      {
       headers: getAuthHeaders(),
      }
    );
  } catch (error) {
    console.log("Error: ", error);
    throw new Error(error);
  }
};

export const updateUserPasswordInSettingsById = async (id, password) => {
  try {
    await axios.patch(
      `${backendUrl}/settings/user/password/${id}`,
      { password },
      {
       headers: getAuthHeaders(),
      }
    );
  } catch (error) {
    throw new Error(error);
  }
};

export const updateUserLanguageInSettingsById = async (id, language) => {
  try {
    await axios.patch(
      `${backendUrl}/settings/user/language/${id}`,
      { language },
      {
       headers: getAuthHeaders(),
      }
    );
  } catch (error) {
    throw new Error(error);
  }
};

export const updateUserTimezoneInSettingsById = async (id, timezone) => {
  try {
    await axios.patch(
      `${backendUrl}/settings/user/timezone/${id}`,
      { timezone },
      {
       headers: getAuthHeaders(),
      }
    );
  } catch (error) {
    throw new Error(error);
  }
};


// ---- NOTIFICATIONS SETTINGS ----

export const updateUserEmailNotificationSettingsById = async (emailNotification) => {
  try {
    await axios.patch(
      `${backendUrl}/settings/user/emailnotification`,
      { emailNotification },
      {
       headers: getAuthHeaders(),
      }
    );
  } catch (error) {
    throw new Error(error);
  }
};