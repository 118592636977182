/**
 * ConnectDeviceToContainerButton.jsx
 *
 * This component allows users to connect devices to containers. It provides a drag-and-drop interface
 * for moving devices between containers. The component also provides a modal for selecting the site and
 * container to which the devices will be connected. The state of the devices and containers is managed
 * using React's useState and useEffect hooks. The drag-and-drop functionality is provided by the @dnd-kit/core
 * and @dnd-kit/sortable libraries.
 *
 * Props:
 * - deviceList: Array of all devices.
 * - containerList: Array of all containers.
 * - siteList: Array of all sites.
 *
 * Example usage:
 * <ConnectDeviceToContainerButton 
 *   deviceList={deviceList} 
 *   containerList={containerList} 
 *   siteList={siteList} 
 * />
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Array} props.deviceList - The list of all devices.
 * @param {Array} props.containerList - The list of all containers.
 * @param {Array} props.siteList - The list of all sites.
 *
 * @returns {JSX.Element} The ConnectDeviceToContainerButton component.
 */

import React, { useEffect, useState } from "react";
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import DroppableContainer from "./DroppableContainer";
import { getAllDeviceDataByContainerId } from "../../../api/locationApi";
import { updateDeviceContainer } from "../../../api/deviceApi";
import { FormSelect, Modal, Row, Col } from "react-bootstrap";
import { BlockOutlined } from "../../../helpers/icons/antDesignIcons";
import SortableDevice from "./SortableDevice";
import "./ConnectDeviceToContainerButton.scss";
import { Form, InputGroup } from "react-bootstrap";

const ConnectDeviceToContainerButton = ({
  deviceList,
  containerList,
  siteList,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [devices, setDevices] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [containers, setContainers] = useState([]);
  const [selectedContainer, setSelectedContainer] = useState(null);
  const [filteredContainers, setFilteredContainers] = useState([]);
  const [selectedSite, setSelectedSite] = useState("all");
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  // State for the search term and filtered devices with no container
  const [searchTerm, setSearchTerm] = useState("");

  const filteredFreeDevices = devices.filter((device) => {
    // Ensure searchTerm is always treated as a string and in lower case
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    // Convert probeid to string and check if it includes the search term
    const matchesSearchTerm = device.probeid
      .toString()
      .includes(lowerCaseSearchTerm);

    // If no container is selected, list all devices with no container
    if (!selectedContainer) {
      return true;
    }
    // Check for device type based on the container type
    if (selectedContainer?.containertype === "Magtár") {
      // List all "Fix" devices if searchTerm is empty or if it matches the searchTerm
      return (
        (!searchTerm || matchesSearchTerm) &&
        device.probe_type?.trim() === "Fix"
      );
    } else if (selectedContainer?.containertype === "Siló") {
      // List all "Flexibilis" devices if searchTerm is empty or if it matches the searchTerm
      return (
        (!searchTerm || matchesSearchTerm) &&
        device.probe_type?.trim() === "Flexibilis"
      );
    }
    // If the container type doesn't match any of the above conditions, exclude the device
    return false;
  });

  useEffect(() => {
    setDevices(Array.isArray(deviceList) ? deviceList : []);
    setContainers(Array.isArray(containerList) ? containerList : []);
  }, [deviceList, containerList]);

  useEffect(() => {
    if (selectedContainer) {
      const fetchDevices = async () => {
        try {
          const selectedContainerDevices = await getAllDeviceDataByContainerId(
            selectedContainer._id
          );

          setSelectedDevices(selectedContainerDevices);
        } catch (error) {
          console.error(
            "Error fetching devices for selected container:",
            error
          );
        }
      };

      fetchDevices();
    } else {
      setSelectedDevices([]);
    }
  }, [selectedContainer]);

  useEffect(() => {
    if (selectedSite) {
      const filtered = containerList.filter((container) => {
        return container.sitename === selectedSite.sitename;
      });
      console.log("Filtered containers:", filtered);
      setFilteredContainers(filtered);
    } else {
      setFilteredContainers(containerList);
    }
  }, [selectedSite, containerList]);

  const handleSiteChange = (e) => {
    const selectedSiteId = Number(e.target.value);
    const foundSite = siteList.find((site) => site._id === selectedSiteId);
    setSelectedSite(foundSite);
  };

  const handleContainerChange = (e) => {
    const selectedContainerId = e.target.value;
    const selected = filteredContainers.find(
      (container) => container._id.toString() === selectedContainerId.toString()
    );
    setSelectedContainer(selected || null);
  };

  /**
   * handleDragEnd is an asynchronous function that handles the end of a drag event.
   * It determines the source list (either 'devices' or 'selectedDevices') and the destination list
   * based on the 'active' and 'over' properties of the event. If a device is moved from one list to another,
   * it updates the state of the devices and selectedDevices arrays accordingly. If the device is moved to 'selectedDevices',
   * it also updates the device's container in the database by calling the updateDeviceContainer API.
   *
   * @param {Object} event - The drag event object.
   * @param {Object} event.active - The active draggable object.
   * @param {string} event.active.id - The id of the active draggable object.
   * @param {Object} event.over - The droppable area the draggable object was dropped over.
   * @param {string} event.over.id - The id of the droppable area.
   */
  const handleDragEnd = async (event) => {
    const { active, over } = event;
    console.log("Drag end:", { active, over });

    const sourceList = devices.some((device) => device.probeid === active.id)
      ? "devices"
      : "selectedDevices";
    let destinationList;

    if (over) {
      destinationList =
        over.id === "selectedDevices" ? "selectedDevices" : "devices";
    } else {
      destinationList = "devices";
    }

    if (active.id !== (over ? over.id : null)) {
      const sourceList = devices.some((device) => device.probeid === active.id)
        ? "devices"
        : "selectedDevices";
      const destinationList =
        over.id === "selectedDevices" ? "selectedDevices" : "devices";

      if (sourceList !== destinationList) {
        const activeDevice =
          sourceList === "devices"
            ? devices.find((device) => device.probeid === active.id)
            : selectedDevices.find((device) => device.probeid === active.id);

        if (!activeDevice) {
          return;
        }

        if (destinationList === "selectedDevices") {
          setDevices((prevDevices) =>
            prevDevices.filter((device) => device.probeid !== active.id)
          );
          setSelectedDevices((prevSelectedDevices) => [
            ...prevSelectedDevices,
            activeDevice,
          ]);

          try {
            await updateDeviceContainer(active.id, selectedContainer._id);
          } catch (error) {
            console.error("Failed to update device container", error);
          }
        } else {
          setSelectedDevices((prevSelectedDevices) =>
            prevSelectedDevices.filter((device) => device.probeid !== active.id)
          );
          setDevices((prevDevices) => [...prevDevices, activeDevice]);

          try {
            await updateDeviceContainer(active.id, null);
          } catch (error) {
            console.error("Failed to update device container", error);
          }
        }
      }
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  return (
    <div className="connect-device-container__btn__wrapper">
      <button
        className="btn btn-primary connect-device-container__btn"
        onClick={() => setModalIsOpen(true)}
      >
        <BlockOutlined />
        Szondakezelő
      </button>
      <Modal show={modalIsOpen} size="xl" onHide={() => setModalIsOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Eszköz raktárhoz rendelése</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="connect-device-modal-filter">
            <h5>Telephely:</h5>
            <FormSelect
              id="site-select"
              value={selectedSite ? selectedSite._id : 0}
              onChange={handleSiteChange}
            >
              <option value={0}>Összes</option>
              {siteList.map((site) => (
                <option key={site._id} value={site._id}>
                  {site.sitename}
                </option>
              ))}
            </FormSelect>
          </div>
          <div>
            <h5>Tároló:</h5>
            <FormSelect
              value={selectedContainer?._id || ""}
              onChange={handleContainerChange}
            >
              <option value="">-</option>
              {filteredContainers.map((container, index) => (
                <option key={index} value={container._id}>
                  {container.containername} - {container.containertype}
                </option>
              ))}
            </FormSelect>
          </div>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            {/*            {selectedContainer && ( */}
            <div className="connect-device-modal-search">
              <p>Keresés a nem hozzárendelt szondák között</p>
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder=" Keresés ..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className="connect-device-modal-search-input"
                />
              </InputGroup>
            </div>
            {/*    )} */}
            <Row>
              <Col className="connect-device-modal-col">
                <h5>Nem hozzárendelt eszközök</h5>
                <DroppableContainer id="devices">
                  <SortableContext
                    items={
                      filteredFreeDevices.length > 0
                        ? filteredFreeDevices.map((device) => device.probeid)
                        : ["placeholder"]
                    }
                    strategy={verticalListSortingStrategy}
                  >
                    <div className="connect-device-modal-col-list">
                      {filteredFreeDevices.length > 0 ? (
                        filteredFreeDevices.map((device, index) => (
                          <SortableDevice
                            key={device.probeid}
                            id={device.probeid}
                            device={device}
                            index={index}
                            disabled={!selectedContainer}
                          />
                        ))
                      ) : (
                        <div id="placeholder">
                          Előfordulhat, hogy nem választott még ki konténert,
                          Minden eszköz tárolóhoz van rendelve vagy nem
                          található a keresésnek megfelelő eszköz.
                        </div>
                      )}
                    </div>
                  </SortableContext>
                </DroppableContainer>
              </Col>
              <Col className="connect-device-modal-col">
                <h5>
                  {selectedContainer
                    ? "Szondák a válaszott tárolóban"
                    : "Válasszon tárolót a listából"}
                </h5>
                <DroppableContainer id="selectedDevices" className="lsistas">
                  <SortableContext
                    items={
                      selectedDevices
                        ? selectedDevices
                            .filter((device) => device && device.probeid) // Ensure device is not null and has a probeid property
                            .map((device) => device.probeid)
                        : []
                    }
                    strategy={verticalListSortingStrategy}
                  >
                    {selectedDevices.length > 0 ? (
                      <div className="connect-device-modal-col-list">
                        {selectedDevices &&
                          selectedDevices
                            .filter((device) => device && device.probeid) // Ensure device is not null and has a probeid property
                            .map((device, index) => (
                              <SortableDevice
                                key={device.probeid}
                                id={device.probeid}
                                device={device}
                                index={index}
                              />
                            ))}
                      </div>
                    ) : (
                      <div id="placeholder">
                        Az adott magtárban még nem található szonda
                      </div>
                    )}
                  </SortableContext>
                </DroppableContainer>
              </Col>
            </Row>
          </DndContext>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ConnectDeviceToContainerButton;
