import React, { useState } from "react";
import { useUserRole } from "../../../context/userRoleContext"
import { useNavigate } from "react-router-dom";
import { FormSelect, ListGroup, Modal } from "react-bootstrap";
import "./DashboardListMoreInfoModal.scss";

const DasboardListMoreInfoModal = ({
  show,
  handleClose,
  dashboardListItemData,
}) => {
  const navigate = useNavigate();
  const [unit, setUnit] = useState("Celsius");
  const userRole = useUserRole();

  const convertToFahrenheit = (celsius) => {
    return (celsius * 9) / 5 + 32;
  };

  if (!dashboardListItemData) {
    return null;
  }

  const goToLocationMoreInfo = (container) => {
    navigate(`/locations/${container.containers_id}`, {
      state: { container },
    });
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton className="dashboard-moreinfo-modal-header">
        <Modal.Title className="dashboard-moreinfo-modal-header-title">
          Riasztást küldő eszköz: {dashboardListItemData.probeid}{" "}
          <FormSelect
            aria-label="Unit select"
            value={unit}
            onChange={(e) => setUnit(e.target.value)}
            className="dashboard-moreinfo-modal-header-title-select"
          >
            <option value="Celsius">°C</option>
            <option value="Fahrenheit">°F</option>
          </FormSelect>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="dashboard-alert-moreinfo-modal-body">
        <ListGroup>
          <ListGroup.Item>
            Szenzor azonosítója: {dashboardListItemData.probesensor_id}
          </ListGroup.Item>
          <ListGroup.Item
            onClick={() => goToLocationMoreInfo(dashboardListItemData)}
            className="dashboard-alert-moreinfo-modal-location"
          >
            Tároló neve: {dashboardListItemData.containername}
          </ListGroup.Item>
          {( userRole === "0" || userRole === "1") &&  (
          <ListGroup.Item>
            Telephely neve: {dashboardListItemData.sitename}
          </ListGroup.Item>
          )}
          <ListGroup.Item>
            Minimum érték:{" "}
            {unit === "Celsius"
              ? dashboardListItemData.min_value
              : convertToFahrenheit(dashboardListItemData.min_value)}
          </ListGroup.Item>
          <ListGroup.Item>
            Maximum érték:{" "}
            {unit === "Celsius"
              ? dashboardListItemData.max_value
              : convertToFahrenheit(dashboardListItemData.max_value)}
          </ListGroup.Item>
          <ListGroup.Item>
            Utolsó mért érték:{" "}
            {unit === "Celsius"
              ? dashboardListItemData.sens_value
              : convertToFahrenheit(dashboardListItemData.sens_value)}
          </ListGroup.Item>
          <ListGroup.Item>
            Mérés ideje:{" "}
            {new Date(dashboardListItemData.sens_timestamp).toLocaleString()}
          </ListGroup.Item>
          {( userRole === "0" || userRole === "1") &&  (
            <ListGroup.Item>
              Felhasználó: {dashboardListItemData.user}
            </ListGroup.Item>
          )}
          {userRole === "0" && (
            <ListGroup.Item>
              Cég neve: {dashboardListItemData.companyname}
            </ListGroup.Item>
          )}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
};

export default DasboardListMoreInfoModal;
