import axios from "axios";
const backendUrl = process.env.REACT_APP_BACKEND_URL_FOR_FRONTEND;
const token = localStorage.getItem("token");

export const getAllCrops = async () => {
  try {
    const crops = await axios.get(`${backendUrl}/crops`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return crops.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getAllCropsByCompanyId = async () => {
  try {
    const crops = await axios.get(`${backendUrl}/crops/companycrops`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return crops.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

/* export const getAllCropsByContainerId = async (containerId) => {
  try {
    const crops = await axios.get(
      `${backendUrl}/crops/containercrops/${containerId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return crops.data;
  } catch (error) {
    throw new Error(error.message);
  }
}; */

export const addNewCrop = async (companyId, crop) => {
  try {
    const newCrop = await axios.post(
      `${backendUrl}/crops`,
      {
        companyId,
        crop,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return newCrop.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const connectCropToContainer = async (containerId, cropId) => {
  try {
    const connected = await axios.post(
      `${backendUrl}/crops/containercrops`,
      {
        containerId,
        cropId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return connected.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

// UPDATE

export const updateCrop = async (cropId, updatedFields) => {
  try {
    const updated = await axios.patch(
      `${backendUrl}/crops/${cropId}`,
      updatedFields,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    
    return updated.data;
  } catch (error) {
    console.log("Error in update crop api:", error.message);
    throw error;
  }
};

// DELETE

export const deleteCropFromContainer = async (containerId, cropId) => {
  try {
    const deleted = await axios.delete(
      `${backendUrl}/crops/containercrops/${containerId}/${cropId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return deleted.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const deleteCropById = async (cropId) => {
  console.log("Delete crop with id in api:", cropId);
  try {
    const deleted = await axios.delete(`${backendUrl}/crops/${cropId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return deleted.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
