import React from "react";
import { Modal, Button } from "react-bootstrap";

const SessionExpiredPopup = ({ show, onClose }) => {
  return (
    <Modal show={show} onHide={onClose} backdrop="static" centered >
      <Modal.Header closeButton>
        <Modal.Title>Lejárt a munkamenet</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        A munkamenete lejárt. Kérjük jelentkezzen be újra.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onClose}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SessionExpiredPopup;
