/**
 * useScreenWidth Hook
 *
 * This custom hook provides the current width of the browser window. It listens for window resize
 * events and updates the width accordingly. The hook uses React's useState and useEffect hooks
 * to manage the state and side effects.
 *
 * Example usage:
 * const screenWidth = useScreenWidth();
 *
 * @returns {number} The current width of the browser window.
 */

import { useState, useEffect } from "react";

export function useScreenWidth() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth); // State to store the screen width

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth); // Function to update the screen width
    window.addEventListener("resize", handleResize); // Add event listener for window resize
    return () => {
      window.removeEventListener("resize", handleResize); // Cleanup event listener on component unmount
    };
  }, []);

  return screenWidth; // Return the current screen width
}
