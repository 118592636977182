// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}
.page-header .page-title {
  font-family: Helvetica, sans-serif;
  /*   font-family: Squalo-BoldItalic, sans-serif; */
  font-size: 2rem;
  font-weight: 600;
  width: -moz-fit-content;
  width: fit-content;
  letter-spacing: 0.02rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Shared/PageHeader/PageHeader.scss","webpack://./src/styles/_variables.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBC8BS;AD7Bb;AACI;EACI,kCAAA;EACF,kDAAA;EACE,eCUO;EDTP,gBCgBe;EDff,uBAAA;EAAA,kBAAA;EACA,uBAAA;AACR","sourcesContent":["@import \"../../../styles/variables.scss\";\n\n.page-header{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: $spacing-lg;\n\n    .page-title {\n        font-family: Helvetica, sans-serif; \n      /*   font-family: Squalo-BoldItalic, sans-serif; */\n        font-size: $font-size-lg;\n        font-weight: $font-weight-semibold;\n        width: fit-content;\n        letter-spacing: 0.02rem;\n      }\n}\n\n","@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');\n\n//FONTS\n$font-family-base: 'Roboto', sans-serif;\n$font-family-title: 'Montserrat', sans-serif;\n\n//COLORS\n$color-primary: #83643E;\n$text-color-base: #333;\n$btn-color-primary: #F3CA52;\n$btn-color-secondary: #4B8F29;\n$btn-color-info: #C7C8C4;\n$color-red: #D11A2A;\n$btn-text-color-primary: #fff;\n\n//FONT SIZES\n$font-size-xs: 0.8rem;\n$font-size-sm: 1rem;\n$font-size-base: 1.1rem;\n$font-size-md: 1.5rem;\n$font-size-lg: 2rem;\n$font-size-xl: 2.5rem;\n\n//FONT WEIGHTS\n$font-weight-light: 300;\n$font-weight-normal: 400;\n$font-weight-medium: 500;\n$font-weight-semibold: 600;\n$font-weight-bold: 700;\n$font-weight-black: 900;\n\n//SPACING\n$spacing-xs: 0.5rem;\n$spacing-sm: 1rem;\n$spacing-md: 1.5rem;\n$spacing-lg: 2rem;\n$spacing-xl: 3rem;\n\n//BREAKPOINTS\n$breakpoint-xs: 375px;\n$breakpoint-sm: 480px;\n$breakpoint-md: 768px;\n$breakpoint-lg: 1024px;\n$breakpoint-xl: 1200px;\n$breakpoint-xxl: 1800px;\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
