import axios from "axios";
import { getAuthHeaders } from "./auth";
const backendUrl = process.env.REACT_APP_BACKEND_URL_FOR_FRONTEND;
const token = localStorage.getItem("token");

export const getAllUsers = async () => {
  try {
    const users = await axios.get(`${backendUrl}/users`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return users;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getAllLocations = async () => {
  try {
    const locations = await axios.get(`${backendUrl}/locations`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return locations;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getAllCompanyUsers = async () => {
  try {
    const users = await axios.get(`${backendUrl}/users/companyusers`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return users.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

// POST
export const addNewCompanyUser = async (user) => {
  console.log("Token:", token);
  try {
    const newCompanyUser = await axios.post(
      `${backendUrl}/users/companyusers`,
      user,
      {
 headers: getAuthHeaders(),
      });
    return newCompanyUser;
  } catch (error) {
    throw new Error(error.message);
  }
};

// UPDATE
export const updateCompanyUser = async (userId, updatedFields) => {
  console.log("updatedFields", updatedFields);
  try {
    const updatedUser = await axios.put(
      `${backendUrl}/users/${userId}`,
      updatedFields, // Pass the updated fields as the request body
      {
        headers: getAuthHeaders(),
      });
    return updatedUser.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

// DELETE
export const deleteCompanyUser = async (userId) => {
  try {
    const deletedUser = await axios.delete(
      `${backendUrl}/users/${userId}`,
      {
        headers: getAuthHeaders(),
      });
    return deletedUser.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
