import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { EditOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import "../CropsPage.scss";

const EditCropModal = ({
  setSelectedCropForEdit,
  selectedCropForEdit,
  onSubmit,
  crop,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [cropId, setCropId] = useState(crop?._id || "");
  const [cropname, setCropname] = useState(crop?.cropname || "");
  const [description, setDescription] = useState(crop?.description || "");
  const [status, setStatus] = useState(crop?.status || "inactive");

  useEffect(() => {
    if (selectedCropForEdit) {
      setCropname(selectedCropForEdit.cropname);
      setDescription(selectedCropForEdit.description);
      setStatus(selectedCropForEdit.status);
      setCropId(selectedCropForEdit._id);
    }
  }, [selectedCropForEdit]);

  const handleCropNameChange = (e) => setCropname(e.target.value);
  const handleDescriptionChange = (e) => setDescription(e.target.value);
  const handleStatusChange = (e) => setStatus(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    let isValid = true;

    if (!cropname) {
      toast.error("A termény neve kötelező");
      isValid = false;
    }

    if (cropname.length < 3) {
      toast.error(
        "A termény nevének legalább 3 karakter hosszúnak kell lennie"
      );
      isValid = false;
    }

    if (cropname.length > 100) {
      toast.error("A termény neve maximum 100 karakter hosszú lehet");
      isValid = false;
    }

    if (!status) {
      toast.error("A státusz megadása kötelező");
      isValid = false;
    }

    if (status.length > 100) {
      toast.error("A státusz maximum 100 karakter hosszú lehet");
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    onSubmit({ _id: cropId, cropname, description, status });
    setModalIsOpen(false);
  };

  return (
    <div className="edit-crop-wrapper">
      <Button
        variant="contained"
        color="primary"
        data={selectedCropForEdit ? selectedCropForEdit._id : null}
        className="crops-page-list__edit-button"
        onClick={(e) => {
          setSelectedCropForEdit(crop); // Use a hardcoded ID for testing
          setModalIsOpen(true);
        }}
      >
        <EditOutlined />
      </Button>
      <Modal show={modalIsOpen} onHide={() => setModalIsOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Termény módosítása</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formCropName" className="mb-3">
              <Form.Label>Termény neve</Form.Label>
              <Form.Control
                type="text"
                value={cropname || ""}
                onChange={handleCropNameChange}
              />
            </Form.Group>
            <Form.Group controlId="formDescription" className="mb-3">
              <Form.Label>Leírás</Form.Label>
              <Form.Control
                type="text"
                value={description || ""}
                onChange={handleDescriptionChange}
              />
            </Form.Group>
            <Form.Group controlId="formStatus" className="mb-3">
              <Form.Label>Státusz</Form.Label>
              <Form.Control
                type="text"
                value={status || ""}
                onChange={handleStatusChange}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Mentés
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditCropModal;
