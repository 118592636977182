/**
 * CompanySettingsPage Component
 *
 * This component is responsible for rendering the company settings page.
 * It uses the `useSaveHandler` custom hook to handle the saving of the company name.
 * It also renders the `EditableSettingItem`, `UsersList`, and `LocationsList` components.
 *
 * The `EditableSettingItem` component is used to display and edit the company name.
 * The `UsersList` and `LocationsList` components are used to display lists of users and locations respectively.
 *
 * The data for the users and locations lists are currently dummy data (`usersListDummyData` and `locationsListDummyData`).
 * The headers for the users and locations tables (`userTableHeaders` and `locationTableHeaders`) are defined in the component.
 *
 * This component will be connected to the backend in the future to fetch and update real data.
 */

import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../../context/UserContext";
/* import useSavehandler from "../../../hooks/useSaveHandler"; */
import "./CompanySettingsPage.scss";
import EditableSettingItem from "../Shared/EditableSettingItem/EditableSettingItem";
import {
  getAllCompanyUsers,
  /*   getAllLocations, */
} from "../../../api/companySettingsApi";
import {
  getAllCompanies,
  getCompanyNameByCompanyId,
  getAllCompanyUsersByCompanyId,
  updateCompanyNameInSettingsByCompanyId,
} from "../../../api/settingsCompanyApi";
import { getAllSites, getAllSitesByCompanyId } from "../../../api/locationApi";
import UsersList from "./CompanyUsers/UsersList";
import LocationsList from "./CompanyLocations/LocationsList";
import { toast } from "react-toastify";

const CompanySettingsPage = () => {
  const { user } = useContext(UserContext);
  const [userListData, setUserListData] = useState([]);
  const [locationListData, setLocationListData] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [isUserSuperAdmin, setIsUserSuperAdmin] = useState(false);

  // Define the headers for the users table
  const userTableHeaders = {
    lastname: "Családnév",
    firstname: "Keresztnév",
    email: "Email cím",
    roles: "Szerepkör",
    location: "Telephely",
    notifications: "Értesítések",
    userMoreInfo: "",
  };

  // Define the headers for the locations table
  const locationTableHeaders = {
    sitename: "Telephely neve",
    address: "Cím",
    container_count: "Tárolók száma",
    locationMoreInfo: "",
  };

  //COMPANY NAME

  const fetchCompanyName = async () => {
    try {
      const companyName = await getCompanyNameByCompanyId(user.company_id);
      setCompanyName(companyName.companyName);
    } catch (error) {
      console.error("Error fetching company name: ", error);
    }
  };

  useEffect(() => {
    fetchCompanyName();
  }, []);

  const updateCompanyName = async (newCompanyName) => {
    try {
      // Update the company name in the backend
      await updateCompanyNameInSettingsByCompanyId(user.company_id, newCompanyName);
      setCompanyName(newCompanyName);
    } catch (error) {
      console.error("Error updating company name: ", error);
    }
  };

  console.log("IS SUPER ADMIN", isUserSuperAdmin);

  // Fetch the company list if the user is a super admin user
  useEffect(() => {
    if (user && user.roles === "0") {
      setIsUserSuperAdmin(true);
      getAllCompaniesForFiltering();
      console.log(isUserSuperAdmin);
    } else {
      setIsUserSuperAdmin(false);
      getAllUsersInCompany(user.company_id);
    }
  }, [user]);

  useEffect(() => {
    if (isUserSuperAdmin) {
      getAllCompaniesForFiltering();
    }
  }, [isUserSuperAdmin]);

  const fetchUsersAndLocations = async () => {
    try {
      if (isUserSuperAdmin) {
        await getAllCompaniesForFiltering();
        const users = await getAllCompanyUsers();
        setUserListData(users);
        console.log("USERS AS SUPERADMIN", users);
        const locations = await getAllSites();
        setLocationListData(locations);
        console.log("LOCATIONS AS SUPERADMIN", locations);
      } else {
        const users = await getAllCompanyUsersByCompanyId(user.company_id);
        setUserListData(users);
        console.log("USERS AS NO SUPERADMIN", users);
        const locations = await getAllSitesByCompanyId(user.company_id);
        setLocationListData(locations);
        console.log("LOCATIONS AS NO SUPERADMIN", locations);
      }
    } catch (error) {
      console.error("Error fetching users: ", error);
    }
  };
  
  useEffect(() => {
    fetchUsersAndLocations();
  }, [isUserSuperAdmin]);

  useEffect(() => {
    console.log(isUserSuperAdmin);
  }, [isUserSuperAdmin]);

  // Fetch all companies for filtering if the user is a super admin user
  const getAllCompaniesForFiltering = async () => {
    try {
      const companies = await getAllCompanies();
      setCompanyList(companies);
      console.log(companies);
    } catch (error) {
      console.error("Error fetching companies: ", error);
    }
  };

  // Fetch all users in the company if the user is not a super admin user
  const getAllUsersInCompany = async () => {
    try {
      const users = await getAllCompanyUsersByCompanyId(user.company_id);
      setUserListData(users);
    } catch (error) {
      console.error("Error fetching users: ", error);
    }
  };

  const handleCompanyFilterChange = async (companyid) => {
    const selectedCompanyId = companyid;

    if (selectedCompanyId === "all") {
      try {
        const companies = await getAllCompanies();
        setCompanyList(companies);

        // Fetch all users across all companies
        const allUsers = await getAllCompanyUsers();
        setUserListData(allUsers);
        console.log("allUsers", allUsers);
      } catch (error) {
        console.error("Error fetching all users: ", error);
      }
      return;
    }

    console.log("userList", userListData);

    // Filter the users by the selected company
    try {
      const users = await getAllCompanyUsersByCompanyId(selectedCompanyId);
      setUserListData(users);
    } catch (error) {
      console.error("Error fetching users for selected company: ", error);
    }
  };

  const handleSave = async (newValue, id) => {
    let isValid = true;
    let errorMessage = "";
    if (newValue === "") {
      isValid = false;
      errorMessage = "A cég neve nem lehet üres!";
    }

    if (newValue.length < 3 || newValue.length > 100) {
      isValid = false;
      errorMessage = "A cég neve minumum 3 és maximum 100 karakter lehet!";
    }

    if (!isValid) {
      toast.error(errorMessage);
      return;
    }

    if (id === "companyname") {
      updateCompanyName(newValue);
      setCompanyName(newValue);
    }
  };

  return (
    <div className="container company-settings-page__container navbar-margin">
      <h1 className="page-title">Céges beállítások</h1>
      <div className="user-settings-page__content">
        <EditableSettingItem
          id="companyname"
          title="Cégnév"
          value={companyName}
          onSave={handleSave}
          type="text"
        />
        <UsersList
          tableHeaders={userTableHeaders}
          tableData={userListData}
          onChange={handleCompanyFilterChange}
          isUserSuperAdmin={isUserSuperAdmin}
          //Pass the company list to the UsersList component if the user is a super admin user
          {...(isUserSuperAdmin && { companyList })}
        />
        <LocationsList
          tableHeaders={locationTableHeaders}
          tableData={locationListData}
          onChange={handleCompanyFilterChange}
          fetchUsersAndLocations={fetchUsersAndLocations}
          isUserSuperAdmin={isUserSuperAdmin}
          //Pass the company list to the UsersList component if the user is a super admin user
          {...(isUserSuperAdmin && { companyList })}
        />
      </div>
    </div>
  );
};

export default CompanySettingsPage;
