import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { PlusCircleOutlined } from "../../../helpers/icons/antDesignIcons";
import "../CropsPage.scss";
import useTranslation from "../../../hooks/useTranslation";

const AddNewCropModal = ({ onSubmit }) => {
  const { t } = useTranslation("cropsPage");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [cropName, setCropName] = useState("");
  /*   const [quantity, setQuantity] = useState("");
  const [unit, setUnit] = useState(""); */
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const newCrop = {
      cropName,
      description,
      status,
    };

    onSubmit(newCrop);
    setModalIsOpen(false);
  };

  return (
    <div className="add-crop-wrapper">
      <Button
        variant="contained"
        color="primary"
        className="locations-page__header__add__btn"
        onClick={() => setModalIsOpen(true)}
      >
        <PlusCircleOutlined />
        {t("addNewCrop")}
      </Button>
      <Modal show={modalIsOpen} onHide={() => setModalIsOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("addNewCrop")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="add-crop-form" onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>{t("cropname")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("cropname")}
                required
                onChange={(e) => setCropName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t("description")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("description")}
                required
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t("status")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("status")}
                required
                onChange={(e) => setStatus(e.target.value)}
              />
            </Form.Group>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setModalIsOpen(false)}>
                {t("cancel")}
              </Button>
              <Button variant="primary" type="submit">
                {t("save")}
              </Button>
            </Modal.Footer>{" "}
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddNewCropModal;
