/**
 * DevicesPage Component
 *
 * This component is responsible for rendering the devices page. It fetches and displays a list of devices,
 * sites, and containers from the API. It also provides functionality to filter devices by site and container.
 * Additionally, it allows navigation to a detailed device page and offers options to edit or delete a device.
 *
 * State Variables:
 * - devices: An array of all devices fetched from the API.
 * - filteredDevices: An array of devices filtered based on the selected site and container.
 * - devicesWithoutContainer: An array of devices that are not associated with any container.
 * - sites: An array of all sites fetched from the API.
 * - containers: An array of all containers fetched from the API.
 * - filteredContainers: An array of containers filtered based on the selected site.
 * - selectedSite: The currently selected site for filtering devices and containers.
 * - selectedContainer: The currently selected container for filtering devices.
 *
 * Functions:
 * - fetchDevices: Fetches all devices from the API and updates the 'devices' state.
 * - fetchSitesAndContainers: Fetches all sites and containers from the API and updates the 'sites' and 'containers' state.
 * - handleSiteChange: Handles the change event for the site select input. It updates the 'selectedSite' state and filters the 'devices' and 'containers' state based on the selected site.
 * - handleContainerChange: Handles the change event for the container select input. It updates the 'selectedContainer' state and filters the 'devices' state based on the selected container.
 * - handleMoreInfo: Navigates to the detailed page of the selected device.
 *
 * useEffect Hooks:
 * - The first useEffect fetches all devices when the component mounts.
 * - The second useEffect filters the devices without a container whenever the 'devices' state updates.
 * - The third useEffect fetches all sites and containers when the component mounts.
 * - The fourth useEffect filters the devices based on the selected site whenever the 'selectedSite' or 'devices' state updates.
 * - The fifth useEffect filters the devices based on the selected container whenever the 'selectedContainer' or 'devices' state updates.
 **/

import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import {
  getAllDevicesByCompanyId,
  getAllDevicesWithNoContainerByCompanyId,
} from "../../api/deviceApi";
import {
  getAllContainersBySiteName,
  getAllSitesByCompanyId,
  getAllContainersByCompanyId,
} from "../../api/locationApi";
/* import ShowCountOfDevices from "../Shared/ShowCountOfDevices/ShowCountOfDevices"; */
import ConnectDeviceToContainerButton from "../Shared/ConnectDeviceToContainerButton/ConnectDeviceToContainerButton";
import HelperModal from "../Shared/HelperModal/HelperModal";
import { Button, FormSelect, Table } from "react-bootstrap";
import {
  EditOutlined,
  DeleteOutlined,
} from "../../helpers/icons/antDesignIcons";
import "./DevicesPage.scss";

const DevicesPage = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const companyId = user.company_id;
  // DEVICES STATES
  const [devices, setDevices] = useState([]);
  const [devicesForConnectDevice, setDevicesForConnectDevice] = useState([]);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [devicesWithoutContainer, setDevicesWithoutContainer] = useState([]);
  const devicesTableHeaders = {
    probeid: "Eszköz azonosító",
    containername: "Tároló neve",
    sitename: "Telephely neve",
    /*     status: "Státusz", */
/*     sens_number: "Szenzorok száma", */
    edit: "Módosítás",
    delete: "Eltávolítás tárolóból",
  };
  // SITES AND CONTAINERS STATES
  const [sites, setSites] = useState([]);
  const [sitesForConnectDevice, setSitesForConnectDevice] = useState([]);
  const [containers, setContainers] = useState([]);
  const [containersForConnectDevice, setContainersForConnectDevice] = useState(
    []
  );
  const deviceTypes = ["Szonda", "Bázis"];
  const [selectedSite, setSelectedSite] = useState(null);
  const [selectedContainer, setSelectedContainer] = useState(null);
  const [filteredContainers, setFilteredContainers] = useState([]);
  const [selectedDeviceType, setSelectedDeviceType] = useState(null);

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const devicesData = await getAllDevicesByCompanyId(companyId);
        setDevices(devicesData);
        setDevicesForConnectDevice(devicesData);
      } catch (error) {
        console.error("Error fetching devices: ", error);
      }
    };
    fetchDevices();
  }, []);

  useEffect(() => {
    const fetchDevicesByCompanyId = async () => {
      try {
        const devicesData = await getAllDevicesWithNoContainerByCompanyId(
          companyId
        );
        setDevicesWithoutContainer(devicesData);
      } catch (error) {
        console.error("Error fetching devices: ", error);
      }
    };
    fetchDevicesByCompanyId();
  }, [companyId]);

  useEffect(() => {
    const fetchSitesAndContainers = async () => {
      try {
        const sitesData = await getAllSitesByCompanyId(companyId);
        setSites(sitesData.data);
        setSitesForConnectDevice(sitesData.data);
        console.log(sites);
        // Fetch containers by companyId
        const containersData = await getAllContainersByCompanyId(companyId);
        setContainers(containersData.data);
        setContainersForConnectDevice(containersData.data);
      } catch (error) {
        console.error("Error fetching sites: ", error);
      }
    };
    fetchSitesAndContainers();
  }, []);

  useEffect(() => {
    if (selectedSite === null || selectedSite === "all") {
      setFilteredDevices(devices);
    } else {
      setFilteredDevices(
        devices.filter((device) => device.sitename === selectedSite)
      );
    }
  }, [selectedSite, devices]);

  useEffect(() => {
    if (selectedContainer === null || selectedContainer === "all") {
      setFilteredDevices(devices);
    } else {
      setFilteredDevices(
        devices.filter(
          (device) =>
            device.containerid &&
            device.containerid.toString() === selectedContainer
        )
      );
    }
  }, [selectedContainer, devices]);

  const handleSiteChange = async (e) => {
    const siteName = e.target.value;
    setSelectedSite(siteName);

    try {
      const containersData = await getAllContainersBySiteName(siteName);
      if (Array.isArray(containersData)) {
        setContainers(containersData);
        setFilteredContainers(containersData); // Add this line
      } else {
        console.error(
          "Error: Expected containersData to be an array, got ",
          containersData
        );
        setContainers([]);
        setFilteredContainers([]); // And this line
      }

      // Fetch the updated devices list
      const devicesData = await getAllDevicesByCompanyId(companyId);

      if (siteName === "all") {
        setDevices(devicesData);
      } else {
        setDevices(
          devicesData.filter((device) => device.sitename === siteName)
        );
      }
    } catch (error) {
      console.error("Error fetching containers: ", error);
    }
  };

  const handleContainerChange = async (e) => {
    const containerName = e.target.value;
    setSelectedContainer(containerName);

    try {
      const devicesData = await getAllDevicesByCompanyId(companyId);

      if (!Array.isArray(devicesData)) {
        console.error("Error: devicesData is not an array");
        return;
      }

      if (containerName === "all") {
        setDevices(
          devicesData.filter((device) => device.sitename === selectedSite)
        );
      } else {
        setDevices(
          devicesData.filter((device) => device.containername === containerName)
        );
      }
    } catch (error) {
      console.error("Error fetching containers: ", error);
    }
  };

  const handleMoreInfo = (device) => {
    navigate(`/devices/${device.probeid}`, { state: { device } });
  };

  const handleDeviceTypeChange = async (e) => {
    const deviceType = e.target.value;
    setSelectedDeviceType(deviceType);
  };

  return (
    <div className="container devices-page__container navbar-margin">
      <h1 className="page-title">Eszközök</h1>
      <div className="devices-page__content">
        <div className="devices-page__header">
          <div className="devices-page__header__filter__wrapper">
            <div className="devices-page__header__filter">
              <span className="devices-page__header__filter__label">
                Telephelyek:
              </span>
              <FormSelect
                id="devices-devicestype"
                className="devices-page__header__filter__select"
                value={selectedSite || "all"}
                onChange={handleSiteChange}
              >
                <option value="all">Összes</option>
                {sites.map((site) => (
                  <option key={site._id} value={site.siteid}>
                    {site.sitename}
                  </option>
                ))}
              </FormSelect>
            </div>
            <div className="devices-page__header__filter">
              <span className="devices-page__header__filter__label">
                Tárólók:
              </span>
              <FormSelect
                id="devices-page__filter__location"
                className="devices-page__header__filter__select"
                value={selectedContainer || "all"}
                onChange={handleContainerChange}
              >
                <option value="all">Összes</option>
                {filteredContainers.map((container) => (
                  <option
                    key={container.containername}
                    value={container.containerid}
                  >
                    {container.containername}
                  </option>
                ))}
              </FormSelect>
            </div>
            {/* <div className="devices-page__header__filter">
              <span className="devices-page__header__filter__label">
                Eszköz típusok:
              </span>
              <FormSelect
                id="devices-page__filter__device"
                className="devices-page__header__filter__select"
                value={selectedDeviceType || "all"}
                onChange={handleDeviceTypeChange}
              >
                <option value="all">Összes</option>
                {deviceTypes.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </FormSelect>
            </div> */}
          </div>
          <ConnectDeviceToContainerButton
            deviceList={devicesWithoutContainer}
            containerList={containersForConnectDevice}
            siteList={sitesForConnectDevice}
          />
          <HelperModal
            title="Eszközök"
            body={`
            Ezen az oldalon a cégéhez tartozó összes eszközt megtekintheti. \nAz eszközöket telephelyek és tárolók szerint is szűrheti.\n Az eszközöket a tárolókhoz tudja rendelni, illetve tárolókból is tudja eltávolítani. Az eszközökre kattintva részletes információkat tud megtekinteni az eszközről, illetve módosítani vagy törölni is tudja azokat.
            `}
          />
        </div>
        {/* <ShowCountOfDevices companyId={companyId} /> */}
        <div className="table__wrapper">
          <Table
            headers={devicesTableHeaders}
            data={filteredDevices}
            className="devices-page__table"
          >
            <thead>
              <tr className="dpt-header-tr">
                {Object.keys(devicesTableHeaders).map((key) => (
                  <th key={key} id={`dpt-${key}-header`}>
                    {devicesTableHeaders[key]}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {devices.map((device, index) => (
                <tr key={index} className="dpt-row">
                  {Object.keys(devicesTableHeaders).map((key) => (
                    <td
                      key={key}
                      id={`dpt-${key}-data`}
                      className="table-data devices-page__table-data"
                      onClick={
                        key === "probeid" ? () => handleMoreInfo(device) : null
                      }
                    >
                      {key === "edit" ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            console.log("Edit device");
                          }}
                          className="devices-page-list__edit-button"
                          
                        >
                          <EditOutlined />
                        </Button>
                      ) : key === "delete" ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            console.log("Delete device");
                          }}
                          className="devices-page-list__delete-button"
                        >
                          <DeleteOutlined />
                        </Button>
                      ) : key === "sens_number" ? (
                        device[key]
                      ) : (
                        device[key] || "-"
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default DevicesPage;
