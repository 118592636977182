/**
 * PageHeader Component
 * 
 * This component renders a page header with a title and an optional helper modal.
 * The helper modal can provide additional information or instructions related to
 * the page. The title and the content of the helper modal are passed as props.
 * 
 * Props:
 * - title: The main title to be displayed in the page header.
 * - helperTitle: The title for the helper modal.
 * - helperBody: The body content for the helper modal.
 * 
 * Example usage:
 * <PageHeader 
 *   title="Dashboard" 
 *   helperTitle="Help" 
 *   helperBody="This is the dashboard page where you can see an overview of your data." 
 * />
 */


import React from "react";
import HelperModal from "../HelperModal/HelperModal";
import "./PageHeader.scss";

const PageHeader = ({ title, helperTitle, helperBody }) => {
  return (
    <div className="page-header">
      <div className="page-title">{title}</div>
      <HelperModal title={helperTitle} body={helperBody} />
    </div>
  );
};

export default PageHeader;
