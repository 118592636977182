import React, { useContext, useRef, useState } from "react";
import UserContext from "../../../../context/UserContext";
import { useNavigate } from "react-router-dom";
import { updateDeviceCoordinates } from "../../../../api/deviceApi";
import Draggable from "react-draggable";
import ImageUpload from "../../../Shared/ImageUpload/ImageUpload";
import DeviceIcon from "../../../../assets/images/deviceIcons/probeicon.png";
import { Overlay, Tooltip } from "react-bootstrap";
import "../ContainerMoreInfoPage.scss";
import { PictureOutlined } from "../../../../helpers/icons/antDesignIcons";
import USER_ROLES from "../../../../constants/userRoles";
import useTranslation from "../../../../hooks/useTranslation";

/**
 * Device Component
 *
 * Summary:
 * This component represents a draggable device icon within the container floor plan.
 * It allows users to move the device and updates its coordinates in the backend.
 *
 * Props:
 * - id: Unique identifier for the device.
 * - initialPosition: Initial position of the device in percentage coordinates.
 * - onMove: Callback function to handle device movement.
 * - parentSize: Size of the parent container to calculate percentage coordinates.
 * - device: Device object containing details like probeid and baseid.
 */

const Device = ({ id, initialPosition, onMove, parentSize, device }) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const { user } = useContext(UserContext);
  const { t } = useTranslation("locationsPage");

  // Calculate initial position in pixels based on parent size
  const position = {
    x: (initialPosition.x * parentSize.width) / 100 || 0,
    y: (initialPosition.y * parentSize.height) / 100 || 0,
  };

  // Handle click on device link to navigate to device details page
  const handleDeviceLinkClick = (e) => {
    e.preventDefault();
    navigate(
      `/devices/${device.probeid}
      `,
      { state: { device } }
    );
  };

  const deviceContent = (
    <div
      ref={target}
      className="cmw-device-content"
      style={{
        position: "absolute",
        cursor: "pointer",
        padding: "5px",
        borderRadius: "5px",
      }}
      onMouseOver={() => setShow(true)}
      onMouseOut={() => setShow(false)}
    >
      <img src={DeviceIcon} alt="Device Icon" width="20" height="20" />
      <div style={{ fontSize: "12px", marginTop: "5px" }}>{device.probeid}</div>
      <Overlay target={target.current} show={show} placement="top">
        {(props) => (
          <Tooltip
            id={`device-${id}`}
            {...props}
            className="container-device__tooltip"
          >
            {t("sensor")}: {device.probeid}
            <br />
            {t("base_related_to_probe")}: {device.baseid}
            <br />
            <button
              onClick={handleDeviceLinkClick}
              className="btn floorplan-link-btn"
            >
              {t("see_device")}
            </button>
          </Tooltip>
        )}
      </Overlay>
    </div>
  );

  return user.roles === USER_ROLES.COMPANYUSER ? (
    <div style={{ position: "absolute", left: position.x, top: position.y }}>
      {deviceContent}
    </div>
  ) : (
    <Draggable
      defaultPosition={position}
      onMouseOver={() => setShow(true)}
      onMouseOut={() => setShow(false)}
      onStop={(e, data) => {
        // Calculate new position in percentage coordinates
        let percentX = (data.x / parentSize.width) * 100;
        let percentY = (data.y / parentSize.height) * 100;
        // Round to two decimal places
        percentX = parseFloat(percentX.toFixed(2));
        percentY = parseFloat(percentY.toFixed(2));

        // Call onMove callback with new coordinates
        onMove(id, percentX, percentY, parentSize);
        // Update device coordinates in the backend
        (async () => {
          try {
            await updateDeviceCoordinates(id, percentX, percentY);
            console.log("Device coordinates updated successfully");
          } catch (error) {
            console.error("Failed to update device coordinates:", error);
          }
        })();
      }}
    >
      {deviceContent}
    </Draggable>
  );
};

/**
 * ContainerFloorPlanView Component
 *
 * Summary:
 * This component represents the floor plan view of a container. It displays the container layout image and allows users to move devices within the layout.
 *
 * Props:
 * - devices: Array of device objects to be displayed on the floor plan.
 * - handleDeviceMove: Callback function to handle device movement.
 * - imageData: Data of the container layout image.
 * - imageRef: Reference to the image element.
 * - imageSize: Size of the image element.
 * - setImageSize: Function to set the size of the image element.
 * - showImageUpload: Boolean to show/hide the image upload component.
 * - setShowImageUpload: Function to toggle the image upload component visibility.
 * - containerId: Unique identifier for the container.
 */

const ContainerFloorPlanView = ({
  devices,
  handleDeviceMove,
  imageData,
  imageRef,
  imageSize,
  setImageSize,
  showImageUpload,
  setShowImageUpload,
  containerId,
}) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation("locationsPage");

  return (
    <div>
      <h4 className="container-view-subtitle">{t("map_view")}</h4>

      {imageData && (
        <div
          className="layout"
          style={{ position: "relative", maxWidth: "100%" }}
        >
          {imageData && imageData.container_layout_img ? (
            <div
              className="layout"
              style={{ position: "relative", maxWidth: "100%" }}
            >
              <img
                ref={imageRef}
                src={`data:image/jpeg;base64,${btoa(
                  String.fromCharCode.apply(
                    null,
                    imageData.container_layout_img.data
                  )
                )}`}
                alt="Container Layout"
                className="container-layout-img"
                onLoad={() => {
                  const rect = imageRef.current.getBoundingClientRect();
                  setImageSize({
                    width: rect.width,
                    height: rect.height,
                  });
                }}
                style={{ display: "block", width: "100%", height: "auto" }}
              />
              <div
                className="devices-container"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              >
                {devices.map((device) => (
                  <Device
                    key={device.probeid}
                    id={device.probeid}
                    initialPosition={{ x: device.x, y: device.y }}
                    onMove={handleDeviceMove}
                    parentSize={imageSize}
                    device={device}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div className="no-image-container">
              <p>{t("devices_connected_to_container")}:</p>
              <ul>
                {devices.map((device) => (
                  <li key={device.probeid}>
                    {t("probe")}: {device.probeid}
                  </li>
                ))}
              </ul>{" "}
              <p>{t("no_floorplan_uploaded")}.</p>
            </div>
          )}
          {/* if the user is superadmin or company admin or location leader for the given site they sould be able to upload an image, the user shouldnt see the button */}
          {(user.roles === USER_ROLES.SUPER_ADMIN ||
            user.roles === USER_ROLES.COMPANY_ADMIN ||
            user.roles === USER_ROLES.LOCATION_LEADER) && (
            <div className="upload-container">
              <button
                onClick={() => setShowImageUpload(!showImageUpload)}
                className={`btn ${
                  showImageUpload ? "btn-danger" : "btn-primary"
                } upload-button`}
              >
                {showImageUpload ? (
                  t("cancel")
                ) : (
                  <>
                    <PictureOutlined /> {t("upload_floorplan")}
                  </>
                )}
              </button>

              {showImageUpload && <ImageUpload containerId={containerId} />}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ContainerFloorPlanView;
