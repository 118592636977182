/**
 * SessionExpiredContext.js
 *
 * This file defines a context and a provider component for handling session expiration.
 * It checks the expiration of a JWT token stored in localStorage and displays a popup
 * when the session has expired. The context provides a boolean value indicating whether
 * the session has expired.
 *
 * Example usage:
 * <SessionExpiredProvider>
 *   <YourComponent />
 * </SessionExpiredProvider>
 *
 * @returns {JSX.Element} The SessionExpiredProvider component.
 */

import React, { createContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import SessionExpiredPopup from "../components/SessionExpiredPopup/SessionExpiredPopup";

// Create a context for session expiration
const SessionExpiredContext = createContext();

// Provider component for session expiration context
export const SessionExpiredProvider = ({ children }) => {
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Function to check token expiration
    const checkTokenExpiration = () => {
      const token = localStorage.getItem("token");
      if (token) {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp < currentTime) {
          setIsSessionExpired(true);
          setShowPopup(true);
          localStorage.removeItem("token");
        }
      }
    };

    checkTokenExpiration();
    const interval = setInterval(checkTokenExpiration, 3600000); // Check every hour
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

    // Function to handle closing the popup
  const handleClosePopup = () => {
    setShowPopup(false);
    navigate("/");
  };

  return (
    <SessionExpiredContext.Provider value={{ isSessionExpired }}>
      {children}
      {showPopup && (
        <SessionExpiredPopup show={showPopup} onClose={handleClosePopup} />
      )}
    </SessionExpiredContext.Provider>
  );
};

export default SessionExpiredContext;
