/**
 * SensorContext.js
 * 
 * This file defines a context and a custom hook for managing sensor data. The context provides
 * a default value with an empty function for refreshing sensor data and an empty array for
 * storing sensor data. The custom hook allows components to access the context.
 * 
 * Example usage:
 * import { SensorDataContext, useSensorData } from './SensorContext';
 * 
 * // In a component
 * const { refreshSensorData, sensorDataForList } = useSensorData();
 * 
 * @returns {Object} The SensorDataContext and useSensorData hook.
 */

import { createContext, useContext } from 'react';

// Create a context with an empty function as default for refreshing sensor data
const SensorDataContext = createContext({
  refreshSensorData: () => {},
  sensorDataForList: [],
});

// Custom hook to use the SensorDataContext
export const useSensorData = () => useContext(SensorDataContext);

// Export SensorDataContext so it can be used outside this module
export { SensorDataContext };