/**
 * EditableSettingItem.jsx
 *
 * This component represents an individual setting item that can be edited by the user.
 * It receives the following props:
 * - id: a unique identifier for the setting
 * - title: the display name of the setting
 * - value: the current value of the setting
 * - onSave: a function to be called when the user saves their changes
 * - type: the type of the input field (e.g., "text", "password", "dropdown")
 * - options: an array of options for the dropdown input field
 *
 * The component uses local state to manage whether it is currently being edited (isEditing) and the new value entered by the user (newValue).
 *
 * The handleEditClick function is used to start editing the setting, and the handleSaveClick function is used to save the changes and stop editing.
 *
 * The component renders a div with the title and current value of the setting, an edit button, and a modal for editing the setting.
 * The modal contains a form control for the new value, which is a dropdown if the type prop is "dropdown" and a regular input field otherwise.
 * The modal also contains save and cancel buttons.
 */

import React, { useState } from "react";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "../../../../helpers/icons/antDesignIcons";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import { toast } from "react-toastify";
import "./EditableSettingItem.scss";
import useTranslation from "../../../../hooks/useTranslation";

const EditableSettingItem = ({
  id,
  title,
  value,
  onSave,
  type,
  options,
  isPassword,
}) => {
  const { t } = useTranslation("settingsPage");
  const [isEditing, setIsEditing] = useState(false);
  const [newValue, setNewValue] = useState(value);
  const [confirmNewValue, setConfirmNewValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    if (isPassword && newValue !== confirmNewValue) {
      toast.error("Jelszó nem egyezik!");
      return;
    }
    onSave(newValue, id);
    setIsEditing(false);
  };

  return (
    <div className="editable-setting-item">
      <div className="editable-setting-item__text">
        <h2>{title}</h2>
        <p>{value}</p>
      </div>
      <div className="editable-setting-item__button">
        <button
          onClick={handleEditClick}
          className="editable-setting-item-edit__button"
        >
          <EditOutlined />
        </button>
      </div>
      <Modal show={isEditing} onHide={() => setIsEditing(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {type === "dropdown" ? (
            <Form.Control
              as="select"
              value={newValue}
              onChange={(e) => setNewValue(e.target.value)}
            >
              {options.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </Form.Control>
          ) : (
            <Form.Control
              type={isPassword ? (showPassword ? "text" : "password") : type}
              name={title}
              value={newValue}
              onChange={(e) => setNewValue(e.target.value)}
              placeholder={`${title}`}
            />
          )}
          {isPassword && (
            <InputGroup style={{ marginTop: "10px" }}>
              <Form.Control
                type={showPassword ? "text" : "password"}
                className="form-control"
                id="password"
                placeholder={t("minPassLengthPlaceholder")}
                value={confirmNewValue}
                onChange={(e) => setConfirmNewValue(e.target.value)}
              />
              <Button
                onClick={() => setShowPassword(!showPassword)}
                variant="outline-secondary"
              >
                {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
              </Button>
            </InputGroup>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleSaveClick}
            className="btn btn-save"
          >
            <CheckOutlined />
          </Button>
          <Button
            variant="secondary"
            onClick={() => setIsEditing(false)}
            className="btn btn-secondary"
          >
            <CloseOutlined />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditableSettingItem;
