import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { updateDeviceCoordinates } from "../../../../api/deviceApi";
import Draggable from "react-draggable";
import ImageUpload from "../../../Shared/ImageUpload/ImageUpload";
import DeviceIcon from "../../../../assets/images/deviceIcons/probeicon.png";
import { Overlay, Tooltip } from "react-bootstrap";
import "../ContainerMoreInfoPage.scss";
import { PictureOutlined } from "../../../../helpers/icons/antDesignIcons";

/**
 * Device Component
 *
 * Summary:
 * This component represents a draggable device icon within the container floor plan.
 * It allows users to move the device and updates its coordinates in the backend.
 *
 * Props:
 * - id: Unique identifier for the device.
 * - initialPosition: Initial position of the device in percentage coordinates.
 * - onMove: Callback function to handle device movement.
 * - parentSize: Size of the parent container to calculate percentage coordinates.
 * - device: Device object containing details like probeid and baseid.
 */

const Device = ({ id, initialPosition, onMove, parentSize, device }) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const target = useRef(null);

   // Calculate initial position in pixels based on parent size
  const position = {
    x: (initialPosition.x * parentSize.width) / 100 || 0,
    y: (initialPosition.y * parentSize.height) / 100 || 0,
  };
  
 // Handle click on device link to navigate to device details page
  const handleDeviceLinkClick = (e) => {
    e.preventDefault();
    navigate(
      `/devices/${device.probeid}
      `,
      { state: { device } }
    );
  };

  return (
    <Draggable
      defaultPosition={position}
      onMouseOver={() => setShow(true)}
      onMouseOut={() => setShow(false)}
      onStop={(e, data) => {
          // Calculate new position in percentage coordinates
        let percentX = (data.x / parentSize.width) * 100;
        let percentY = (data.y / parentSize.height) * 100;
        // Round to two decimal places
        percentX = parseFloat(percentX.toFixed(2));
        percentY = parseFloat(percentY.toFixed(2));

        // Call onMove callback with new coordinates
        onMove(id, percentX, percentY, parentSize);
        // Update device coordinates in the backend
        (async () => {
          try {
            await updateDeviceCoordinates(id, percentX, percentY);
            console.log("Device coordinates updated successfully");
          } catch (error) {
            console.error("Failed to update device coordinates:", error);
          }
        })();
      }}
    >
      <div
        ref={target}
        className="device"
        style={{
          position: "absolute",
          cursor: "pointer",
          backgroundColor: "lightblue",
          padding: "5px",
          borderRadius: "5px",
        }}
        onMouseOver={() => setShow(true)}
        onMouseOut={() => setShow(false)}
      >
        <img src={DeviceIcon} alt="Device Icon" width="20" height="20" />
        {/* <span style={{ marginLeft: "5px" }}>Device {id}</span> */}
        <Overlay target={target.current} show={show} placement="top">
          {(props) => (
            <Tooltip
              id={`device-${id}`}
              {...props}
              className="container-device__tooltip"
            >
              Szenzor: {device.probeid}
              <br />
              Eszközhöz tartozó bázis: {device.baseid}
              <br />
              <button onClick={handleDeviceLinkClick} className="btn floorplan-link-btn">Eszköz megtekintése</button>
            </Tooltip>
          )}
        </Overlay>
      </div>
    </Draggable>
  );
};

/**
 * ContainerFloorPlanView Component
 *
 * Summary:
 * This component represents the floor plan view of a container. It displays the container layout image and allows users to move devices within the layout.
 *
 * Props:
 * - devices: Array of device objects to be displayed on the floor plan.
 * - handleDeviceMove: Callback function to handle device movement.
 * - imageData: Data of the container layout image.
 * - imageRef: Reference to the image element.
 * - imageSize: Size of the image element.
 * - setImageSize: Function to set the size of the image element.
 * - showImageUpload: Boolean to show/hide the image upload component.
 * - setShowImageUpload: Function to toggle the image upload component visibility.
 * - containerId: Unique identifier for the container.
 */

const ContainerFloorPlanView = ({
  devices,
  handleDeviceMove,
  imageData,
  imageRef,
  imageSize,
  setImageSize,
  showImageUpload,
  setShowImageUpload,
  containerId,
}) => {
  return (
    <div>
      <h4 className="container-view-subtitle">Magtár nézet</h4>

      {imageData && (
        <div
          className="layout"
          style={{ position: "relative", maxWidth: "100%" }}
        >
          {imageData && imageData.container_layout_img ? (
            <div
              className="layout"
              style={{ position: "relative", maxWidth: "100%" }}
            >
              <img
                ref={imageRef}
                src={`data:image/jpeg;base64,${btoa(
                  String.fromCharCode.apply(
                    null,
                    imageData.container_layout_img.data
                  )
                )}`}
                alt="Container Layout"
                className="container-layout-img"
                onLoad={() => {
                  const rect = imageRef.current.getBoundingClientRect();
                  setImageSize({
                    width: rect.width,
                    height: rect.height,
                  });
                }}
                style={{ display: "block", width: "100%", height: "auto" }}
              />
              <div
                className="devices-container"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              >
                {devices.map((device) => (
                  <Device
                    key={device.probeid}
                    id={device.probeid}
                    initialPosition={{ x: device.x, y: device.y }}
                    onMove={handleDeviceMove}
                    parentSize={imageSize}
                    device={device}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div className="no-image-container">
              <p>Tárolóhoz rendelt eszközök:</p>
              <ul>
                {devices.map((device) => (
                  <li key={device.probeid}>Szonda: {device.probeid}</li>
                ))}
              </ul>{" "}
              <p>Nincs alaprajz feltöltve ehhez a tárolóhoz.</p>
            </div>
          )}
          <div className="upload-container">
            <button
              onClick={() => setShowImageUpload(!showImageUpload)}
              className={`btn ${
                showImageUpload ? "btn-danger" : "btn-primary"
              } upload-button`}
            >
              {showImageUpload ? (
                "Mégsem"
              ) : (
                <>
                  <PictureOutlined /> Új alaprajz feltöltése
                </>
              )}
            </button>

            {showImageUpload && <ImageUpload containerId={containerId} />}
          </div>
        </div>
      )}
    </div>
  );
};

export default ContainerFloorPlanView;
