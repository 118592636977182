/**
 * NotificationTable is a component that displays a table of notifications with pagination.
 *
 * @component
 * @param {object} props - The properties that define the NotificationTable component.
 * @param {Array} props.currentPageData - The data for the current page of the table.
 * @param {object} props.notificationTableHeaders - The headers for the table.
 * @param {function} props.handleSort - A function that is called when a column needs to be sorted.
 * @param {string} props.sortField - The field that is currently being sorted.
 * @param {string} props.sortDirection - The direction of the current sort ("asc" or "desc").
 * @param {function} props.handleNotificationInfoModalShow - A function that is called when the info modal needs to be shown.
 * @param {function} props.handlePageChange - A function that is called when the page needs to be changed.
 * @param {number} props.currentPage - The current page number.
 * @param {number} props.totalPages - The total number of pages.
 * @returns {JSX.Element} A React component that represents a table of notifications with pagination.
 */

import React from "react";
import { Button, Pagination, Table } from "react-bootstrap";
import {
  InfoCircleOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "../../../../helpers/icons/antDesignIcons";
import "./NotificationTable.scss";
import moment from "moment";

const NotificationTable = ({
  currentPageData,
  notificationTableHeaders,
  handleSort,
  sortField,
  sortDirection,
  handleNotificationInfoModalShow,
  handlePageChange,
  currentPage,
  totalPages,
}) => {
  return (
    <>
      <div className="notification-settings-page__content__prev-notifications-list__container">
        <h5 className="notification-subtitle">
          Korábban jóváhagyott értesítések
        </h5>
        <div className="table__wrapper">
          <Table className="notifications__table">
            <thead>
              <tr className="notification-table-header-tr">
                {Object.keys(notificationTableHeaders).map((key, index) => (
                  <th
                    key={index}
                    className={`notification-table-header__item ${key}`}
                    id={`notification-table-${key}-header`}
                  >
                    <p className="notification-table-header__title">
                      {notificationTableHeaders[key]}
                    </p>
                    {/* If the key is "name" or "location", display the sort button */}
                    {key === "sens_timestamp" ? (
                      <Button
                        variant="link"
                        className="table-header-sort__btn"
                        onClick={() => handleSort(key)}
                      >
                        {sortField === key && sortDirection === "asc" ? (
                          <SortDescendingOutlined />
                        ) : (
                          <SortAscendingOutlined />
                        )}
                      </Button>
                    ) : null}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentPageData.map((data, index) => (
                <tr key={index}>
                  {Object.keys(notificationTableHeaders).map((key, index) => (
                    <td
                      key={index}
                      className={`notification-table-data ${key}`}
                      id={`notification-table-${key}-data`}
                    >
                      <div className="ellipsis">
                        {key === "sens_timestamp" ||
                        key === "acknowledged_at" ? (
                          moment(data[key]).format("YYYY.MM.DD HH:mm:ss")
                        ) : key === "notificationMoreInfo" ? (
                          <Button
                            variant="link"
                            onClick={() =>
                              handleNotificationInfoModalShow(data)
                            }
                          >
                            <InfoCircleOutlined />
                          </Button>
                        ) : (
                          data[key]
                        )}
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <Pagination className="notification-settings-page__content__prev-notifications-list__container__pagination">
          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {Array.from({ length: totalPages }, (_, index) => (
            <Pagination.Item
              key={index}
              active={index + 1 === currentPage}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      </div>
    </>
  );
};

export default NotificationTable;
