import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import {
  EditOutlined,
  DeleteOutlined,
} from "../../../helpers/icons/antDesignIcons";
import { deleteContainerByContainerId } from "../../../api/locationApi";
import ConfirmationModal from "../../Shared/ConfirmationModal/ConfirmationModal";
import { toast } from "react-toastify";
import "./LocationTable.scss";
import EditContainerModal from "../EditModals/EditContainerModal";

const LocationTable = ({
  tableHeader,
  tabledata,
  selectedSite,
  refreshTableData,
}) => {
  const navigate = useNavigate();
  // Ensure tableDataState is initialized with an empty array if tabledata might be undefined
  const [tableDataState, setTableDataState] = useState(tabledata || []);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [selectedContainerForEdit, setSelectedContainerForEdit] =
    useState(null);
  const [selectedContainerForDeletion, setSelectedContainerForDeletion] =
    useState(null);

  useEffect(() => {
    setTableDataState(tabledata);
  }, [tabledata]);

  const handleEditClick = (container) => {
    setSelectedContainerForEdit(container);
    setIsEditModalVisible(true);
  };

  const handleEditSubmitClick = async () => {
    try {
      
      setIsEditModalVisible(false);
    } catch (error) {
      console.error("Error updating container: ", error);
      toast.error("Hiba történt a frissítés során");
    }
  };

  const handleEditModalClose = () => {
    setIsEditModalVisible(false);
  };

  const handleDeleteClick = (container) => {
    console.log("container", container);
    setSelectedContainerForDeletion(container);
    setIsModalVisible(true);
  };

  const handleConfirmDelete = async () => {
    try {
      console.log(selectedContainerForDeletion._id);
      await deleteContainerByContainerId(selectedContainerForDeletion._id);
      toast.success("Tároló sikeresen törölve!");
      setIsModalVisible(false);
      const updatedData = await refreshTableData(); // Assume this returns the updated list
      setTableDataState(updatedData); // Assuming you have a state called tableData that holds the table's data
    } catch (error) {
      console.error("Error deleting container: ", error);
      toast.error("Hiba történt a törlés során");
    }
  };

  const handleCancelDelete = () => {
    setIsModalVisible(false);
  };

  if (!tableHeader || !tabledata) return null;

  const filteredTableData = tabledata.filter(
    (container) => container.sites_id === selectedSite
  );

  const handleMoreContainerInfoClick = (container) => {
    navigate(`/locations/${container._id}`, { state: { container } });
  };

  return (
    <>
      <div className="table__wrapper">
        <Table
          headers={tableHeader}
          tabledata={tabledata}
          className="locations-page-table"
        >
          <thead>
            <tr className="table-header-tr">
              {Object.values(tableHeader).map((header, index) => (
                <th
                  key={index}
                  className="table-header__item devices-page__table-header-item locations-page__table-header-item"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableDataState && tableDataState.length > 0 ? (
              tableDataState.map((container, containerIndex) => (
                <tr key={containerIndex}>
                  <td
                    className="table-data devices-page__table-data locations-page__table-data"
                    onClick={() => handleMoreContainerInfoClick(container)}
                  >
                    {container.containername}
                  </td>
                  <td className="table-data devices-page__table-data locations-page__table-data">
                    {container.sitename}
                  </td>
                  <td className="table-data devices-page__table-data locations-page__table-data">
                    {container.containertype}
                  </td>
                  <td>
                    <Button
                      className="devices-and-locations-page-list__edit-button"
                      onClick={() => handleEditClick(container)}
                    >
                      <EditOutlined />
                    </Button>
                  </td>
                  <td>
                    <Button
                      className="devices-and-locations-page-list__delete-button"
                      onClick={() => handleDeleteClick(container)}
                    >
                      <DeleteOutlined />
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">Nincs megjeleníthető adat</td>
              </tr>
            )}
            {isEditModalVisible && (
              <EditContainerModal
                show={isEditModalVisible}
                onHide={handleEditModalClose}
                container={selectedContainerForEdit}
                refreshTableData={refreshTableData}
              />
            )}

            <ConfirmationModal
              isVisible={isModalVisible}
              container={selectedContainerForDeletion}
              onConfirm={handleConfirmDelete}
              onCancel={handleCancelDelete}
              message="Biztosan törölni szeretné ezt a tárolót?"
            />
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default LocationTable;
