import React from "react";
import { Modal, Button, FormSelect } from "react-bootstrap";
import { PlusCircleOutlined } from "../../../../helpers/icons/antDesignIcons";
import "../ContainerMoreInfoPage.scss";

const ConnectCropToContainerModal = ({
  showConnectCropToContainerModal,
  handleShowConnectCropToContainerModal,
  handleCloseConnectCropToContainerModal,
  containerId,
  cropData,
  listOfCropsInCompany,
  handleSubmitConnectCropToContainerButtonClick,
  selectedCropToConnectToContainer,
  setSelectedCropToConnectToContainer,

}) => {
  const handleSelectChange = (event) => {
    if (event.target.value === "") {
      setSelectedCropToConnectToContainer(null);
      return;
    }

    setSelectedCropToConnectToContainer(event.target.value);
  };

  // Filter the list of crops in the company to exclude those already in the container
  const filteredCrops = listOfCropsInCompany.filter(
    (crop) =>
      !cropData.some((containerCrop) => containerCrop.crops_id === crop._id)
  );

  return (
    <div className="container-crop-more-view-list__wrapper cctcm-wrapper">
      <p>Termény hozzáadása a tárolóhoz</p>
      <button onClick={handleShowConnectCropToContainerModal}>
        <PlusCircleOutlined />
      </button>
      <Modal
        show={showConnectCropToContainerModal}
        onHide={handleCloseConnectCropToContainerModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Termény hozzáadása a tárolóhoz</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Elérhető termények:</p>
          <FormSelect onChange={handleSelectChange}>
            <option value="">Válasszon egy terményt...</option>
            {filteredCrops.map((crop, index) => (
              <option key={index} value={crop._id}>
                {crop.cropname}
              </option>
            ))}
          </FormSelect>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCloseConnectCropToContainerModal}
          >
            Bezárás
          </Button>
          <Button
            variant="primary"
            onClick={() =>
              handleSubmitConnectCropToContainerButtonClick(
                containerId,
                selectedCropToConnectToContainer
              )
            }
          >
            Hozzáadás
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConnectCropToContainerModal;
