/**
 * HelperModal Component
 *
 * This component renders a button that, when clicked, opens a modal with a title and body content.
 * The body content can be either a string or a React element. If the body is a string, it will be
 * split by newline characters and each line will be rendered separately.
 *
 * Props:
 * - title: The title to be displayed in the modal header.
 * - body: The content to be displayed in the modal body. Can be a string or a React element.
 *
 * Key Features:
 * - Toggle the visibility of the modal.
 * - Render the body content appropriately based on its type.
 *
 * Example usage:
 * <HelperModal
 *   title="Help"
 *   body="This is a help text.\nIt supports multiple lines."
 * />
 */

import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { QuestionCircleOutlined } from "../../../helpers/icons/antDesignIcons";

const HelperModal = ({ title, body }) => {
  const [show, setShow] = useState(false);

  // Function to toggle the visibility of the modal
  const toggleModal = () => setShow(!show);

  // Function to render the body content appropriately
  const renderBody = () => {
    if (typeof body === "string") {
      return body.split("\n").map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ));
    } else {
      // Handle non-string body appropriately
      return body;
    }
  };

  return (
    <>
      <Button
        onClick={toggleModal}
        size="sm"
        variant="primary"
        style={{ backgroundColor: "transparent", color: "#666" }}
      >
        <QuestionCircleOutlined />
      </Button>
      <Modal show={show} onClick={toggleModal} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{renderBody()}</Modal.Body>
      </Modal>
    </>
  );
};

export default HelperModal;
