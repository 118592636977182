import React from "react";
import { Link } from "react-router-dom";
import "./LoginForgotPassword.scss";
import useTranslation from "../../../hooks/useTranslation";

const LoginForgotPassword = () => {
  const { t } = useTranslation("loginRelatedPages", true);

  return (
    <>
      <Link to="/forgot-password">
        <p className="login-page__form-forgot-password">
          {t("forgotYourPassword")}
        </p>
      </Link>
    </>
  );
};

export default LoginForgotPassword;
