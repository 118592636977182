/**
 * ContainerThresholdsView Component
 *
 * This component is responsible for displaying the sensor thresholds view for a specific container.
 * It groups sensors by their probe ID and displays each group with their respective sensor data.
 * Additionally, it visually indicates when sensor values are outside their defined thresholds.
 *
 * Props:
 * - containerId: Identifier for the container whose sensor data is being displayed.
 * - sensorsTableHeader: Object defining the headers for the sensor data table.
 * - sensorsData: Array of sensor data objects to be displayed.
 * - handleThresholdSaveClick: Function to handle saving changes to sensor thresholds.
 * - handleThresholdAdditiveSaveClick: Function to handle saving additive changes to sensor thresholds.
 *
 * The component utilizes the `ThresholdAccordion` for displaying detailed threshold controls for each sensor.
 * It also applies specific styling to sensor values that are outside their thresholds, using arrows and color coding
 * to indicate whether the value is above or below the expected range.
 */

import React from "react";
import { Table } from "react-bootstrap";
import ThresholdAccordion from "./ThresholdAccordion";
import "../ContainerMoreInfoPage.scss";
import useTranslation from "../../../../hooks/useTranslation";

const ContainerThresholdsView = ({
  containerId,
  sensorsTableHeader,
  sensorsData,
  handleThresholdSaveClick,
  handleThresholdAdditiveSaveClick,
  sensorMinThreshold,
  sensorMaxThreshold,
}) => {
  const { t } = useTranslation("locationsPage");
  // Grouping the sensors by their probeid
  const groupedSensorsData = sensorsData
    .sort((a, b) => a.probeid - b.probeid)
    .reduce((acc, sensor) => {
      (acc[sensor.probeid] = acc[sensor.probeid] || []).push(sensor);
      return acc;
    }, {});

  // Function to determine and return the appropriate arrow and class based on thresholds
  const getThresholdArrowAndClass = (sensor) => {
    if (sensor.sens_value < sensor.min_value) {
      return { arrow: " ↓", className: "red-text-bold" }; // Arrow down for values below min threshold and red text
    } else if (sensor.sens_value > sensor.max_value) {
      return { arrow: " ↑", className: "red-text-bold" }; // Arrow up for values above max threshold and red text
    }
    return { arrow: "", className: "" }; // No arrow or class if within thresholds
  };

  return (
    <div>
      <h4 className="container-view-subtitle">{t("sensor_view")}</h4>
      <div className="container-thresholds-view-table__wrapper">
        <Table hover className="container-threshold-view-sensors-table">
          <thead>
            <tr className="table-header-tr">
              {Object.keys(sensorsTableHeader).map((key, index) => (
                <th
                  key={index}
                  className={`table-header__item ${key}-header`}
                  id={`ctmwt-${key}-header`}
                >
                  {sensorsTableHeader[key]}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.entries(groupedSensorsData).map(([probeid, sensors]) => (
              <React.Fragment key={probeid}>
                <tr>
                  <td colSpan="4" className="ctmwt-grouping-line">
                    {t("probe")}: {probeid}
                  </td>
                </tr>
                {sensors.map((sensor, index) => (
                  <tr key={index}>
                    {Object.keys(sensorsTableHeader).map((key, cellIndex) => (
                      <td
                        key={cellIndex}
                        className={`table-data ${key}-data`}
                        id={`ctmwt-${key}-data`}
                      >
                        {key === "sens_value" ? (
                          <span
                            className={
                              getThresholdArrowAndClass(sensor).className
                            }
                          >
                            {`${sensor[key]}${
                              getThresholdArrowAndClass(sensor).arrow
                            }`}
                          </span>
                        ) : key === "min_value" && sensor[key] === null ? (
                          <p className="ctmwt-no-data">N/A</p>
                        ) : key === "max_value" && sensor[key] === null ? (
                          <p className="ctmwt-no-data">N/A</p>
                        ) : key === "min_value" && sensor[key] !== null ? (
                          sensor[key]
                        ) : key === "max_value" && sensor[key] !== null ? (
                          sensor[key]
                        ) : (
                          sensor[key]
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </div>
      <ThresholdAccordion
        sensorsData={sensorsData}
        handleThresholdSaveClick={handleThresholdSaveClick}
        handleThresholdAdditiveSaveClick={handleThresholdAdditiveSaveClick}
        sensorMinThreshold={sensorMinThreshold}
        sensorMaxThreshold={sensorMaxThreshold}
      />
    </div>
  );
};

export default ContainerThresholdsView;
