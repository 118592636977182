import React from "react";
import "./BarIcon.scss";

const BarIcon = () => {
    return ( 
        <div className="hamburger-icon">
        <div className="line line1"></div>
        <div className="line line2"></div>
      </div>
    );
}
 
export default BarIcon;