/*
 * AddNewLocationModal component
 *
 * This component is responsible for rendering a modal that allows the user to add a new location.
 * It recieves the visibility state of the modal and functions to handle the modal's visibility.
 * The handleNewLocationModalClose function is called when the modal needs to be closed.
 * The handleNewLocationModalSubmit function is called when the modal needs to be submitted.
 *
 * * The modal contains a form with fields for the location name, location address, and location leader.
 * The form does not currently handle form submission. This will need to be implemented in the future.
 * The form fields are currently placeholders and will need to be replaced with real data.
 * 
 */

import React from "react";
import { Button, Form, Modal } from "react-bootstrap";

const AddNewLocationModal = ({
  showNewLocationModal,
  handleNewLocationModalClose,
  handleNewLocationModalSubmit,
}) => {
  return (
    <>
      <Modal
        show={showNewLocationModal}
        onHide={handleNewLocationModalClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Új telephely hozzáadása</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              className="mb-3"
              controlId="newlocationform__locationname"
            >
              <Form.Label>Telephely neve</Form.Label>
              <Form.Control type="text" placeholder="Székesfehérvár" />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="newlocationform__locationaddress"
            >
              <Form.Label>Cím</Form.Label>
              <Form.Control type="text" placeholder="Székesfehérvár" />
            </Form.Group>
           {/*  <Form.Group className="mb-3" controlId="newlocationform__leader">
              <Form.Select aria-label="Telephelyvezető">
                <option>Válasszon telephelyvezetőt...</option>
                <option value="1">Géza</option>
                <option value="2">Béla</option>
                <option value="3">Jóska</option>
              </Form.Select>
            </Form.Group> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleNewLocationModalClose}>
            Mégsem
          </Button>
          <Button variant="primary" onClick={handleNewLocationModalSubmit}>
            Hozzáad
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddNewLocationModal;
