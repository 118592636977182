/**
 * UsersList Component
 *
 * This component is responsible for rendering a list of users.
 * It receives `tableHeaders` and `tableData` as props, which are used to display the users table.
 *
 * It uses a local state `showNewUserModal` to control the visibility of the `AddNewUserModal` component.
 * The `handleNewUserModalClose` and `handleNewUserModalShow` functions are used to close and open the modal respectively.
 * The `handleNewUserModalSubmit` function is used to handle the submission of the new user form in the modal.
 *
 * The `AddNewUserModal` component is rendered with the `showNewUserModal`, `handleNewUserModalClose`, and `handleNewUserModalSubmit` passed as props.
 *
 * The `SettingsListingTable` component is used to display the users table with the provided headers and data.
 *
 * @component
 */

import React, { useEffect, useState } from "react";
import { Button, FloatingLabel, FormSelect } from "react-bootstrap";
import { UserAddOutlined } from "../../../../helpers/icons/antDesignIcons";
import "../CompanySettingsPage.scss";
import AddNewUserModal from "./AddNewUserModal";
import { addNewCompanyUser } from "../../../../api/companySettingsApi";
import SettingsListingTable from "../../Shared/SettingsListingTable/SettingsListingTable";

const UsersList = ({
  tableHeaders,
  tableData,
  onChange,
  isUserSuperAdmin,
  companyList,
}) => {
  const [showNewUserModal, setShowNewUserModal] = useState(false);
  const handleNewUserModalShow = () => setShowNewUserModal(true);
  const handleNewUserModalClose = () => setShowNewUserModal(false);
  const handleNewUserModalSubmit = async (formData) => {
    await addNewCompanyUser(formData);
    setShowNewUserModal(false);
  };

  /*   const filteredTableData = tableData.filter( */
/*   console.log(tableData);
  useEffect(() => {
    console.log(tableData);
  }, [tableData]);
 */
  return (
    <>
      <div className="company-settings-page__list">
        <div className="company-settings-page__list__header">
          <h2>Felhasználók</h2>
          <Button
            onClick={handleNewUserModalShow}
            className="company-settings-page__list__header__add-button"
          >
            <UserAddOutlined />
          </Button>
          <AddNewUserModal
            showNewUserModal={showNewUserModal}
            handleNewUserModalClose={handleNewUserModalClose}
            handleNewUserModalSubmit={handleNewUserModalSubmit}
          />
        </div>
        {/* Select company dropdown for superadmin */}
        {isUserSuperAdmin && (
          <div className="company-settings-page__list__company-select">
            <FloatingLabel controlId="floatingSelect" label="Cég kiválasztása">
              <FormSelect onChange={(e) => onChange(e.target.value)}>
                <option value="all">Összes cég</option>
                {companyList.map((company) => (
                  <option key={company.companyid} value={company.companyid}>
                    {company.companyname}
                  </option>
                ))}
              </FormSelect>
            </FloatingLabel>
          </div>
        )}
        <div className="company-settings-page__list__content table-wrapper">
          <SettingsListingTable
            tableHeaders={tableHeaders}
            tableData={tableData}
            tableType="users"
          />
        </div>
      </div>
    </>
  );
};

export default UsersList;
