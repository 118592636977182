import React from 'react';
import "./LoginButton.scss";
import useTranslation from '../../../hooks/useTranslation';

const LoginButton = ({handleSubmit}) => {
    const { t } = useTranslation("loginRelatedPages", true);

    return ( 
        <button
        type="submit"
        className="btn btn-primary submit-button"
        onClick={handleSubmit}
      >
        {t("login")}
      </button>
     );
}
 
export default LoginButton;