import axios from "axios";
import { getAuthHeaders } from "./auth";
const backendUrl = process.env.REACT_APP_BACKEND_URL_FOR_FRONTEND;
const token = localStorage.getItem("token");

const uploadContainerImage = async (containerId, compressedFile) => {
  const formData = new FormData();
  formData.append("file", compressedFile);
  formData.append("coord_x", null);
  formData.append("coord_y", null);

  const url = `${backendUrl}/locations/containers/layoutimage/${containerId}`;

  try {
    const response = await axios.patch(url, formData, {
      headers: {
       ...getAuthHeaders(),
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};

const uploadUserProfilePicture = async (compressedFile) => {
  const formData = new FormData();
  formData.append("file", compressedFile);

  const url = `${backendUrl}/users/profile/picture`;

  try {
    const response = await axios.patch(url, formData, {
      headers: {
        ...getAuthHeaders(),
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};

export { uploadContainerImage, uploadUserProfilePicture };
