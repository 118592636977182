import React from "react";
import { Button, FormControl, InputGroup, Modal } from "react-bootstrap";

const SensorEditThresholdModal = ({
  show,
  handleClose,
  handleSave,
  editingSensorData,
  setEditingSensorData,
}) => {
  const sensorId = editingSensorData ? editingSensorData._id : null; // check if sensorData is not null before accessing its _id property

 const handleInputChange = (event, field) => {
    setEditingSensorData((prevState) => ({
      ...prevState,
      [field]: event.target.value,
    }));
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Szenzor azonosító: {sensorId}</Modal.Title>{" "}
      </Modal.Header>
      <Modal.Body>
        <InputGroup>
          <InputGroup.Text>Minimum érték (°C):</InputGroup.Text>
          <FormControl
            type="number"
            min="-55"
            max="125"
            value={
              editingSensorData
                ? editingSensorData.min_value || ""
                : ""
            }
            onChange={(e) => handleInputChange(e, "min_value")}
            required
          />
        </InputGroup>
        <InputGroup>
          <InputGroup.Text>Maximum érték (°C):</InputGroup.Text>
          <FormControl
            type="number"
            min="-80"
            max="80"
            value={
              editingSensorData
                ? editingSensorData.max_value || ""
                : ""
            }
            onChange={(e) => handleInputChange(e, "max_value")}
            required
          />
        </InputGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Bezárás
        </Button>
        <Button
          variant="primary"
          onClick={() => handleSave(editingSensorData)}
        >
          Mentés
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SensorEditThresholdModal;
